import React, { useEffect } from 'react';
import logo from './logo.svg';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import './App.css';
import { RecoilRoot } from 'recoil';
import LoginPage from './LoginPage';
import MainPage from './MainPage';
import { CssVarsProvider } from '@mui/joy/styles';

function App() {

  return (
  <CssVarsProvider>
    <RecoilRoot>
      <Router basename='/'>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/" element={<MainPage />} />
        </Routes>
      </Router>
    </RecoilRoot>
    </CssVarsProvider>
  );
}

export default App;
