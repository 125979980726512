import { useEffect, useState } from 'react'
import cn from 'classnames'
import './App.css'

import {Link, useLocation, useNavigate, useParams, useSearchParams} from 'react-router-dom'
import axios from 'axios'
import { CircularProgress } from '@mui/joy'
import { API_HOST, LAB_ITEMS_COLUMN_1, LAB_ITEMS_COLUMN_2, LAB_ITEMS_COLUMNS} from './Constants'
import { customYMD, ANSWER_DICT, getValueByKey, markName } from './util'
import moment from 'moment'
import { renderToString } from 'react-dom/server'
import { Chart, registerables, Tooltip } from 'chart.js'
import { Line } from 'react-chartjs-2'
import type { ChartData, ChartOptions } from 'chart.js';
import * as _ from 'lodash';

Chart.register(...registerables);
Chart.register([Tooltip])

interface PushStatus {
  pushEnabled: boolean;
  pushDayBefore: number;
  pushHour: number;
  pushMinute: number;
  pushTitle: string;
  pushDesc: string;
  repeatEnabled: boolean;
}

interface LineProps {
  options: ChartOptions<'line'>;
  data: ChartData<'line'>;
}

export default function MainPage() {

  const navigate = useNavigate();

  let isTokenRefreshing = false;
  let refreshSubscribers:Array<any> = [];

  const onTokenRefreshed = (accessToken:string) => {
      refreshSubscribers.map((callback) => callback(accessToken))
  }

  const asyncLocalStorage = {
    setItem: function (key:string, value:string) {
        return Promise.resolve().then(function () {
            localStorage.setItem(key, value);
        });
    },
    getItem: function (key:string) {
        return Promise.resolve().then(function () {
            return localStorage.getItem(key);
        });
    }
  };

  const refreshAuth = () => {
    return axios.post(`${API_HOST}/api/ecrf/refreshToken`, {
      access_token: localStorage.getItem('accessToken'),
      refresh_token: localStorage.getItem('refreshToken')
    })
    .then(async function(res) {
        if (res.status === 202) return Promise.resolve(res);
        axios.defaults.headers.common['token'] = `${res.data.data.access_token}`;
        await asyncLocalStorage.setItem('accessToken', res.data.data.access_token);
        await asyncLocalStorage.setItem('refreshToken', res.data.data.refresh_token);
        return Promise.resolve(res);
    })
    .catch((err) => {
        return Promise.reject(err);
    });
}

axios.interceptors.request.use(
  function (config) {
      config.headers!.token = localStorage.accessToken;
  return config;
});

axios.interceptors.response.use(
  function (res) {
      return res;
  },
  async function (err) {
      const deniedRequest = err.config;
      console.log(err.response);
      console.log("check 0");

      if (err.response.status === 403) { // 토큰 만료

          await refreshAuth()
          .then((res) => {
              if (res.status === 202) return;
              deniedRequest.headers.token = res.data.data.access_token;
          })
          .catch((err) => {
              console.log("check 4")
              console.log(err)
              isTokenRefreshing = false;
              return Promise.reject(err);
          })

          const originRes = await axios.request(deniedRequest);
          return originRes;

      }
      else {
        console.log(err);
        Logout();
      }
  }
)


  const [modalState, setModalState] = useState<any>({
    type: "",
    mode: "",
    title: "",
    desc: ""
  });
  const [popupState, setPopupState] = useState<any>({
    type: "",
    mode: "",
    title: "",
    desc: ""
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dialogState, setDialogState] = useState<any>({
    isOpen: false,
    title: "",
    desc: "",
    accept_label: "",
    deny_label: "",
    accept_callback: () => {}
  });

  const [pageState, setPageState] = useState({
    tab: "participant",
    participant_id: "",
    hospital: "순천향대학교병원",
    total: 0,
    total_page: 1,
    max_per_page: 10,
    page: 1
  });

  const [sortState, setSortState] = useState({
    participant_name: 'asc',
    participant_id: 'asc',
    created_at: 'asc',
    last_surveyed_at: 'asc',
    updated_at: 'asc',
    sort_by: 'participant_id'
  })

  useEffect(() => {
    setListData((prevState) => getValueByKey(sortState, sortState.sort_by) === 'asc' ? _.sortBy(prevState, sortState.sort_by) : _.sortBy(prevState, sortState.sort_by).reverse())
  }, [sortState])

  const [listData, setListData] = useState<Array<any>>([]);
  const [recentViewed, setRecentViewed] = useState<Array<any>>([]);

  const pushToRecentViewed = (id: string) => {
    const idx = recentViewed.indexOf(id);
    if (idx > -1) recentViewed.splice(idx, 1);

    setRecentViewed([id].concat(recentViewed));
  }

  const findParcitipantNameById = (id: string): string => {

    let name:string = ""
    participantList.forEach((item, idx) => {
      if (item.participant_id === id) {
        name = item.participant_name;
      }
    })

    return name
  }

  const [participantInfo, setParticipantInfo] = useState<any>({});
  const [participantList, setParticipantList] = useState<Array<any>>([]);

  const [savedRawItemData, setSavedRawItemData] = useState<any>({});


  const Logout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('refreshToken');
    navigate("/login");
  }

  const LoadParticipantInfo = async (id:string) => {
    setIsLoading(true);
    await axios.post(`${API_HOST}/api/ecrf/getParticipantInfo`, {
      participant_id: id
    })
    .then((res) => {
      setParticipantInfo(res.data.data)
    })
    .catch((err) => {

    })
    
    setIsLoading(false);
  }
 
  const LoadParticipantList = async () => {
    setIsLoading(true);
    setListData([]);
    axios.get(`${API_HOST}/api/ecrf/getParticipantList`)
    .then((res) => {
      const listArray = Object.values(res.data.data);
      setListData(_.sortBy(listArray, sortState.sort_by));
      setParticipantList(listArray);
      setPageState(prevState => ({
        ...prevState,
        total: listArray.length,
        total_page: Math.ceil(listArray.length/10),
        page: 1
      }))
    });
    setIsLoading(false);
  }


  //#region TAB FUNCIONS

  //#region LAB funcions

  const [labDate, setLabDate] = useState<string>("");
  const [labType, setLabType] = useState<string>("");
  const [labNumData, setLabNumData] = useState({});
  const [labClassData, setLabClassData] = useState({});

  const LoadLabData = async (id: string) => {
    setIsLoading(true);
    setListData([]);
    await axios.post(`${API_HOST}/api/ecrf/getLabInfo`, {
      participant_id: id
    })
    .then((res) => {
      const listArray:Array<any> = Object.values(res.data.data);
      setListData(listArray)
      setDashboardLabData(listArray.sort((a, b) => b.metadata_id - a.metadata_id));
      
      setPageState(prevState => ({
        ...prevState,
        total: listArray.length,
        total_page: Math.ceil(listArray.length/5),
        page: 1
      }))
      
    })
    .catch((err) => {
    })
    
    setIsLoading(false);
  }

  const countEnteredLabValues = (data:any): number => {
    const set = new Set();
    Object.keys(data).forEach((key, idx) => {
      if ((key.endsWith('class') || key.endsWith('value')) && data[key] !== null) {
         set.add(key.split('_')[1])
      }
    })

    return set.size;
  }

  const LoadDetailLabData = (data:any) => {
    setSavedRawItemData(data);
    setLabNumData({});
    setLabClassData({});
    setLabDate(data['entered_date']);
    setLabType(data['lab_type']);
    Object.keys(data).forEach((key, idx) => {
      if (key.endsWith('class')) {
        setLabClassData(prevState => ({
          ...prevState,
          [key.split('_')[1]]: data[key]
        })) 
      }
      else if (key.endsWith('value')) {
        setLabNumData(prevState => ({
          ...prevState,
          [key.split('_')[1]]: data[key]
        })) 
      }
    })
  }

  const ReloadDetailLabData = () => {
    const data = savedRawItemData;
    setLabNumData({});
    setLabClassData({});
    setLabDate(data['entered_date']);
    setLabType(data['lab_type']);
    Object.keys(data).forEach((key, idx) => {
      if (key.endsWith('class')) {
        setLabClassData(prevState => ({
          ...prevState,
          [key.split('_')[1]]: data[key]
        })) 
      }
      else if (key.endsWith('value')) {
        setLabNumData(prevState => ({
          ...prevState,
          [key.split('_')[1]]: data[key]
        })) 
      }
    })
  }


  const getLabValue = (key:string):any => {
    const idx = Object.keys(labNumData).findIndex((v) => v === key);

    return Object.values(labNumData)[idx];
  }

  const getLabClass = (key:string):any => {
    const idx = Object.keys(labClassData).findIndex((v) => v === key);

    return Object.values(labClassData)[idx];
  }

  const InsertLabData = async (id:number) => {
    setIsLoading(true);
    let hasValueError = false;

    const valueData:any = {};
    Object.keys(labNumData).forEach((key, idx) => {
      const value = getLabValue(key);
      if (value === null || value === "") return;
      if (value < 0 || value > 1000) {
        hasValueError = true;
        return;
      }
      else valueData[`response_${key}_value`] = value;
    })

    if (labType === null || labType === "") {
      setPopupState({
        type: "message",
        mode: "fail",
        title: "검사 방법을 선택해주세요.",
        desc: ""
      })
      setIsLoading(false);
      return;
    }

    if (!moment(labDate, 'YYYY-MM-DD', true).isValid()) {
      setPopupState({
        type: "message",
        mode: "fail",
        title: "날짜를 올바르게 입력해주세요.",
        desc: "예시: 2023-02-08"
      })
      setIsLoading(false);
      return;
    }

    if (hasValueError) {
      setPopupState({
        type: "message",
        mode: "fail",
        title: "입력 값의 범위를 확인해주세요.",
        desc: "입력 값 범위 : 0.05~1000"
      })
      setIsLoading(false);
      return;
    }

    const classData:any = {};
    Object.keys(labClassData).forEach((key, idx) => {
      const value = getLabClass(key);
      if (value === null || value === "") return;
      else classData[`response_${key}_class`] = value;
    })

    const uploadData = {
      participant_id: pageState.participant_id,
      entered_date: labDate,
      lab_type: labType,
      ...valueData,
      ...classData
    }


    if (id === 0) { // 새 데이터 추가
      await axios.post(`${API_HOST}/api/ecrf/insertLabInfo`, {
        data: uploadData
      })
      .then((res) => {
        setPopupState({
          type: "message",
          mode: "success",
          title: "성공적으로 등록되었습니다.",
          desc: "데이터가 성공적으로 입력되었습니다."
        })
        LoadLabData(pageState.participant_id);
      })
      .catch((err) => {
        setPopupState({
          type: "message",
          mode: "fail",
          title: "등록 실패하였습니다.",
          desc: "데이터 등록에 실패하였습니다. 다시 시도해주세요."
        })
      })
    }
    else {
      await axios.post(`${API_HOST}/api/ecrf/updateLabInfo`, {
        id: id,
        data: uploadData
      })
      .then((res) => {
        setPopupState({
          type: "message",
          mode: "success",
          title: "성공적으로 등록되었습니다.",
          desc: "데이터가 성공적으로 입력되었습니다."
        })
        LoadLabData(pageState.participant_id);
      })
      .catch((err) => {
        setPopupState({
          type: "message",
          mode: "fail",
          title: "등록 실패하였습니다.",
          desc: "데이터 등록에 실패하였습니다. 다시 시도해주세요."
        })
      })
    }
    setModalState({
      type: "",
      mode: ""
    })
    
    
    setIsLoading(false);
  }

  const changeLabPosted = async (id: number, status: boolean) => {
    setIsLoading(true);

    await axios.post(`${API_HOST}/api/ecrf/postLabInfo`, {
      id: id,
      status: status
    })
    .then((res) => {
      setDialogState({
        isOpen: false
      })
      setModalState({
        type: "",
        mode: ""
      })
      LoadLabData(pageState.participant_id);
    })
    .catch((err) => {

    })
    
    setIsLoading(false);
  }

  const deleteLabData = async (id: number) => {
    setIsLoading(true);

    await axios.post(`${API_HOST}/api/ecrf/deleteLabInfo`, {
      id: id
    })
    .then((res) => {
      setDialogState({
        isOpen: false
      })
      setModalState({
        type: "",
        mode: ""
      })
      LoadLabData(pageState.participant_id);
    })
    .catch((err) => {

    })
    
    setIsLoading(false);
  }


  //#endregion


  //#region SCORAD functions

  const [scoradData, setScoradData] = useState<any>({});

  const LoadScoradData = async (id: string) => {
    setIsLoading(true);
    setListData([]);
    await axios.post(`${API_HOST}/api/ecrf/getScoradInfo`, {
      participant_id: id
    })
    .then((res) => {
      const listArray:Array<any> = Object.values(res.data.data);
      setListData(listArray)
      setDashboardScoradData(listArray.sort((a, b) => b.metadata_id - a.metadata_id));

      setPageState(prevState => ({
        ...prevState,
        total: listArray.length,
        total_page: Math.ceil(listArray.length/5),
        page: 1
      }))
      
    })
    .catch((err) => {
    })
    
    setIsLoading(false);
  }

  const LoadScoradChartData = async (id: string) => {
    setIsLoading(true);
    await axios.post(`${API_HOST}/api/ecrf/getScoradInfo`, {
      participant_id: id
    })
    .then((res) => {
      const listArray:Array<any> = Object.values(res.data.data);
      
      let chartLabels: Array<string> = [];
      let chartNumbers: Array<Number> = [];

      listArray
      .sort((a, b) => ( b.entered_date > a.entered_date ) ? -1 : ( b.entered_date == a.entered_date ) ? 0 : 1)
      .forEach((item, idx) => {
        chartLabels.push(item.entered_date);
        chartNumbers.push(item.total_score)
      });

      setChartData({
        labels: chartLabels,
        datasets: [
          {
            label: 'SCORAD Total Score',
            data: chartNumbers
          }
        ]
      });
      
    })
    .catch((err) => {
    })
    
    setIsLoading(false);
  }

  const LoadDetailScoradData = (data:any) => {
    setSavedRawItemData(data);
    setScoradData(data);
  }

  const ReloadDetailScoradData = () => {
    const data = savedRawItemData;
    setScoradData(data);
  }


  const InsertScoradData = async (id:number) => {
    setIsLoading(true);

    const uploadData = {
      ...scoradData,
      participant_id: pageState.participant_id
    }

    if (!moment(scoradData.entered_date, 'YYYY-MM-DD', true).isValid()) {
      setPopupState({
        type: "message",
        mode: "fail",
        title: "날짜를 올바르게 입력해주세요.",
        desc: "예시: 2023-02-08"
      })
      setIsLoading(false);
      return;
    }

    if (scoradData.response_extent < 0 || scoradData.response_extent > 103
        || scoradData.response_intensity < 0 || scoradData.response_intensity > 103
        || scoradData.response_subj_symptoms < 0 || scoradData.response_subj_symptoms > 103) {
      setPopupState({
        type: "message",
        mode: "fail",
        title: "입력 값의 범위를 확인해주세요.",
        desc: "입력 값 범위 : 0~103"
      })
      setIsLoading(false);
      return;
    }

    if (id === 0) { // 새 데이터 추가
      await axios.post(`${API_HOST}/api/ecrf/insertScoradInfo`, {
        data: uploadData
      })
      .then((res) => {
        setPopupState({
          type: "message",
          mode: "success",
          title: "성공적으로 등록되었습니다.",
          desc: "데이터가 성공적으로 입력되었습니다."
        })
        LoadScoradData(pageState.participant_id);
      })
      .catch((err) => {
        setPopupState({
          type: "message",
          mode: "fail",
          title: "등록 실패하였습니다.",
          desc: "데이터 등록에 실패하였습니다. 다시 시도해주세요."
        })
      })
    }
    else {
      await axios.post(`${API_HOST}/api/ecrf/updateScoradInfo`, {
        id: id,
        data: uploadData
      })
      .then((res) => {
        setPopupState({
          type: "message",
          mode: "success",
          title: "성공적으로 수정되었습니다.",
          desc: "데이터가 성공적으로 입력되었습니다."
        })
        LoadScoradData(pageState.participant_id);
      })
      .catch((err) => {
        setPopupState({
          type: "message",
          mode: "fail",
          title: "등록 실패하였습니다.",
          desc: "데이터 등록에 실패하였습니다. 다시 시도해주세요."
        })
      })
    }
    setModalState({
      type: "",
      mode: ""
    })

    
    
    setIsLoading(false);
  }

  const deleteScoradData = async (id: number) => {
    setIsLoading(true);

    await axios.post(`${API_HOST}/api/ecrf/deleteScoradInfo`, {
      id: id
    })
    .then((res) => {
      setDialogState({
        isOpen: false
      })
      setModalState({
        type: "",
        mode: ""
      })
      LoadScoradData(pageState.participant_id);
    })
    .catch((err) => {

    })
    
    setIsLoading(false);
  }

  //#endregion


  //#region EASI functions

  const [easiData, setEasiData] = useState<any>({});

  const LoadEasiData = async (id: string) => {
    setIsLoading(true);
    setListData([]);
    await axios.post(`${API_HOST}/api/ecrf/getEasiInfo`, {
      participant_id: id
    })
    .then((res) => {
      const listArray:Array<any> = Object.values(res.data.data);
      setListData(listArray)
      setDashboardEasiData(listArray.sort((a, b) => b.metadata_id - a.metadata_id));
      
      setPageState(prevState => ({
        ...prevState,
        total: listArray.length,
        total_page: Math.ceil(listArray.length/5),
        page: 1
      }))
      
    })
    .catch((err) => {
    })
    
    setIsLoading(false);
  }

  const LoadEasiChartData = async (id: string) => {
    setIsLoading(true);
    await axios.post(`${API_HOST}/api/ecrf/getEasiInfo`, {
      participant_id: id
    })
    .then((res) => {
      const listArray:Array<any> = Object.values(res.data.data);

      let chartLabels: Array<string> = [];
      let chartNumbers: Array<Number> = [];

      listArray
      .sort((a, b) => ( b.entered_date > a.entered_date ) ? -1 : ( b.entered_date == a.entered_date ) ? 0 : 1)
      .forEach((item, idx) => {
        chartLabels.push(item.entered_date);
        chartNumbers.push(item.response_easi)
      });

      setChartData({
        labels: chartLabels,
        datasets: [
          {
            label: 'EASI Score',
            data: chartNumbers
          }
        ]
      });
      
    })
    .catch((err) => {
    })
    
    setIsLoading(false);
  }

  const LoadDetailEasiData = (data:any) => {
    setSavedRawItemData(data);
    setEasiData(data);
  }

  const ReloadDetailEasiData = () => {
    const data = savedRawItemData;
    setEasiData(data);
  }

  const InsertEasiData = async (id:number) => {
    setIsLoading(true);

    const uploadData = {
      ...easiData,
      participant_id: pageState.participant_id
    }

    if (!moment(easiData.entered_date, 'YYYY-MM-DD', true).isValid()) {
      setPopupState({
        type: "message",
        mode: "fail",
        title: "날짜를 올바르게 입력해주세요.",
        desc: "예시: 2023-02-08"
      })
      setIsLoading(false);
      return;
    }

    if (easiData.response_easi < 0 || easiData.response_easi > 72) {
      setPopupState({
        type: "message",
        mode: "fail",
        title: "입력 값의 범위를 확인해주세요.",
        desc: "입력 값 범위 : 0~72"
      })
      setIsLoading(false);
      return;
    }

    if (id === 0) { // 새 데이터 추가
      await axios.post(`${API_HOST}/api/ecrf/insertEasiInfo`, {
        data: uploadData
      })
      .then((res) => {
        setPopupState({
          type: "message",
          mode: "success",
          title: "성공적으로 등록되었습니다.",
          desc: "데이터가 성공적으로 입력되었습니다."
        })
        LoadEasiData(pageState.participant_id);
      })
      .catch((err) => {
        setPopupState({
          type: "message",
          mode: "fail",
          title: "등록 실패하였습니다.",
          desc: "데이터 등록에 실패하였습니다. 다시 시도해주세요."
        })
      })
    }
    else {
      await axios.post(`${API_HOST}/api/ecrf/updateEasiInfo`, {
        id: id,
        data: uploadData
      })
      .then((res) => {
        setPopupState({
          type: "message",
          mode: "success",
          title: "성공적으로 수정되었습니다.",
          desc: "데이터가 성공적으로 입력되었습니다."
        })
        LoadEasiData(pageState.participant_id);
      })
      .catch((err) => {
        setPopupState({
          type: "message",
          mode: "fail",
          title: "등록 실패하였습니다.",
          desc: "데이터 등록에 실패하였습니다. 다시 시도해주세요."
        })
      })
    }
    setModalState({
      type: "",
      mode: ""
    })

    
    
    setIsLoading(false);
  }

  const deleteEasiData = async (id: number) => {
    setIsLoading(true);

    await axios.post(`${API_HOST}/api/ecrf/deleteEasiInfo`, {
      id: id
    })
    .then((res) => {
      setDialogState({
        isOpen: false
      })
      setModalState({
        type: "",
        mode: ""
      })
      LoadEasiData(pageState.participant_id);
    })
    .catch((err) => {

    })
    
    setIsLoading(false);
  }

  //#endregion


  //#region ADR functions

  const [adrData, setAdrData] = useState<any>({});

  const LoadAdrData = async (id: string) => {
    setIsLoading(true);
    setListData([]);
    await axios.post(`${API_HOST}/api/ecrf/getAdrInfo`, {
      participant_id: id
    })
    .then((res) => {
      const listArray:Array<any> = Object.values(res.data.data);
      setListData(listArray)
      setDashboardAdrData(listArray.sort((a, b) => b.metadata_id - a.metadata_id));

      
      setPageState(prevState => ({
        ...prevState,
        total: listArray.length,
        total_page: Math.ceil(listArray.length/5),
        page: 1
      }))
      
    })
    .catch((err) => {
    })
    
    setIsLoading(false);
  }

  const LoadDetailAdrData = (data:any) => {
    setSavedRawItemData(data);
    setAdrData(data);
  }

  const ReloadDetailAdrData = () => {
    const data = savedRawItemData;
    setAdrData(data);
  }

  const InsertAdrData = async (id:number) => {
    setIsLoading(true);

    const uploadData = {
      ...adrData,
      participant_id: pageState.participant_id
    }

    if (!moment(adrData.entered_date, 'YYYY-MM-DD', true).isValid()) {
      setPopupState({
        type: "message",
        mode: "fail",
        title: "날짜를 올바르게 입력해주세요.",
        desc: "예시: 2023-02-08"
      })
      setIsLoading(false);
      return;
    }


    if (id === 0) { // 새 데이터 추가
      await axios.post(`${API_HOST}/api/ecrf/insertAdrInfo`, {
        data: uploadData
      })
      .then((res) => {
        setPopupState({
          type: "message",
          mode: "success",
          title: "성공적으로 등록되었습니다.",
          desc: "데이터가 성공적으로 입력되었습니다."
        })
        LoadAdrData(pageState.participant_id);
      })
      .catch((err) => {
        setPopupState({
          type: "message",
          mode: "fail",
          title: "등록 실패하였습니다.",
          desc: "데이터 등록에 실패하였습니다. 다시 시도해주세요."
        })
      })
    }
    else {
      await axios.post(`${API_HOST}/api/ecrf/updateAdrInfo`, {
        id: id,
        data: uploadData
      })
      .then((res) => {
        setPopupState({
          type: "message",
          mode: "success",
          title: "성공적으로 수정되었습니다.",
          desc: "데이터가 성공적으로 입력되었습니다."
        })
        LoadAdrData(pageState.participant_id);
      })
      .catch((err) => {
        setPopupState({
          type: "message",
          mode: "fail",
          title: "등록 실패하였습니다.",
          desc: "데이터 등록에 실패하였습니다. 다시 시도해주세요."
        })
      })
    }
    setModalState({
      type: "",
      mode: ""
    })

    
    
    setIsLoading(false);
  }

  const deleteAdrData = async (id: number) => {
    setIsLoading(true);

    await axios.post(`${API_HOST}/api/ecrf/deleteAdrInfo`, {
      id: id
    })
    .then((res) => {
      setDialogState({
        isOpen: false
      })
      setModalState({
        type: "",
        mode: ""
      })
      LoadAdrData(pageState.participant_id);
    })
    .catch((err) => {

    })
    
    setIsLoading(false);
  }

  //#endregion


  //#region OAS functions

  const [oasData, setOasData] = useState<any>({});

  const LoadOasData = async (id:string) => {
    setIsLoading(true);
    setListData([]);
    await axios.post(`${API_HOST}/api/ecrf/getOasInfo`, {
      participant_id: id
    })
    .then((res) => {
      const listArray:Array<any> = Object.values(res.data.data);
      setListData(listArray)
      setDashboardOasData(listArray.sort((a, b) => b.metadata_id - a.metadata_id));
      
      setPageState(prevState => ({
        ...prevState,
        total: listArray.length,
        total_page: Math.ceil(listArray.length/5),
        page: 1
      }))
      
    })
    .catch((err) => {
    })
    
    setIsLoading(false);
  }

  const LoadDetailOasData = (data:any) => {
    setSavedRawItemData(data);
    setOasData(data);
  }

  const ReloadDetailOasData = () => {
    const data = savedRawItemData;
    setOasData(data);
  }

  const InsertOasData = async (id:number) => {
    setIsLoading(true);

    const filteredData:any = {};
    
    Object.keys(oasData).forEach((key) => {
      if (oasData[key] !== null && oasData[key] !== "") filteredData[key] = oasData[key]
    })

    const uploadData = {
      ...filteredData,
      participant_id: pageState.participant_id
    }


    if (!moment(oasData.entered_date, 'YYYY-MM-DD', true).isValid()) {
      setPopupState({
        type: "message",
        mode: "fail",
        title: "날짜를 올바르게 입력해주세요.",
        desc: "예시: 2023-02-08"
      })
      setIsLoading(false);
      return;
    }


    if (id === 0) { // 새 데이터 추가
      await axios.post(`${API_HOST}/api/ecrf/insertOasInfo`, {
        data: uploadData
      })
      .then((res) => {
        setPopupState({
          type: "message",
          mode: "success",
          title: "성공적으로 등록되었습니다.",
          desc: "데이터가 성공적으로 입력되었습니다."
        })
        LoadOasData(pageState.participant_id);
      })
      .catch((err) => {
        setPopupState({
          type: "message",
          mode: "fail",
          title: "등록 실패하였습니다.",
          desc: "데이터 등록에 실패하였습니다. 다시 시도해주세요."
        })
      })
    }
    else {
      await axios.post(`${API_HOST}/api/ecrf/updateOasInfo`, {
        id: id,
        data: uploadData
      })
      .then((res) => {
        setPopupState({
          type: "message",
          mode: "success",
          title: "성공적으로 수정되었습니다.",
          desc: "데이터가 성공적으로 입력되었습니다."
        })
        LoadOasData(pageState.participant_id);
      })
      .catch((err) => {
        setPopupState({
          type: "message",
          mode: "fail",
          title: "등록 실패하였습니다.",
          desc: "데이터 등록에 실패하였습니다. 다시 시도해주세요."
        })
      })
    }
    setModalState({
      type: "",
      mode: ""
    })

    
    
    setIsLoading(false);
  }


  const getOasString = (item:any) => {
    const filteredValue: Array<string> = [];
    Object.keys(item).forEach((key:string) => {
      if (key.includes("response_allergen") && (item[key] !== null && item[key] !== "")) filteredValue.push(item[key])
    })

    return filteredValue.join(", ")
  }

  const deleteOasData = async (id: number) => {
    setIsLoading(true);

    await axios.post(`${API_HOST}/api/ecrf/deleteOasInfo`, {
      id: id
    })
    .then((res) => {
      setDialogState({
        isOpen: false
      })
      setModalState({
        type: "",
        mode: ""
      })
      LoadOasData(pageState.participant_id);
    })
    .catch((err) => {

    })
    
    setIsLoading(false);
  }

  //#endregion


  //#region Survey functions

  const [surveyData, setSurveyData] = useState<any>({});

  const LoadSurveyData = async (id: string) => {
    setIsLoading(true);
    setListData([]);
    await axios.post(`${API_HOST}/api/ecrf/getSurveyInfo`, {
      participant_id: id
    })
    .then((res) => {
      const listArray:Array<any> = Object.values(res.data.data);
      setListData(listArray)
      setDashboardSurveyData(listArray.sort((a, b) => a.submitted_at - b.submitted_at));
      
      setPageState(prevState => ({
        ...prevState,
        total: listArray.length,
        total_page: Math.ceil(listArray.length/6),
        page: 1
      }));

      
    })
    .catch((err) => {
    })
    
    setIsLoading(false);
  };

  const LoadSurveyChartData = async (id: string, tab: string) => {
    setIsLoading(true);
    await axios.post(`${API_HOST}/api/ecrf/getSurveyInfo`, {
      participant_id: id
    })
    .then((res) => {
      const listArray:Array<any> = Object.values(res.data.data);

      let chartLabels: Array<string> = [];
      let chartNumbers: Array<Number> = [];

      listArray
      .sort((a, b) => ( b.entered_date > a.entered_date ) ? -1 : ( b.entered_date == a.entered_date ) ? 0 : 1)
      .forEach((item, idx) => {
        chartLabels.push(item.surveyed_at.slice(0,10));

        switch (tab) {
          case 'adct':
            chartNumbers.push(item.adct_score);
            break;
          case 'dlqi':
            chartNumbers.push(item.qol_score)
            break;
          case 'dfi':
            chartNumbers.push(item.dfi_score)
            break;
          case 'poem':
            chartNumbers.push(item.poem_score)
            break;
        }

      });

      setChartData({
        labels: chartLabels,
        datasets: [
          {
            label: `${tab.toUpperCase()} Score`,
            data: chartNumbers
          }
        ]
      });
      
    })
    .catch((err) => {
    })
    
    setIsLoading(false);
  }


  const LoadDetailSurveyData = (data:any) => {
    setSavedRawItemData(data);
    setSurveyData(data);
  }


  const getAdrString = (data:any) => {
    const adrKeys = ['response_5b', 'response_5d', 'response_5f', 'response_5h', 'response_5j', 'response_5l', 'response_5n']
    let adrValue:Array<string> = [];
    adrKeys.forEach((key:string, idx) => {
      if (data[key] !== null && data[key] !== undefined && Array.isArray(data[key])) {
        data[key].forEach((answer:number) => {
          adrValue.push(getValueByKey(ANSWER_DICT, key)[answer]);
        })
      }
    })

    if (adrValue.length === 0) return null
    else return adrValue.join(', ')
  }

  //#endregion


  //#region Dashboard functions

  const [dashboardSurveyData, setDashboardSurveyData] = useState<Array<any>>([]);
  const [dashboardScoradData, setDashboardScoradData] = useState<Array<any>>([]);
  const [dashboardLabData, setDashboardLabData] = useState<Array<any>>([]);
  const [dashboardEasiData, setDashboardEasiData] = useState<Array<any>>([]);
  const [dashboardAdrData, setDashboardAdrData] = useState<Array<any>>([]);
  const [dashboardOasData, setDashboardOasData] = useState<Array<any>>([]);

  const LoadDashboardData = async (id: string) => {
    setIsLoading(true);
    await LoadSurveyData(id);
    await LoadScoradData(id);
    await LoadLabData(id);
    await LoadEasiData(id);
    await LoadAdrData(id);
    await LoadOasData(id);
    setIsLoading(false);
  }


  //#endregion


  //#endregion


  //#region GRAPH functions

  const [chartState, setChartState] = useState<any>({
    tab: ''
  });

  const [chartData, setChartData] = useState<any>({
    labels: [],
    datasets: [
      {
        label: 'SCORAD Total Score',
        data: []
      }
    ]
  });



  //#endregion

  const LoadPushData = async () => {
    setIsLoading(true);
    await axios.get(`${API_HOST}/api/ecrf/getPushInfo`)
    .then((res) => {
      
      if (res.data.data.length !== 0)  {
        setAdminState({
          pushEnabled: res.data.data[0].is_enabled,
          pushDayBefore: res.data.data[0].push_day_before,
          pushHour: res.data.data[0].push_hour,
          pushMinute: res.data.data[0].push_minute,
          pushTitle: res.data.data[0].content_title,
          pushDesc: res.data.data[0].content_desc,
          repeatEnabled: res.data.data[0].is_repeat_enabled
        });
      }

    })
    .catch((err) => {
    })
    
    setIsLoading(false);
  };

  const UpdatePushData = async () => {

    //schedule_cron: `0 ${adminState.pushHour-9 < 0 ? adminState.pushHour-9+24 : adminState.pushHour-9} ${adminState.pushHour-9 < 0 ? adminState.pushDay-1 : adminState.pushDay} * *`,

    setIsLoading(true);
    console.log(adminState)
    await axios.post(`${API_HOST}/api/ecrf/updatePushInfo`, 
    {
      is_enabled: adminState.pushEnabled,
      push_day_before: adminState.pushDayBefore,
      push_hour: adminState.pushHour,
      push_minute: adminState.pushMinute,
      content_title: adminState.pushTitle,
      content_desc: adminState.pushDesc,
      is_repeat_enabled: adminState.repeatEnabled
    })
    .then((res) => {

      setPopupState({
        type: "message",
        mode: "success",
        title: "성공적으로 수정되었습니다.",
        desc: "데이터가 성공적으로 입력되었습니다."
      })

    })
    .catch((err) => {

      setPopupState({
        type: "message",
        mode: "fail",
        title: "등록 실패하였습니다.",
        desc: "데이터 등록에 실패하였습니다. 다시 시도해주세요."
      })
    })
    
    setIsLoading(false);
  };

  const [adminState, setAdminState] = useState<PushStatus>({
    pushEnabled: false,
    pushDayBefore: 5,
    pushHour: 10,
    pushMinute: 0,
    pushTitle: '',
    pushDesc: '',
    repeatEnabled: false
  })

  useEffect(() => {

    if (localStorage.getItem('accessToken') === null || localStorage.getItem('refreshToken') === null) {
      Logout();
      return;
    }
    else {
      refreshAuth()
      .then(() => {
        axios.get(`${API_HOST}/api/ecrf/userInfo`)
        .then((res) => {
          setPageState(prevState => ({
            ...prevState,
            hospital: res.data.data.hospital.hospital_name
          }))
        });

        if (pageState.tab === "participant") {
          LoadParticipantList();
        }
      })
      .catch((err) => {
        Logout();
      })
      .finally(() => {
      })
    }

  }, [])


  return (
    <div style={{width: '100%', minWidth: 1280, height: '100%', minHeight: 720, overflow: 'hidden'}}>

      <div style={{height: 50, display: 'flex', alignItems: 'center', justifyContent: 'space-between',borderBottom: '1px solid #E9ECEF'}}>
        <img style={{width: 250, marginLeft: 20}} src={`./img/login_logo.png`} alt="login page logo"/>

          <img style={{width: 24, marginLeft: 'auto'}} src={`./img/ic_hospital.png`} alt="hospital icon"/>
          <div style={{marginLeft: 6, fontSize: 14}}>{pageState.hospital} 로그인됨  |  </div>
          <img style={{width: 20, marginLeft: 6}} src={`./img/info.png`} alt="info icon"/>
          <a href="https://ecrf-guide-01.notion.site/eCRF-39b54c5eba914113bc9b70f3bd7f048b" target="_blank" rel="noopener noreferrer"
             style={{marginLeft: 4, fontSize: 14, cursor: 'pointer', textDecoration: 'underline'}}>
            가이드 링크
          </a>
          <span style={{marginLeft: 4,fontSize: 14}}>|</span>

          {pageState.hospital === '관리자' ?
              <div style={{marginLeft: 6, fontSize: 14, cursor: 'pointer'}}
                   onClick={async() => {
                     await LoadPushData()
                     setModalState({
                       type: 'admin',
                       mode: "push"
                     })
                   }}><span style={{textDecoration: 'underline', marginRight: 4}}>Admin</span> | </div>
              : null}
          <div style={{marginLeft: 6, marginRight: 20, fontSize: 14, cursor: 'pointer', textDecoration: 'underline'}}
               onClick={() => {
                 Logout();
               }}>로그아웃</div>
      </div>

      {pageState.hospital === '관리자' && modalState.type === "admin" ?
      <div className={cn('modalBase')} style={{minHeight: 740}}>
        <div className={cn('modalPaper')} style={{width: 800, height: 650, padding: 20}}>
          <div style={{display: 'flex', height: 40, fontSize: '22px', fontWeight: 500, alignItems: 'center', gap: 10}}>
            
            <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', gap: 10}}>
              <div>관리자 설정</div>
            </div>
            
            <img style={{width: 24, height: 24, marginLeft: 'auto', alignSelf: 'start', cursor: 'pointer'}} src={`./img/btn_close.png`} alt="dashboard background"
              onClick={() => {
                setModalState({
                  type: "",
                  mode: ""
                })
              }}/>
          </div>

          <div style={{display: 'flex', height: 20, borderRadius: 8, background: 'white', marginTop: 10, padding: 20, alignItems: 'center'}}>
            <div style={{fontSize: '20px', fontWeight: 700}}>설문 미참여자 대상 월별 푸시 설정</div>
            <div style={{display: 'grid', gap: 10, padding: '8px 12px', marginLeft: 'auto', gridTemplateColumns: '70px 60px 60px 60px 60px 60px ', alignItems: 'center', fontSize: '14px', 
                            background: '#ebebeb', borderRadius: '22px', boxShadow: 'inset 0 0 2px gray'}}>
                <div className={cn({'graphButton': true, 'graphButtonSelected': modalState.mode === 'push'})}
                onClick={() => {
                  setModalState({
                    ...modalState,
                    mode: "push"
                  })
                }}>PUSH</div>
              
              </div>
          </div>

          <div style={{width: '100%', height: 700, marginTop: 20, fontSize: '12px', textAlign: 'center', borderRadius: '4px', border: '1px solid #A2A2A2', background: 'white'}}>
            <div style={{display: 'grid', gridTemplateColumns: '0.7fr 3fr', marginTop: 20, marginLeft: 20, gap: 30, height: 40, fontSize: 16, lineHeight: '40px', fontWeight: 700, textAlign: 'center'}}>
            <div style={{borderRadius: 8, border: '1.5px solid gray'}}>푸시 전송 여부</div>

                    <form style={{border: 'none', display: 'flex', alignItems: 'center', gap: 3}}>
                      <input 
                        type='radio'
                        id="pushTrue"
                        name="pushRadio"
                        defaultChecked={adminState.pushEnabled}
                        onChange={(e) => {
                          console.log(e.target.checked)
                          if (e.target.checked) {
                            setAdminState({
                              ...adminState,
                              pushEnabled: true
                            });
                          }
                          
                        }} />
                      <label style={{marginTop: 3}}>ON</label>
                      <input 
                        type='radio'
                        id="pushFalse"
                        name="pushRadio"
                        defaultChecked={!adminState.pushEnabled}
                        style={{marginLeft: 15}}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setAdminState({
                              ...adminState,
                              pushEnabled: false
                            });
                          }
                        }} />
                      <label style={{marginTop: 3}}>OFF</label>
                    </form>
            </div>


            <div style={{display: 'grid', gridTemplateColumns: '0.7fr 3fr', marginTop: 20, marginLeft: 20, gap: 30, height: 40, fontSize: 16, lineHeight: '40px', fontWeight: 700, textAlign: 'center'}}>
              <div style={{borderRadius: 8, border: '1.5px solid gray'}}>푸시 전송 시점</div>
              <div style={{display: 'flex', gap: 10, alignItems: 'center'}}>
                <div>매월</div>
                <input 
                  type='number'
                  className={cn('cellTextInput')}
                  style={{width: 60, textAlign: 'center', height: '100%', background: '#F3F3F3', borderRadius: 8, fontSize: 20, marginLeft: 10}}
                  defaultValue={adminState.pushDayBefore}
                  onChange={(e) => {
                    setAdminState({
                      ...adminState,
                      pushDayBefore: Number(e.target.value)
                    })
                  }}>
                </input>
                <div>일전</div>
                <input 
                  type='number'
                  className={cn('cellTextInput')}
                  style={{width: 60, textAlign: 'center', height: '100%', background: '#F3F3F3', borderRadius: 8, fontSize: 20, marginLeft: 10}}
                  defaultValue={adminState.pushHour}
                  onChange={(e) => {
                    setAdminState({
                      ...adminState,
                      pushHour: Number(e.target.value)
                    })
                  }}>
                </input>
                <div>시</div>
                <input 
                  type='number'
                  className={cn('cellTextInput')}
                  style={{width: 60, textAlign: 'center', height: '100%', background: '#F3F3F3', borderRadius: 8, fontSize: 20, marginLeft: 10}}
                  defaultValue={adminState.pushMinute}
                  onChange={(e) => {
                    setAdminState({
                      ...adminState,
                      pushMinute: Number(e.target.value)
                    })
                  }}>
                </input>
                <div>분</div>
                <div>(KST 0시 ~ 23시 59분)</div>

              </div>
            </div>

            <div style={{display: 'grid', gridTemplateColumns: '0.7fr 3fr', marginTop: 20, marginLeft: 20, gap: 30, height: 160, fontSize: 16, lineHeight: '40px', fontWeight: 700, textAlign: 'center'}}>
              <div style={{borderRadius: 8, border: '1.5px solid gray', height: 40}}>푸시 전송 내용</div>
              <div>
                <div style={{display: 'flex', gap: 20, alignItems: 'center', height: 40}}>
                  <div>제목</div>
                  <input 
                    type='text'
                    className={cn('cellTextInput')}
                    style={{width: 400, height: '100%', background: '#F3F3F3', borderRadius: 8, fontSize: 18, paddingLeft: 15}}
                    defaultValue={adminState.pushTitle}
                    onChange={(e) => {
                      setAdminState({
                        ...adminState,
                        pushTitle: e.target.value
                      })
                    }}>
                  </input>
                </div>
                <div style={{display: 'flex', gap: 20, alignItems: 'center', height: 100, marginTop: 10}}>
                  <div style={{alignSelf: 'start'}}>내용</div>
                  <textarea 
                    className={cn('cellTextInput')}
                    style={{width: 390, height: 100, background: '#F3F3F3', borderRadius: 8, fontSize: 18, marginTop: 40, padding: 15, resize: 'none', lineHeight: '20px'}}
                    defaultValue={adminState.pushDesc}
                    onChange={(e) => {
                      setAdminState({
                        ...adminState,
                        pushDesc: e.target.value
                      })
                    }}>
                  </textarea>

                </div>
              </div>
            </div>

            <div style={{display: 'grid', gridTemplateColumns: '0.7fr 3fr', marginTop: 30, marginLeft: 20, gap: 30, height: 40, fontSize: 16, lineHeight: '40px', fontWeight: 700, textAlign: 'center'}}>
              <div style={{borderRadius: 8, border: '1.5px solid gray'}}>푸시 반복 전송 여부</div>
              <form style={{border: 'none', display: 'flex', alignItems: 'center', gap: 3}}>
                <input 
                  type='radio'
                  id="repeatTrue"
                  name="repeatRadio"
                  defaultChecked={adminState.repeatEnabled}
                  onChange={(e) => {
                    console.log(e.target.checked)
                    if (e.target.checked) {
                      setAdminState({
                        ...adminState,
                        repeatEnabled: true
                      });
                    }
                    
                  }} />
                <label style={{marginTop: 3}}>ON</label>
                <input 
                  type='radio'
                  id="repeatFalse"
                  name="repeatRadio"
                  defaultChecked={!adminState.repeatEnabled}
                  style={{marginLeft: 15}}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setAdminState({
                        ...adminState,
                        repeatEnabled: false
                      });
                    }
                  }} />
                <label style={{marginTop: 3}}>OFF</label>
              </form>
            </div>

            <div style={{display: 'grid', gridTemplateColumns: '0.7fr 3fr', marginTop: 20, marginLeft: 20, gap: 30, height: 40, fontSize: 16, lineHeight: '40px', fontWeight: 700, textAlign: 'center'}}>
              <div style={{borderRadius: 8, border: '1.5px solid gray'}}>안내</div>
              <div style={{display: 'flex', gap: 20, alignItems: 'center'}}>
                
                <div>해당 월에 설문을 참여하지 않은 사용자에게만 푸시가 전송됩니다.</div>

              </div>
            </div>

            
            <div style={{display: 'flex', gap: 20, justifyContent: 'center'}}>
            <button style={{width: 160, height: 36, border: 'none', background: '#3A57E8', borderRadius: '4px', cursor: 'pointer', color: 'white', marginTop: 20, fontSize: 16, fontWeight: 700}}
                onClick={async () => {
                  console.log(adminState)

                  if (adminState.pushDayBefore > 20 || adminState.pushDayBefore < 0 || !Number.isInteger(adminState.pushDayBefore)) {
                    setPopupState({
                      type: "message",
                      mode: "fail",
                      title: "날짜를 올바르게 입력해주세요.",
                      desc: "범위: 0~20 / 정수만 입력 가능"
                    })
                    return;
                  } 
                  else if (adminState.pushHour > 23 || adminState.pushHour < 0 || !Number.isInteger(adminState.pushHour)) {
                    setPopupState({
                      type: "message",
                      mode: "fail",
                      title: "시간(시)을 올바르게 입력해주세요.",
                      desc: "범위: 0~23 / 정수만 입력 가능"
                    })
                    return;
                  } 
                  else if (adminState.pushMinute > 59 || adminState.pushMinute < 0 || !Number.isInteger(adminState.pushMinute)) {
                    setPopupState({
                      type: "message",
                      mode: "fail",
                      title: "시간(분)을 올바르게 입력해주세요.",
                      desc: "범위: 0~59 / 정수만 입력 가능"
                    })
                    return;
                  }

                  await UpdatePushData();
                  setModalState({
                    type: "",
                    mode: ""
                  })

                }}>
                저장
              </button>
            </div>
            

          </div>


        </div>
      </div>
      : null}

      
      <div style={{height: 'calc(100% - 50px)', display: 'flex'}}>
        <div style={{width: 190, padding: '16px 12px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <img style={{width: 170, cursor: 'pointer'}} src={`./img/btn_participant_list.png`} alt="participant list button"
            onClick={() => {
              setPageState(prevState => ({
                ...prevState,
                tab: 'participant',
                participant_id: ''
              }))
              LoadParticipantList();
            }}/>
          <div style={{width: 170, height: 1, borderBottom: '1px solid #E9ECEF', marginTop: 16}}/>

          <div style={{width: 150}}>
            <div style={{fontSize: '14px', fontWeight: '600', marginTop: 16, color: '#ADB5BD', marginBottom: 20}}>최근 본 참여자</div>

            {recentViewed.map((id, idx) => {
              return(
                <div>
                  <div style={{display: 'flex', alignItems: 'center', marginBottom: 15, cursor: 'pointer'}}
                    onClick={async () => {
                      setIsLoading(true);
                      setPageState(prevState => ({
                        ...prevState,
                        participant_id: id
                      }))
                      await LoadParticipantInfo(id);
                      await LoadDashboardData(id);
                      setPageState(prevState => ({
                        ...prevState,
                        tab: 'dashboard',
                        participant_id: id
                      }))
                      setIsLoading(false);
                    }}>
                    <img style={{width: 24}} src={`./img/ic_user.png`} alt="user icon"/>
                    <div style={{fontSize: '14px', fontWeight: '400', marginLeft: 10, color: '#8A92A6'}}>{markName(findParcitipantNameById(id))}</div>
                  </div>
                </div>
              )
            })
            }

          </div>
        </div>

        <div style={{width: 'calc(100% - 190px)', background: '#E9ECEF'}}>
          <img style={{height: 170, width: '100%', objectFit: 'cover', borderRadius: '0 0 16px 16px'}} src={`./img/dashboard_bg.svg`} alt="dashboard background"/>
          <div style={{position: 'relative', fontSize: '28px', fontWeight: '700', marginLeft: 40, top: -140, color: 'white'}}>
            {pageState.hospital}
          </div> 

          {/* 메인 리스트 paper */}
          { pageState.tab === "participant" ?
          <div style={{position: 'relative', width: '94%', height: '75%', background: 'white', borderRadius: '8px', boxShadow: '0px 10px 13px rgba(17, 38, 146, 0.05)', top: -115, marginLeft: 40, overflow: 'auto'}}>
            <div style={{display: 'flex', alignItems: 'center', height: 60, padding: '0 20px', borderBottom: '1px solid #E9ECEF', gap: 10}}>
              <div style={{fontSize: '24px', fontWeight: '700'}}>참여자 리스트</div>
              <div style={{fontSize: '18px', fontWeight: '700', color: '#54595E', marginTop: 5}}> 
                {(pageState.page-1) * 10 + 1}-{(pageState.page)*10 > pageState.total ? pageState.total : (pageState.page)*10} / total: {pageState.total}
              </div>
            </div>

            <div style={{height: 40, display: 'grid', gridTemplateColumns: '10% 15% 20% 20% 20% 1fr', alignItems: 'center', padding: '0 20px', fontWeight: 600, fontSize: '16px', color: '#54595E', borderBottom: '1px solid #E9ECEF'}}>
              <div style={{display:'flex', gap: 10, alignItems: 'center'}}>
                <>성명</>
                <img style={{width: 20, cursor: 'pointer', opacity: sortState.sort_by === 'participant_name' ? 1 : 0.5}} 
                  src={`./img/ic_sort_${sortState.participant_name}.png`} alt="오름차순 정렬"
                  onClick={() => {
                    setSortState((prevState) => ({
                      ...prevState,
                      sort_by: 'participant_name',
                      participant_name: sortState.sort_by !== 'participant_name' ? prevState.participant_name : sortState.participant_name === 'desc' ? 'asc' : 'desc'
                    }))
                  }}/>
              </div>
              <div style={{display:'flex', gap: 10, alignItems: 'center'}}>
                <>연구 등록 번호</>
                <img style={{width: 20, cursor: 'pointer', opacity: sortState.sort_by === 'participant_id' ? 1 : 0.5}} 
                  src={`./img/ic_sort_${sortState.participant_id}.png`} alt="오름차순 정렬"
                  onClick={() => {
                    setSortState((prevState) => ({
                      ...prevState,
                      sort_by: 'participant_id',
                      participant_id: sortState.sort_by !== 'participant_id' ? prevState.participant_id : sortState.participant_id === 'desc' ? 'asc' : 'desc'
                    }))
                  }}/>
              </div>
              <div style={{display:'flex', gap: 10, alignItems: 'center'}}>
                <>가입일자</>
                <img style={{width: 20, cursor: 'pointer', opacity: sortState.sort_by === 'created_at' ? 1 : 0.5}} 
                  src={`./img/ic_sort_${sortState.created_at}.png`} alt="오름차순 정렬"
                  onClick={() => {
                    setSortState((prevState) => ({
                      ...prevState,
                      sort_by: 'created_at',
                      created_at: sortState.sort_by !== 'created_at' ? prevState.created_at : sortState.created_at === 'desc' ? 'asc' : 'desc'
                    }))
                  }}/>
              </div>
              <div style={{display:'flex', gap: 10, alignItems: 'center'}}>
                <>최근 설문참여일</>
                <img style={{width: 20, cursor: 'pointer', opacity: sortState.sort_by === 'last_surveyed_at' ? 1 : 0.5}} 
                  src={`./img/ic_sort_${sortState.last_surveyed_at}.png`} alt="오름차순 정렬"
                  onClick={() => {
                    setSortState((prevState) => ({
                      ...prevState,
                      sort_by: 'last_surveyed_at',
                      last_surveyed_at: sortState.sort_by !== 'last_surveyed_at' ? prevState.last_surveyed_at : sortState.last_surveyed_at === 'desc' ? 'asc' : 'desc'
                    }))
                  }}/>
              </div>
              <div style={{display:'flex', gap: 10, alignItems: 'center'}}>
                <>최근 업데이트일</>
                <img style={{width: 20, cursor: 'pointer', opacity: sortState.sort_by === 'updated_at' ? 1 : 0.5}} 
                  src={`./img/ic_sort_${sortState.updated_at}.png`} alt="오름차순 정렬"
                  onClick={() => {
                    setSortState((prevState) => ({
                      ...prevState,
                      sort_by: 'updated_at',
                      updated_at: sortState.sort_by !== 'updated_at' ? prevState.updated_at : sortState.updated_at === 'desc' ? 'asc' : 'desc'
                    }))
                  }}/>
              </div>
              <div>상세 정보 및 관리</div>
            </div>

            {listData.slice((pageState.page-1) * 10, (pageState.page)*10).map((item, idx) => {
              return (
              <div style={{height: 36, display: 'grid', gridTemplateColumns: '10% 15% 20% 20% 20% 1fr', alignItems: 'center', padding: '0 20px', fontWeight: 400, fontSize: '14px', borderBottom: '1px solid #E9ECEF',
                background: idx%2 === 1 ? '#F6F6F6' : 'none'}}>
                <div>{markName(item.participant_name)}</div>
                <div>{item.participant_id}</div>
                <div>{item.created_at ? item.created_at.split("T")[0] : ""}</div>
                <div>{item.last_surveyed_at ? item.last_surveyed_at.split("T")[0] : ""}</div>
                <div>{item.updated_at ? item.updated_at.split("T")[0] : ""}</div>
                <div>
                  <button style={{width: 110, height: 24, border: 'none', background: '#54595E', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                    onClick={async () => {
                      setIsLoading(true);
                      setPageState(prevState => ({
                        ...prevState,
                        participant_id: item.participant_id
                      }))
                      pushToRecentViewed(item.participant_id);
                      await LoadParticipantInfo(item.participant_id);
                      await LoadDashboardData(item.participant_id);
                      setPageState(prevState => ({
                        ...prevState,
                        tab: 'dashboard',
                        participant_id: item.participant_id
                      }))
                      setIsLoading(false);
                    }}>
                    상세 보기
                  </button>
                </div>
              </div>)
            })}


            <div style={{display: 'flex', gap: 3, justifyContent: 'center', alignItems: 'center', width: '100%', height: 40, position: 'absolute', bottom: 5}}>
              <img style={{width: 24, marginRight: 5, cursor: 'pointer'}} src={pageState.page !== 1 ? `./img/ic_arrow_left_enabled.png` : `./img/ic_arrow_left_disabled.png`} alt="user icon"
                onClick={() => {
                  if (pageState.page === 1) return;
                  setPageState(prevState => ({
                    ...prevState,
                    page: prevState.page-1
                  }))
                }}/>

              {Array.apply(null, new Array(pageState.total_page)).map((v, i) => {
              return (
                <div className={cn({'pageButton': true, 'pageButtonSelected': pageState.page === i+1})}
                  onClick={() => {
                    setPageState(prevState => ({
                      ...prevState,
                      page: i+1
                    }))
                  }}>
                  {i+1}
                </div>
              )})}

              <img style={{width: 24, marginLeft: 5, cursor: 'pointer'}} src={pageState.page !== pageState.total_page && pageState.total_page !== 0 ? `./img/ic_arrow_right_enabled.png` : `./img/ic_arrow_right_disabled.png`} alt="user icon"
                onClick={() => {
                  if (pageState.page === pageState.total_page || pageState.total_page === 0) return;
                  setPageState(prevState => ({
                    ...prevState,
                    page: prevState.page+1
                  }))
                }}/>

            </div>

          </div> : null}

          {/* 탭 네비게이션 */}
          { pageState.tab !== "participant" ?
          <div style={{position: 'relative', width: '94%', top: -115, marginLeft: 40, overflow: 'auto'}}>
            <div style={{display: 'flex', alignItems: 'center', height: 60, padding: '0 20px', background: 'white', borderRadius: '8px', gap: 10}}>
              <div style={{fontSize: '24px', fontWeight: '700'}}>{markName(participantInfo.participant_name)}</div>
              <div style={{fontSize: '16px', fontWeight: '400', color: '#232D42', marginTop: 5}}>| 연구 등록 번호 {participantInfo.participant_id}</div>
            </div>

            <div style={{display: 'grid', gap: 2, marginTop: 30, gridTemplateColumns: '80px 80px 70px 90px 80px 80px 140px 1fr', alignItems: 'center', fontSize: '14px'}}>
              <div className={cn({'tabButton': true, 'tabButtonSelected': pageState.tab === 'dashboard'})}
                onClick={() => {
                  LoadDashboardData(participantInfo.participant_id);
                  setPageState(prevState => ({...prevState, tab: 'dashboard'}));
                }}>대시보드</div>
              <div className={cn({'tabButton': true, 'tabButtonSelected': pageState.tab === 'survey'})} 
                onClick={() => {
                  setPageState(prevState => ({...prevState, tab: 'survey', max_per_page: 6}));
                  LoadSurveyData(participantInfo.participant_id);
                }}>설문결과</div>
              <div className={cn({'tabButton': true, 'tabButtonSelected': pageState.tab === 'lab'})} 
                onClick={() => {
                  setPageState(prevState => ({...prevState, tab: 'lab', max_per_page: 5}));
                  LoadLabData(participantInfo.participant_id);
                }}>Lab</div>
              <div className={cn({'tabButton': true, 'tabButtonSelected': pageState.tab === 'scorad'})} 
                onClick={() => {
                  setPageState(prevState => ({...prevState, tab: 'scorad', max_per_page: 5}));
                  LoadScoradData(participantInfo.participant_id);
                }}>SCORAD</div>
              <div className={cn({'tabButton': true, 'tabButtonSelected': pageState.tab === 'easi'})}
                onClick={() => {
                  setPageState(prevState => ({...prevState, tab: 'easi', max_per_page: 5}));
                  LoadEasiData(participantInfo.participant_id);
                }}>EASI</div>
              <div className={cn({'tabButton': true, 'tabButtonSelected': pageState.tab === 'adr'})}
                onClick={() => {
                  setPageState(prevState => ({...prevState, tab: 'adr', max_per_page: 5}));
                  LoadAdrData(participantInfo.participant_id);
                }}>부작용</div>
              <div className={cn({'tabButton': true, 'tabButtonSelected': pageState.tab === 'oas'})} style={{marginLeft: 10}}
                onClick={() => {
                  setPageState(prevState => ({...prevState, tab: 'oas', max_per_page: 5}));
                  LoadOasData(participantInfo.participant_id);
                }}>구강알레르기 증후군</div>
              <div className={cn({'tabButton': true, 'tabButtonSelected': true})} style={{marginLeft: 'auto', width: 80, background: '#2875e6', borderRadius: '50px'}}
                onClick={() => {
                  setModalState({
                    type: "chart"
                  });
                  setChartState({
                    tab: "scorad"
                  });
                  LoadScoradChartData(participantInfo.participant_id);
                }}>Chart</div>
            </div>
          </div> : null}


          {/* 환자별 대시보드 paper */}
          { pageState.tab === "dashboard" ?
          <div style={{position: 'relative', width: '94%', height: '75%', top: -115, marginLeft: 40, overflow: 'auto'}}>
            <div style={{display: 'flex', gap: 10, marginTop: 15}}>
              <div style={{width: '49.5%', height: 70, background: 'white', boxShadow: '0px 10px 13px rgba(17, 38, 146, 0.05)', borderRadius: '8px', padding: 15}}>
                <div style={{fontSize: 16, fontWeight: 600}}>환자 정보</div>
                <div style={{width: '100%', height: 20, marginTop: 12, display: 'grid', gridTemplateColumns: '0.7fr 1fr 1fr 1fr', color:'rgba(84, 89, 94, 0.6)', fontSize: 12, fontWeight: 400}}>
                  <div>성명</div>
                  <div>연구 등록 번호</div>
                  <div>가입일자</div>
                  <div>최근 설문참여일</div>
                </div>
                <div style={{width: '100%', height: 20, marginTop: 4, display: 'grid', gridTemplateColumns: '0.7fr 1fr 1fr 1fr', color:'#54595E', fontSize: 14, fontWeight: 600}}>
                  <div>{markName(participantInfo.participant_name)}</div>
                  <div>{participantInfo.participant_id}</div>
                  <div>{participantInfo.created_at ? participantInfo.created_at.slice(0,10) : "-"}</div>
                  <div>{participantInfo.last_surveyed_at ? participantInfo.last_surveyed_at.slice(0,10) : "-"}</div>
                </div>
              </div>


              <div style={{width: '49.5%', height: 70, background: 'white', boxShadow: '0px 10px 13px rgba(17, 38, 146, 0.05)', borderRadius: '8px', padding: 15}}>
                <div style={{fontSize: 16, fontWeight: 600, display: 'flex'}}>
                  <div>최근 설문 결과 보기</div>
                  <div style={{textDecoration: 'underline', color: '#3A57E8', fontSize: 14, fontWeight: 600, marginLeft: 'auto', cursor: 'pointer'}} onClick={() => {
                    setPageState(prevState => ({...prevState, tab: 'survey', max_per_page: 6}));
                    LoadSurveyData(pageState.participant_id);
                  }}>더보기</div>
                </div>
                <div style={{display: 'flex', gap: 10, marginTop: 16}}>
                  {dashboardSurveyData.map((item, idx) => {
                    return (
                    <button style={{width: 100, height: 35, border: 'none', background: '#3A57E8', borderRadius: '4px', cursor: 'pointer', color: 'white', fontSize: 12}}
                      onClick={() => {
                        LoadDetailSurveyData(item);
                        setModalState({
                          type: "survey",
                          mode: "detail",
                          created_at: item.created_at ? item.created_at.slice(0,10) : "-",
                          updated_at: item.updated_at ? item.updated_at.slice(0,10) : "-",
                          posted_at: item.posted_at ? item.posted_at.slice(0,10) : "-"
                        });
                      }}>
                      {item.surveyed_at.slice(0,10)}
                    </button>
                    )
                  })}
                </div>
              
              </div>
            </div>

            <div style={{maxHeight: 300, marginTop: 14, background: 'white', boxShadow: '0px 10px 13px rgba(17, 38, 146, 0.05)', borderRadius: '8px', padding: 15, overflowY: 'auto'}}>
              <div style={{fontSize: 16, fontWeight: 600}}>최근 입력한 결과 및 점수</div>
              <div className={cn('dashboardTableTop')} style={{marginTop: 10}}>
                <div>항목</div>
                <div>작성 내용</div>
                <div>작성 일시</div>
                <div/><div/>
              </div>



              <div className={cn('dashboardTableRow1')}>
                <div>Lab</div>
                <div>
                  {dashboardLabData.length > 0 
                  ? Object.entries(dashboardLabData[0]).map((element: any, idx: number, arr) => {
                    let [key, value] = element;
                    if (value === null || key.substring(9, 11).length !== 2) {
                     if (idx-1 >= 0 && arr[idx-1][1] !== null && arr[idx-1][0].charAt(12) === 'v') return " | "
                    }
                    else if (key.charAt(12) === 'v') return LAB_ITEMS_COLUMNS[key.substring(9, 11)] + ": " + value;
                    else if (key.charAt(12) === 'c') {
                      if (idx-1 >= 0 && arr[idx-1][1] !== null) return ", " + value + " | "
                      else return LAB_ITEMS_COLUMNS[key.substring(9, 11)] + ": " + value + " | ";
                    }
                  })
                  : "없음"}
                </div>
                <div>
                  {dashboardLabData.length > 0 
                  ? dashboardLabData[0].created_at.slice(0,10)
                  : ""}
                </div>

                <div style={{color: '#3A57E8', cursor: 'pointer', textAlign: 'center'}}
                  onClick={() => {
                    LoadDetailLabData(dashboardLabData[0])
                    setModalState({
                      type: "lab",
                      mode: "edit",
                      created_at: dashboardLabData[0].created_at ? dashboardLabData[0].created_at.slice(0,10) : "-",
                      updated_at: dashboardLabData[0].updated_at ? dashboardLabData[0].updated_at.slice(0,10) : "-",
                      posted_at: dashboardLabData[0].posted_at ? dashboardLabData[0].posted_at.slice(0,10) : "-",
                      metadata_id: dashboardLabData[0].metadata_id
                    })
                  }}>
                  {dashboardLabData.length > 0 
                  ? "수정"
                  : ""}
                </div>
                <div style={{color: '#3A57E8', cursor: 'pointer', textAlign: 'center'}}
                  onClick={() => {
                    setLabDate(customYMD(Date.now()));
                    setLabNumData({});
                    setLabClassData({});
                    setModalState({
                      type: "lab",
                      mode: "new"
                    })
                  }}>
                  신규등록
                </div>
              </div>



              <div className={cn('dashboardTableRow2')}>
                <div>SCORAD</div>
                <div style={{whiteSpace: 'pre-wrap'}}>
                {dashboardScoradData.length > 0 
                  ? `Extent : ${dashboardScoradData[0].response_extent}
Intensity : ${dashboardScoradData[0].response_intensity}
Subjective symptoms : ${dashboardScoradData[0].response_subj_symptoms}
Total score : ${dashboardScoradData[0].total_score}`
                  : "없음"}
                </div>
                <div>
                {dashboardScoradData.length > 0 
                  ? dashboardScoradData[0].created_at.slice(0,10)
                  : ""}
                </div>
                <div style={{color: '#3A57E8', cursor: 'pointer', textAlign: 'center'}}
                  onClick={() => {
                    LoadDetailScoradData(dashboardScoradData[0])
                    setModalState({
                      type: "scorad",
                      mode: "edit",
                      created_at: dashboardScoradData[0].created_at ? dashboardScoradData[0].created_at.slice(0,10) : "-",
                      updated_at: dashboardScoradData[0].updated_at ? dashboardScoradData[0].updated_at.slice(0,10) : "-",
                      posted_at: dashboardScoradData[0].posted_at ? dashboardScoradData[0].posted_at.slice(0,10) : "-",
                      metadata_id: dashboardScoradData[0].metadata_id
                    })
                  }}>
                  {dashboardScoradData.length > 0 
                  ? "수정"
                  : ""}
                </div>
                <div style={{color: '#3A57E8', cursor: 'pointer', textAlign: 'center'}}
                  onClick={() => {
                    setScoradData({
                      entered_date: customYMD(Date.now()),
                      response_extent: 0,
                      response_intensity: 0,
                      response_subj_symptoms: 0,
                      total_score: 0
                    });
                    setModalState({
                      type: "scorad",
                      mode: "new"
                    })
                  }}>
                  신규등록
                </div>
              </div>



              <div className={cn('dashboardTableRow1')}>
                <div>EASI</div>
                <div>
                {dashboardEasiData.length > 0 
                  ? dashboardEasiData[0].response_easi
                  : "없음"}
                </div>
                <div>
                {dashboardEasiData.length > 0 
                  ? dashboardEasiData[0].created_at.slice(0,10)
                  : ""}
                </div>
                <div style={{color: '#3A57E8', cursor: 'pointer', textAlign: 'center'}}
                  onClick={() => {
                    LoadDetailEasiData(dashboardEasiData[0])
                    setModalState({
                      type: "easi",
                      mode: "edit",
                      created_at: dashboardEasiData[0].created_at ? dashboardEasiData[0].created_at.slice(0,10) : "-",
                      updated_at: dashboardEasiData[0].updated_at ? dashboardEasiData[0].updated_at.slice(0,10) : "-",
                      posted_at: dashboardEasiData[0].posted_at ? dashboardEasiData[0].posted_at.slice(0,10) : "-",
                      metadata_id: dashboardEasiData[0].metadata_id
                    })
                  }}>
                  {dashboardEasiData.length > 0 
                  ? "수정"
                  : ""}
                </div>
                <div style={{color: '#3A57E8', cursor: 'pointer', textAlign: 'center'}}
                  onClick={() => {
                    setEasiData({
                      entered_date: customYMD(Date.now()),
                      response_easi: 0
                    });
                    setModalState({
                      type: "easi",
                      mode: "new"
                    })
                  }}>
                  신규등록
                </div>
              </div>



              <div className={cn('dashboardTableRow2')}>
                <div>부작용</div>
                <div>
                {dashboardAdrData.length > 0 
                  ? dashboardAdrData[0].response_adr
                  : "없음"}
                </div>
                <div>
                {dashboardAdrData.length > 0 
                  ? dashboardAdrData[0].created_at.slice(0,10)
                  : ""}
                </div>
                <div style={{color: '#3A57E8', cursor: 'pointer', textAlign: 'center'}}
                  onClick={() => {
                    LoadDetailAdrData(dashboardAdrData[0])
                    setModalState({
                      type: "adr",
                      mode: "edit",
                      created_at: dashboardAdrData[0].created_at ? dashboardAdrData[0].created_at.slice(0,10) : "-",
                      updated_at: dashboardAdrData[0].updated_at ? dashboardAdrData[0].updated_at.slice(0,10) : "-",
                      posted_at: dashboardAdrData[0].posted_at ? dashboardAdrData[0].posted_at.slice(0,10) : "-",
                      metadata_id: dashboardAdrData[0].metadata_id
                    })
                  }}>
                  {dashboardAdrData.length > 0 
                  ? "수정"
                  : ""}
                </div>
                <div style={{color: '#3A57E8', cursor: 'pointer', textAlign: 'center'}}
                  onClick={() => {
                    setAdrData({
                      entered_date: customYMD(Date.now()),
                      response_adr: ""
                    });
                    setModalState({
                      type: "adr",
                      mode: "new"
                    })
                  }}>
                  신규등록
                </div>
              </div>



              <div className={cn('dashboardTableRow1')}>
                <div>구강알레르기 증후군</div>
                <div>
                {dashboardOasData.length > 0 
                  ? Object.entries(dashboardOasData[0]).map((element: any, idx: number) => {
                    let [key, value] = element;
                      if (key === "existence_of_oas") return "증후군 여부: " + (value===false?"No":"Yes - 유발식품: ");
                      if (key.charAt(0) === "r" && value !== null) return value + ", ";
                      return;
                  })
                  : "없음"}
                </div>
                <div>
                {dashboardOasData.length > 0 
                  ? dashboardOasData[0].created_at.slice(0,10)
                  : ""}
                </div>
                <div style={{color: '#3A57E8', cursor: 'pointer', textAlign: 'center'}}
                  onClick={() => {
                    LoadDetailOasData(dashboardOasData[0])
                    setModalState({
                      type: "oas",
                      mode: "edit",
                      created_at: dashboardOasData[0].created_at ? dashboardOasData[0].created_at.slice(0,10) : "-",
                      updated_at: dashboardOasData[0].updated_at ? dashboardOasData[0].updated_at.slice(0,10) : "-",
                      posted_at: dashboardOasData[0].posted_at ? dashboardOasData[0].posted_at.slice(0,10) : "-",
                      metadata_id: dashboardOasData[0].metadata_id
                    })
                  }}>
                  {dashboardOasData.length > 0 
                  ? "수정"
                  : ""}
                </div>
                <div style={{color: '#3A57E8', cursor: 'pointer', textAlign: 'center'}}
                  onClick={() => {
                    setOasData({
                      entered_date: customYMD(Date.now()),
                    });
                    setModalState({
                      type: "oas",
                      mode: "new"
                    })
                  }}>
                  신규등록
                </div>
              </div>
            </div>



          </div> : null}


          {/* 설문결과 paper */}
          { pageState.tab === "survey" ?
          <div style={{position: 'relative', width: '94%', height: '75%', top: -115, marginLeft: 40, overflow: 'auto'}}>
            {listData.map((item, idx) => {
              return (
              <div style={{padding: '0 15px', height: 48, background: 'white', boxShadow: '0px 10px 13px rgba(17, 38, 146, 0.05)', borderRadius: '8px', marginTop: 10, display: 'grid', gridTemplateColumns: '1.2fr 0.7fr 0.8fr 0.6fr 0.7fr 4fr 110px', alignItems: 'center', fontSize: '14px', color: '#54595E'}}>
                <div style={{fontWeight: 600, fontSize: '16px', color: 'black'}}>{item.surveyed_at.slice(0,10)}</div>
                <div>ADCT : {item.adct_score}</div>
                <div>{item.response_2_age === 1 ? 'IDLQI' : item.response_2_age === 2 ? 'CDLQI' : 'DLQI'} : {item.qol_score}</div>
                <div>DFI : {item.dfi_score}</div>
                <div>POEM : {item.poem_score}</div>
                <div style={{fontSize: '13px'}}>치료부작용 : {getAdrString(item) ?? "없음"}</div>
                <div>
                  <button style={{width: 110, height: 36, border: 'none', background: '#54595E', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                    onClick={() => {
                      LoadDetailSurveyData(item);
                      setModalState({
                        type: "survey",
                        mode: "detail",
                        created_at: item.created_at ? item.created_at.slice(0,10) : "-",
                        updated_at: item.updated_at ? item.updated_at.slice(0,10) : "-",
                        posted_at: item.posted_at ? item.posted_at.slice(0,10) : "-"
                      });
                    }}>
                    상세 보기
                  </button>
                </div>
              </div>
            )})}

            <div style={{display: 'flex', gap: 3, justifyContent: 'center', alignItems: 'center', width: '100%', height: 40, position: 'absolute', bottom: 100}}>
              <img style={{width: 24, marginRight: 5, cursor: 'pointer'}} src={pageState.page !== 1 ? `./img/ic_arrow_left_enabled.png` : `./img/ic_arrow_left_disabled.png`} alt="user icon"
                onClick={() => {
                  if (pageState.page === 1) return;
                  setPageState(prevState => ({
                    ...prevState,
                    page: prevState.page-1
                  }))
                }}/>

              {Array.apply(null, new Array(pageState.total_page)).map((v, i) => {
              return (
                <div className={cn({'pageButton': true, 'pageButtonSelected': pageState.page === i+1})}
                  onClick={() => {
                    setPageState(prevState => ({
                      ...prevState,
                      page: i+1
                    }))
                  }}>
                  {i+1}
                </div>
              )})}

              <img style={{width: 24, marginLeft: 5, cursor: 'pointer'}} src={pageState.page !== pageState.total_page && pageState.total_page !== 0 ? `./img/ic_arrow_right_enabled.png` : `./img/ic_arrow_right_disabled.png`} alt="user icon"
                onClick={() => {
                  if (pageState.page === pageState.total_page || pageState.total_page === 0) return;
                  setPageState(prevState => ({
                    ...prevState,
                    page: prevState.page+1
                  }))
                }}/>

            </div>

          </div> : null}


          {/* Lab paper */}
          { pageState.tab === "lab" ?
          <div style={{position: 'relative', width: '94%', height: '75%', top: -115, marginLeft: 40, overflow: 'auto'}}>

            <div style={{padding: '0 15px', height: 48, background: '#54595E19', boxShadow: '0px 10px 13px rgba(17, 38, 146, 0.05)', borderRadius: '8px', marginTop: 10, display: 'grid', gridTemplateColumns: '120px 120px 180px 1fr 110px 110px', alignItems: 'center', fontSize: '14px', color: '#54595E', fontWeight: 700, textAlign: 'center', gap: 10}}>
              <div>날짜</div>
              <div>등록일</div>
              <div>등록된 항목 수</div>
              <div></div>
              <div></div>
              <div>
                <button style={{width: 110, height: 36, border: 'none', background: '#3A57E8', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                  onClick={() => {
                    setLabDate(customYMD(Date.now()));
                    setLabType("");
                    setLabNumData({});
                    setLabClassData({});
                    setModalState({
                      type: "lab",
                      mode: "new"
                    })
                  }}>
                  신규 등록
                </button>
              </div>
            </div>

            {listData.sort((a, b) => ( b.entered_date < a.entered_date ) ? -1 : ( b.entered_date == a.entered_date ) ? 0 : 1).slice((pageState.page-1) * pageState.max_per_page, (pageState.page)*pageState.max_per_page).map((item, idx) => {
              return (
              <div style={{padding: '0 15px', height: 48, background: 'white', boxShadow: '0px 10px 13px rgba(17, 38, 146, 0.05)', borderRadius: '8px', marginTop: 10, display: 'grid', gridTemplateColumns: '120px 120px 180px 1fr 110px 110px 60px', alignItems: 'center', fontSize: '14px', color: '#54595E', fontWeight: 400, textAlign: 'center', gap: 10}}>
                {/* <div style={{fontSize: '15px', fontWeight: 700}}>{item.posted_at ? item.posted_at.slice(0,10) : "-"}</div> */}
                <div>{item.entered_date}</div>
                <div>{item.created_at.slice(0,10)}</div>
                <div>{countEnteredLabValues(item)}</div>
                <div></div>
                <div>
                  {item.is_posted ?
                  <button style={{width: 110, height: 36, border: 'none', background: '#3A57E8', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                    onClick={() => setDialogState({
                      isOpen: true,
                      title: "Lab 게시를 취소하시겠습니까?", 
                      desc: "게시 취소를 원하실 경우 게시 취소 버튼을 눌러주세요.\n더 이상 환자가 Lab 결과 화면을 조회할 수 없게 됩니다.",
                      accept_label: "게시 취소",
                      deny_label: "닫기",
                      accept_callback: () => {
                        changeLabPosted(item.metadata_id, false)
                      }
                    })}>
                    게시 취소
                  </button> : null}
                </div>
                <div>
                  <button style={{width: 110, height: 36, border: 'none', background: '#54595E', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                    onClick={() => {
                      LoadDetailLabData(item);
                      setModalState({
                        type: "lab",
                        mode: "detail",
                        created_at: item.created_at ? item.created_at.slice(0,10) : "-",
                        updated_at: item.updated_at ? item.updated_at.slice(0,10) : "-",
                        posted_at: item.posted_at ? item.posted_at.slice(0,10) : "-",
                        metadata_id: item.metadata_id
                      });
                    }}>
                    상세 보기
                  </button>
                </div>
                <div>
                  <button style={{width: 60, height: 36, border: 'none', background: '#FF6969', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                    onClick={() => {
                      setDialogState({
                        isOpen: true,
                        title: "해당 Lab 데이터를 삭제하시겠습니까?", 
                        desc: "삭제를 원하실 경우 삭제 버튼을 눌러주세요.\n삭제 후 복구할 수 없습니다.",
                        accept_label: "삭제",
                        deny_label: "닫기",
                        accept_callback: () => {
                          deleteLabData(item.metadata_id)
                        }
                      })
                    }}>
                    삭제
                  </button>
                </div>
              </div>
            )})}

            <div style={{display: 'flex', gap: 3, justifyContent: 'center', alignItems: 'center', width: '100%', height: 40, position: 'absolute', bottom: 100}}>
              <img style={{width: 24, marginRight: 5, cursor: 'pointer'}} src={pageState.page !== 1 ? `./img/ic_arrow_left_enabled.png` : `./img/ic_arrow_left_disabled.png`} alt="user icon"
                onClick={() => {
                  if (pageState.page === 1) return;
                  setPageState(prevState => ({
                    ...prevState,
                    page: prevState.page-1
                  }))
                }}/>

              {Array.apply(null, new Array(pageState.total_page)).map((v, i) => {
              return (
                <div className={cn({'pageButton': true, 'pageButtonSelected': pageState.page === i+1})}
                  onClick={() => {
                    setPageState(prevState => ({
                      ...prevState,
                      page: i+1
                    }))
                  }}>
                  {i+1}
                </div>
              )})}

              <img style={{width: 24, marginLeft: 5, cursor: 'pointer'}} src={pageState.page !== pageState.total_page && pageState.total_page !== 0? `./img/ic_arrow_right_enabled.png` : `./img/ic_arrow_right_disabled.png`} alt="user icon"
                onClick={() => {
                  if (pageState.page === pageState.total_page || pageState.total_page === 0) return;
                  setPageState(prevState => ({
                    ...prevState,
                    page: prevState.page+1
                  }))
                }}/>

            </div>

          </div> : null}


          {/* SCORAD paper */}
          { pageState.tab === "scorad" ?
          <div style={{position: 'relative', width: '94%', height: '75%', top: -115, marginLeft: 40, overflow: 'auto'}}>
            
            <div style={{padding: '0 15px', height: 48, background: '#54595E19', boxShadow: '0px 10px 13px rgba(17, 38, 146, 0.05)', borderRadius: '8px', marginTop: 10, display: 'grid', 
                          gridTemplateColumns: '120px 120px 90px 90px 180px 90px 1fr 110px', alignItems: 'center', fontSize: '14px', color: '#54595E', fontWeight: 700, textAlign: 'center', gap: 10}}>
              <div>날짜</div>
              <div>등록일</div>
              <div>Extent</div>
              <div>Intensity</div>
              <div>Subjective symptoms</div>
              <div>Total</div>
              <div></div>
              <div>
                <button style={{width: 110, height: 36, border: 'none', background: '#3A57E8', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                  onClick={() => {
                    setScoradData({
                      entered_date: customYMD(Date.now()),
                      response_extent: 0,
                      response_intensity: 0,
                      response_subj_symptoms: 0,
                      total_score: 0
                    });
                    setModalState({
                      type: "scorad",
                      mode: "new"
                    })
                  }}>
                  신규 등록
                </button>
              </div>
            </div>

            {listData.sort((a, b) => ( b.entered_date < a.entered_date ) ? -1 : ( b.entered_date == a.entered_date ) ? 0 : 1).slice((pageState.page-1) * pageState.max_per_page, (pageState.page)*pageState.max_per_page).map((item, idx) => {
              return (
                <div style={{padding: '0 15px', height: 48, background: 'white', boxShadow: '0px 10px 13px rgba(17, 38, 146, 0.05)', borderRadius: '8px', marginTop: 10, display: 'grid', 
                gridTemplateColumns: '120px 120px 90px 90px 180px 90px 1fr 110px 60px', alignItems: 'center', fontSize: '14px', color: '#54595E', fontWeight: 400, textAlign: 'center', gap: 10}}>
                  <div>{item.entered_date}</div>
                  <div>{item.created_at.slice(0,10)}</div>
                  <div>{item.response_extent}</div>
                  <div>{item.response_intensity}</div>
                  <div>{item.response_subj_symptoms}</div>
                  <div>{item.total_score}</div>
                  <div/>
                  <div>
                    <button style={{width: 110, height: 36, border: 'none', background: '#54595E', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                      onClick={() => {
                        LoadDetailScoradData(item);
                        setModalState({
                          type: "scorad",
                          mode: "detail",
                          created_at: item.created_at ? item.created_at.slice(0,10) : "-",
                          updated_at: item.updated_at ? item.updated_at.slice(0,10) : "-",
                          posted_at: item.posted_at ? item.posted_at.slice(0,10) : "-",
                          metadata_id: item.metadata_id
                        });
                      }}>
                      상세 보기
                    </button>
                  </div>
                  <div>
                    <button style={{width: 60, height: 36, border: 'none', background: '#FF6969', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                      onClick={() => {
                        setDialogState({
                          isOpen: true,
                          title: "해당 SCORAD 데이터를 삭제하시겠습니까?", 
                          desc: "삭제를 원하실 경우 삭제 버튼을 눌러주세요.\n삭제 후 복구할 수 없습니다.",
                          accept_label: "삭제",
                          deny_label: "닫기",
                          accept_callback: () => {
                            deleteScoradData(item.metadata_id)
                          }
                        })
                      }}>
                      삭제
                    </button>
                  </div>
                </div>
            )})}

            <div style={{display: 'flex', gap: 3, justifyContent: 'center', alignItems: 'center', width: '100%', height: 40, position: 'absolute', bottom: 100}}>
              <img style={{width: 24, marginRight: 5, cursor: 'pointer'}} src={pageState.page !== 1 ? `./img/ic_arrow_left_enabled.png` : `./img/ic_arrow_left_disabled.png`} alt="user icon"
                onClick={() => {
                  if (pageState.page === 1) return;
                  setPageState(prevState => ({
                    ...prevState,
                    page: prevState.page-1
                  }))
                }}/>

              {Array.apply(null, new Array(pageState.total_page)).map((v, i) => {
              return (
                <div className={cn({'pageButton': true, 'pageButtonSelected': pageState.page === i+1})}
                  onClick={() => {
                    setPageState(prevState => ({
                      ...prevState,
                      page: i+1
                    }))
                  }}>
                  {i+1}
                </div>
              )})}

              <img style={{width: 24, marginLeft: 5, cursor: 'pointer'}} src={pageState.page !== pageState.total_page && pageState.total_page !== 0? `./img/ic_arrow_right_enabled.png` : `./img/ic_arrow_right_disabled.png`} alt="user icon"
                onClick={() => {
                  if (pageState.page === pageState.total_page || pageState.total_page === 0) return;
                  setPageState(prevState => ({
                    ...prevState,
                    page: prevState.page+1
                  }))
                }}/>

            </div>

          </div> : null}


          {/* EASI paper */}
          { pageState.tab === "easi" ?
          <div style={{position: 'relative', width: '94%', height: '75%', top: -115, marginLeft: 40, overflow: 'auto'}}>

            <div style={{padding: '0 15px', height: 48, background: '#54595E19', boxShadow: '0px 10px 13px rgba(17, 38, 146, 0.05)', borderRadius: '8px', marginTop: 10, display: 'grid', 
                          gridTemplateColumns: '120px 120px 120px 1fr 110px', alignItems: 'center', fontSize: '14px', color: '#54595E', fontWeight: 700, textAlign: 'center', gap: 10}}>
              <div>날짜</div>
              <div>등록일</div>
              <div style={{textAlign: 'left', marginLeft: 50}}>EASI</div>
              <div></div>
              <div>
                <button style={{width: 110, height: 36, border: 'none', background: '#3A57E8', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                  onClick={() => {
                  setEasiData({
                    entered_date: customYMD(Date.now()),
                    response_easi: 0
                  });
                  setModalState({
                    type: "easi",
                    mode: "new"
                  })
                }}>
                  신규 등록
                </button>
              </div>
            </div>

            {listData.sort((a, b) => ( b.entered_date < a.entered_date ) ? -1 : ( b.entered_date == a.entered_date ) ? 0 : 1).slice((pageState.page-1) * pageState.max_per_page, (pageState.page)*pageState.max_per_page).map((item, idx) => {
              return (
                <div style={{padding: '0 15px', height: 48, background: 'white', boxShadow: '0px 10px 13px rgba(17, 38, 146, 0.05)', borderRadius: '8px', marginTop: 10, display: 'grid', 
                gridTemplateColumns: '120px 120px 120px 1fr 110px 60px', alignItems: 'center', fontSize: '14px', color: '#54595E', fontWeight: 400, textAlign: 'center', gap: 10}}>
                  <div>{item.entered_date}</div>
                  <div>{item.created_at.slice(0,10) ?? ""}</div>
                  <div style={{textAlign: 'left', marginLeft: 50}}>{item.response_easi}</div>
                  <div></div>
                  <div>
                    <button style={{width: 110, height: 36, border: 'none', background: '#54595E', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                      onClick={() => {
                        LoadDetailEasiData(item);
                        setModalState({
                          type: "easi",
                          mode: "detail",
                          created_at: item.created_at ? item.created_at.slice(0,10) : "-",
                          updated_at: item.updated_at ? item.updated_at.slice(0,10) : "-",
                          posted_at: item.posted_at ? item.posted_at.slice(0,10) : "-",
                          metadata_id: item.metadata_id
                        });
                      }}>
                      상세 보기
                    </button>
                  </div>
                  <div>
                    <button style={{width: 60, height: 36, border: 'none', background: '#FF6969', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                      onClick={() => {
                        setDialogState({
                          isOpen: true,
                          title: "해당 EASI 데이터를 삭제하시겠습니까?", 
                          desc: "삭제를 원하실 경우 삭제 버튼을 눌러주세요.\n삭제 후 복구할 수 없습니다.",
                          accept_label: "삭제",
                          deny_label: "닫기",
                          accept_callback: () => {
                            deleteEasiData(item.metadata_id)
                          }
                        })
                      }}>
                      삭제
                    </button>
                  </div>
                </div>
            )})}

            <div style={{display: 'flex', gap: 3, justifyContent: 'center', alignItems: 'center', width: '100%', height: 40, position: 'absolute', bottom: 100}}>
              <img style={{width: 24, marginRight: 5, cursor: 'pointer'}} src={pageState.page !== 1 ? `./img/ic_arrow_left_enabled.png` : `./img/ic_arrow_left_disabled.png`} alt="user icon"
                onClick={() => {
                  if (pageState.page === 1) return;
                  setPageState(prevState => ({
                    ...prevState,
                    page: prevState.page-1
                  }))
                }}/>

              {Array.apply(null, new Array(pageState.total_page)).map((v, i) => {
              return (
                <div className={cn({'pageButton': true, 'pageButtonSelected': pageState.page === i+1})}
                  onClick={() => {
                    setPageState(prevState => ({
                      ...prevState,
                      page: i+1
                    }))
                  }}>
                  {i+1}
                </div>
              )})}

              <img style={{width: 24, marginLeft: 5, cursor: 'pointer'}} src={pageState.page !== pageState.total_page && pageState.total_page !== 0 ? `./img/ic_arrow_right_enabled.png` : `./img/ic_arrow_right_disabled.png`} alt="user icon"
                onClick={() => {
                  if (pageState.page === pageState.total_page || pageState.total_page === 0) return;
                  setPageState(prevState => ({
                    ...prevState,
                    page: prevState.page+1
                  }))
                }}/>

            </div>

          </div> : null}


          {/* ADR paper */}
          { pageState.tab === "adr" ?
          <div style={{position: 'relative', width: '94%', height: '75%', top: -115, marginLeft: 40, overflow: 'auto'}}>
            <div style={{padding: '0 15px', height: 48, background: '#54595E19', boxShadow: '0px 10px 13px rgba(17, 38, 146, 0.05)', borderRadius: '8px', marginTop: 10, display: 'grid', 
                          gridTemplateColumns: '120px 120px 1fr 110px', alignItems: 'center', fontSize: '14px', color: '#54595E', fontWeight: 700, textAlign: 'center', gap: 10}}>
              <div>날짜</div>
              <div>등록일</div>
              <div style={{textAlign: 'left', marginLeft: 50}}>부작용</div>
              <div>
                <button style={{width: 110, height: 36, border: 'none', background: '#3A57E8', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                  onClick={() => {
                    setAdrData({
                      entered_date: customYMD(Date.now()),
                      response_adr: ""
                    });
                    setModalState({
                      type: "adr",
                      mode: "new"
                    })
                  }}>
                  신규 등록
                </button>
              </div>
            </div>

            {listData.sort((a, b) => ( b.entered_date < a.entered_date ) ? -1 : ( b.entered_date == a.entered_date ) ? 0 : 1).slice((pageState.page-1) * pageState.max_per_page, (pageState.page)*pageState.max_per_page).map((item, idx) => {
              return (
                <div style={{padding: '0 15px', height: 48, background: 'white', boxShadow: '0px 10px 13px rgba(17, 38, 146, 0.05)', borderRadius: '8px', marginTop: 10, display: 'grid', 
                gridTemplateColumns: '120px 120px 1fr 110px 60px', alignItems: 'center', fontSize: '14px', color: '#54595E', fontWeight: 400, textAlign: 'center', gap: 10}}>
                  <div>{item.entered_date}</div>
                  <div>{item.created_at.slice(0,10)}</div>
                  <div style={{textAlign: 'left', marginLeft: 50}}>{item.response_adr}</div>
                  <div>
                    <button style={{width: 110, height: 36, border: 'none', background: '#54595E', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                      onClick={() => {
                        LoadDetailAdrData(item);
                        setModalState({
                          type: "adr",
                          mode: "detail",
                          created_at: item.created_at ? item.created_at.slice(0,10) : "-",
                          updated_at: item.updated_at ? item.updated_at.slice(0,10) : "-",
                          posted_at: item.posted_at ? item.posted_at.slice(0,10) : "-",
                          metadata_id: item.metadata_id
                        });
                      }}>
                      상세 보기
                    </button>
                  </div>
                  <div>
                    <button style={{width: 60, height: 36, border: 'none', background: '#FF6969', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                      onClick={() => {
                        setDialogState({
                          isOpen: true,
                          title: "해당 부작용 데이터를 삭제하시겠습니까?", 
                          desc: "삭제를 원하실 경우 삭제 버튼을 눌러주세요.\n삭제 후 복구할 수 없습니다.",
                          accept_label: "삭제",
                          deny_label: "닫기",
                          accept_callback: () => {
                            deleteAdrData(item.metadata_id)
                          }
                        })
                      }}>
                      삭제
                    </button>
                  </div>
                </div>
            )})}

            <div style={{display: 'flex', gap: 3, justifyContent: 'center', alignItems: 'center', width: '100%', height: 40, position: 'absolute', bottom: 100}}>
              <img style={{width: 24, marginRight: 5, cursor: 'pointer'}} src={pageState.page !== 1 ? `./img/ic_arrow_left_enabled.png` : `./img/ic_arrow_left_disabled.png`} alt="user icon"
                onClick={() => {
                  if (pageState.page === 1) return;
                  setPageState(prevState => ({
                    ...prevState,
                    page: prevState.page-1
                  }))
                }}/>

              {Array.apply(null, new Array(pageState.total_page)).map((v, i) => {
              return (
                <div className={cn({'pageButton': true, 'pageButtonSelected': pageState.page === i+1})}
                  onClick={() => {
                    setPageState(prevState => ({
                      ...prevState,
                      page: i+1
                    }))
                  }}>
                  {i+1}
                </div>
              )})}

              <img style={{width: 24, marginLeft: 5, cursor: 'pointer'}} src={pageState.page !== pageState.total_page && pageState.total_page !== 0 ? `./img/ic_arrow_right_enabled.png` : `./img/ic_arrow_right_disabled.png`} alt="user icon"
                onClick={() => {
                  if (pageState.page === pageState.total_page || pageState.total_page === 0) return;
                  setPageState(prevState => ({
                    ...prevState,
                    page: prevState.page+1
                  }))
                }}/>

            </div>

          </div> : null}


          {/* OAS paper */}
          { pageState.tab === "oas" ?
          <div style={{position: 'relative', width: '94%', height: '75%', top: -115, marginLeft: 40, overflow: 'auto'}}>
            <div style={{padding: '0 15px', height: 48, background: '#54595E19', boxShadow: '0px 10px 13px rgba(17, 38, 146, 0.05)', borderRadius: '8px', marginTop: 10, display: 'grid', 
                          gridTemplateColumns: '120px 120px 100px 1fr 110px', alignItems: 'center', fontSize: '14px', color: '#54595E', fontWeight: 700, textAlign: 'center', gap: 10}}>
              <div>날짜</div>
              <div>등록일</div>
              <div>증후군 여부</div>
              <div style={{textAlign: 'left', marginLeft: 40}}>유발 식품</div>
              <div>
                <button style={{width: 110, height: 36, border: 'none', background: '#3A57E8', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                onClick={() => {
                  setOasData({
                    entered_date: customYMD(Date.now()),
                  });
                  setModalState({
                    type: "oas",
                    mode: "new"
                  })
                }}>
                  신규 등록
                </button>
              </div>
            </div>

            {listData.sort((a, b) => ( b.entered_date < a.entered_date ) ? -1 : ( b.entered_date == a.entered_date ) ? 0 : 1).slice((pageState.page-1) * pageState.max_per_page, (pageState.page)*pageState.max_per_page).map((item, idx) => {
              return (
                <div style={{padding: '0 15px', height: 48, background: 'white', boxShadow: '0px 10px 13px rgba(17, 38, 146, 0.05)', borderRadius: '8px', marginTop: 10, display: 'grid', 
                gridTemplateColumns: '120px 120px 100px 1fr 110px 60px', alignItems: 'center', fontSize: '14px', color: '#54595E', fontWeight: 400, textAlign: 'center', gap: 10}}>
                  <div>{item.entered_date}</div>
                  <div>{item.created_at.slice(0,10)}</div>
                  <div>{item.existence_of_oas ? "O" : "X"}</div>
                  <div style={{textAlign: 'left', marginLeft: 40}}>
                    {getOasString(item)}
                  </div>
                  <div>
                    <button style={{width: 110, height: 36, border: 'none', background: '#54595E', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                      onClick={() => {
                        LoadDetailOasData(item);
                        setModalState({
                          type: "oas",
                          mode: "detail",
                          created_at: item.created_at ? item.created_at.slice(0,10) : "-",
                          updated_at: item.updated_at ? item.updated_at.slice(0,10) : "-",
                          posted_at: item.posted_at ? item.posted_at.slice(0,10) : "-",
                          metadata_id: item.metadata_id
                        });
                      }}>
                      상세 보기
                    </button>
                  </div>
                  <div>
                    <button style={{width: 60, height: 36, border: 'none', background: '#FF6969', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                      onClick={() => {
                        setDialogState({
                          isOpen: true,
                          title: "해당 구강알레르기 증후군 데이터를 삭제하시겠습니까?", 
                          desc: "삭제를 원하실 경우 삭제 버튼을 눌러주세요.\n삭제 후 복구할 수 없습니다.",
                          accept_label: "삭제",
                          deny_label: "닫기",
                          accept_callback: () => {
                            deleteOasData(item.metadata_id)
                          }
                        })
                      }}>
                      삭제
                    </button>
                  </div>
                </div>
            )})}

            <div style={{display: 'flex', gap: 3, justifyContent: 'center', alignItems: 'center', width: '100%', height: 40, position: 'absolute', bottom: 100}}>
              <img style={{width: 24, marginRight: 5, cursor: 'pointer'}} src={pageState.page !== 1 ? `./img/ic_arrow_left_enabled.png` : `./img/ic_arrow_left_disabled.png`} alt="user icon"
                onClick={() => {
                  if (pageState.page === 1) return;
                  setPageState(prevState => ({
                    ...prevState,
                    page: prevState.page-1
                  }))
                }}/>

              {Array.apply(null, new Array(pageState.total_page)).map((v, i) => {
              return (
                <div className={cn({'pageButton': true, 'pageButtonSelected': pageState.page === i+1})}
                  onClick={() => {
                    setPageState(prevState => ({
                      ...prevState,
                      page: i+1
                    }))
                  }}>
                  {i+1}
                </div>
              )})}

              <img style={{width: 24, marginLeft: 5, cursor: 'pointer'}} src={pageState.page !== pageState.total_page && pageState.total_page !== 0 ? `./img/ic_arrow_right_enabled.png` : `./img/ic_arrow_right_disabled.png`} alt="user icon"
                onClick={() => {
                  if (pageState.page === pageState.total_page || pageState.total_page === 0) return;
                  setPageState(prevState => ({
                    ...prevState,
                    page: prevState.page+1
                  }))
                }}/>

            </div>

          </div> : null}

        </div>
      </div>

      

      <div style={{display: 'flex', position: 'sticky', width: '100%', height: 40, bottom: 0, background: 'white', alignItems: 'center', borderTop: '1px solid #E9ECEF'}}>
        <div style={{fontSize: '12px', marginLeft: '15px'}}>E-CRF 계정 및 오류 문의 | mdpp_support@evidnet.co.kr</div>
        <div style={{fontSize: '12px', marginLeft: 'auto', marginRight: '15px', userSelect: 'none'}}>© 2023. evidnet Inc.</div>
      </div>

      {modalState.type === "lab" ?
        <div className={cn('modalBase')} style={{minHeight: 720}}>
          <div className={cn('modalPaper')} style={{height: 690, padding: 20}}>
            <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', gap: 10}}>
              
              {modalState.mode === "detail" ?
              <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', gap: 10}}>
                <div>Lab(알레르기 검사 결과)</div>
                <button style={{width: 110, height: 36, border: 'none', background: '#3A57E8', borderRadius: '4px', cursor: 'pointer', color: 'white', marginLeft: 20}}
                  onClick={() => setDialogState({
                      isOpen: true,
                      title: "Lab 결과를 게시하시겠습니까?",
                      desc: "환자가 메디팡팡 앱을 통해 Lab 결과를 조회할 수 있게 됩니다.\n환자의 Lab 결과 조회 화면을 확인하기 위해서는 게시 미리보기 버튼을 눌러주세요.",
                      accept_label: "게시",
                      deny_label: "취소",
                      accept_callback: () => {
                        changeLabPosted(modalState.metadata_id, true)
                      }
                    })}>
                  게시
                </button>
                <button style={{width: 110, height: 36, border: 'none', background: '#54595E', borderRadius: '4px', cursor: 'pointer', color: 'white'}} 
                onClick={() => {
                  setModalState({
                    ...modalState,
                    type: 'lab_view'
                  })
                }}>
                  게시 미리보기
                </button>
              </div>: null}

              {modalState.mode === "new" || modalState.mode === "edit" ?
              <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', gap: 10}}>
                <div>Lab(알레르기 검사 결과) {modalState.mode === "new" ? "신규 등록" : "수정"} </div>
              </div>: null}
 
              <img style={{width: 24, height: 24, marginLeft: 'auto', alignSelf: 'start', cursor: 'pointer'}} src={`./img/btn_close.png`} alt="dashboard background"
                onClick={() => {
                  if (modalState.isChanged === true) {
                    setDialogState({
                      isOpen: true,
                      title: "저장되지 않은 수정 사항이 있습니다. 페이지를 닫으시겠습니까?",
                      desc: "저장되지 않은 수정 사항은 페이지 종료 시 반영되지 않습니다.",
                      accept_label: "닫기",
                      deny_label: "취소",
                      accept_callback: () => {
                        setModalState({
                          type: "",
                          mode: ""
                        })
                        setDialogState({
                          isOpen: false
                        })
                      }
                    })
                  }
                  else {
                    setModalState({
                      type: "",
                      mode: ""
                    })
                  }
                }}/>
            </div>

            <div style={{display: 'flex', height: 20, borderRadius: 8, background: 'white', marginTop: 10, padding: 20, alignItems: 'center'}}>
              <div style={{fontSize: '20px', fontWeight: 700}}>{markName(participantInfo.participant_name)}</div>
              <div style={{fontSize: '15px', fontWeight: 400, marginTop: 5, marginLeft: 10}}> | 연구 등록 번호 {participantInfo.participant_id}</div>
              
              {modalState.mode === "detail" || modalState.mode === "edit" ?
              <div style={{fontSize: '13px', fontWeight: 400, marginTop: 5, marginLeft: 'auto', textAlign: 'left'}}>
                최초등록 {modalState.created_at}<br/>
                업데이트 {modalState.updated_at}<br/>
                게시일 {modalState.posted_at}<br/>
              </div> : null}
            </div>

            <div style={{display: 'flex', marginTop: 10, height: 24, alignItems: 'center', gap: 10, marginLeft: 10}}>
              <img style={{width: 24, height: 24, cursor: 'pointer'}} src={`./img/ic_description.png`} alt="description icon"/>
              <div style={{fontSize: '14px', fontWeight: 700}}>입력 시 참고하세요~</div>
            </div>

            <div style={{ marginTop: 5, marginLeft: 10, fontSize: '12px', fontWeight: 400, color: '#8A92A6', lineHeight: '15px'}}>
              {"- 수치 및 Class 직접 입력"}<br/>
              {"- 수치( IU/ML) : 00.00 (Max: 1000  /  Min: 0)"}<br/>
              {/* {"- MAST, IMMUNOCAP 구분 없이 입력"}<br/> */}
            </div>

            <div style={{width: '100%', height: 440, marginTop: 10, fontSize: '12px', textAlign: 'center', display: 'flex', overflowY: 'scroll', borderRadius: '4px', border: '1px solid #A2A2A2'}}>
              
              <div style={{width: '50%', borderTopLeftRadius: '4px'}}>
                <div className={cn('tableRow')} style={{background: 'rgba(0, 0, 0, 0.1)'}}>
                  <div className={cn('tableCell')} style={{fontWeight: 700, textAlign: 'center'}}>날짜</div>
                  <div className={cn('tableCell')} style={{fontWeight: 700, textAlign: 'center'}}>
                    <input 
                      type='text'
                      maxLength={10}
                      className={cn('cellTextInput')}
                      style={{textAlign: 'center'}}
                      defaultValue={labDate}
                      disabled={modalState.mode !== "edit" && modalState.mode !== "new"}
                      onKeyUp={(e) => {
                        const value = e.currentTarget.value;
                        const s = e.currentTarget.selectionStart;

                        if (s === null) return;
                        if (isNaN(Number(e.key))) return;

                        if (value.length === 5 && !value.endsWith('-')) {
                          console.log('here4')
                          e.currentTarget.value = `${value.slice(0, 4)}-${value.slice(-1)}`
                        }
                        else if (value.length === 8 && !value.endsWith('-')) {
                          console.log('here5')
                          e.currentTarget.value = `${value.slice(0, 4)}-${value.slice(5, 7)}-${value.slice(-1)}`
                        }
                        else if (value.length === 6 && !value.includes('-')) {
                          console.log('here6')
                          e.currentTarget.value = `${value.slice(0, 4)}-${value.slice(-2)}`
                        }
                        else if ((value.length === 9 || value.length === 10) && value.charAt(7) !== '-' && s !== 5) {
                          console.log('here7 position: '+s)
                          e.currentTarget.value = `${value.slice(0, 4)}-${value.slice(5, 7)}-${value.slice(7, 9)}`
                          e.currentTarget.selectionEnd = s+1;
                        }
                        setLabDate(e.currentTarget.value);

                      }}
                      onKeyDown={(e) => {
                        if (e.currentTarget.value.length === 10 && !isNaN(Number(e.key))) {
                          const s = e.currentTarget.selectionStart;
                          const value = e.currentTarget.value;
                          console.log(`selection: ${s} / key: ${e.key}`)

                          if (s === null) return;

                          if (s === 4 || s === 7) {
                            if (e.currentTarget.value !== `${value.slice(0, s)}-${e.key}${value.slice(s+2)}`) {
                              e.currentTarget.value = `${value.slice(0, s)}-${e.key}${value.slice(s+2)}`
                            }
                            else {
                              e.preventDefault();
                              e.currentTarget.value = `${value.slice(0, s)}-${e.key}${value.slice(s+2)}`
                              e.currentTarget.selectionStart = s+2;
                            }
                            e.currentTarget.selectionEnd = s+2;
                          }
                          else if (s !== 10) {
                            console.log('here2')
                            e.currentTarget.value = `${value.slice(0, s)}${e.key}${value.slice(s+1)}`
                            e.currentTarget.selectionEnd = s+1;
                          }
                        }
                      }}
                      onChange={(e) => {
                        setLabDate(e.target.value);
                        setModalState({
                          ...modalState,
                          isChanged: true
                        })
                      }}>
                    </input>
                  </div>
                  <div className={cn('tableCell')} style={{fontWeight: 700, textAlign: 'center'}}></div>
                </div>
                <div className={cn('tableRow')} style={{background: 'rgba(0, 0, 0, 0.05)'}}>
                  <div className={cn('tableCell')} style={{fontWeight: 700, textAlign: 'center'}}>항목</div>
                  <div className={cn('tableCell')} style={{fontWeight: 700, textAlign: 'center'}}>수치</div>
                  <div className={cn('tableCell')} style={{fontWeight: 700, textAlign: 'center'}}>Class</div>
                </div>


                {Object.keys(LAB_ITEMS_COLUMN_1).sort().map((item, idx) => {
                  return (
                    <div className={cn('tableRow')}>
                      <div className={cn('tableCell')}>{LAB_ITEMS_COLUMN_1[item]}</div>
                      <div className={cn('tableCell')}>
                        <input 
                          type='number'
                          maxLength={5}
                          style={{ width: 100, height: 20, outline: 'none', fontSize: '12px', fontWeight: '600', lineHeight: '12px', letterSpacing:'-0.5px', boxSizing: 'border-box', border: 'none'}}
                          onChange={(e) => {
                            setLabNumData(prevState => ({
                              ...prevState,
                              [item]:  e.target.value
                            }))
                            setModalState({
                              ...modalState,
                              isChanged: true
                            })
                          }}
                          step="0.01"
                          max={1000}
                          min={0}
                          defaultValue={getLabValue(item)}
                          disabled={modalState.mode !== "edit" && modalState.mode !== "new"}
                          >
                        </input>
                      </div>
                      <div className={cn('tableCell')}>
                        <input 
                          type='text'
                          maxLength={10}
                          className={cn('cellTextInput')}
                          onChange={(e) => {
                            setLabClassData(prevState => ({
                              ...prevState,
                              [item]:  e.target.value
                            }))
                            setModalState({
                              ...modalState,
                              isChanged: true
                            })
                          }}
                          defaultValue={getLabClass(item)}
                          disabled={modalState.mode !== "edit" && modalState.mode !== "new"}
                          >
                        </input>
                      </div>
                    </div>
                  )
                })}
              </div>

              <div style={{width: '50%', borderTopLeftRadius: '4px'}}>
                <div className={cn('tableRow')} style={{background: 'rgba(0, 0, 0, 0.1)'}}>
                  <div className={cn('tableCell')} style={{fontWeight: 700, textAlign: 'center'}}>검사 방법</div>
                  <div className={cn('tableCell')} style={{fontWeight: 700, textAlign: 'center'}}>
                    <select className={cn('cellSelect')} name="labType" id="labTypeSelect" value={labType} disabled={modalState.mode !== "edit" && modalState.mode !== "new"}
                      onChange={(e) => {
                        console.log("here change labtype : "+e.target.value)
                        setLabType(e.target.value)
                      }}>
                      <option value="" disabled selected>선택</option>
                      <option value="MAST">MAST</option>
                      <option value="IMMUNOCAP">IMMUNOCAP</option>
                    </select>
                  </div>
                  <div className={cn('tableCell')} style={{fontWeight: 700, textAlign: 'center'}}></div>
                </div>
                <div className={cn('tableRow')} style={{background: 'rgba(0, 0, 0, 0.05)'}}>
                  <div className={cn('tableCell')} style={{fontWeight: 700, textAlign: 'center'}}>항목</div>
                  <div className={cn('tableCell')} style={{fontWeight: 700, textAlign: 'center'}}>수치</div>
                  <div className={cn('tableCell')} style={{fontWeight: 700, textAlign: 'center'}}>Class</div>
                </div>


                {Object.keys(LAB_ITEMS_COLUMN_2).sort().map((item, idx) => {
                  return (
                    <div className={cn('tableRow')}>
                      <div className={cn('tableCell')}>{LAB_ITEMS_COLUMN_2[item]}</div>
                      <div className={cn('tableCell')}>
                        <input 
                          type='number'
                          maxLength={5}
                          style={{ width: 100, height: 20, outline: 'none', fontSize: '12px', fontWeight: '600', lineHeight: '12px', letterSpacing:'-0.5px', boxSizing: 'border-box', border: 'none'}}
                          onChange={(e) => {
                            setLabNumData(prevState => ({
                              ...prevState,
                              [item]:  e.target.value
                            }))
                            setModalState({
                              ...modalState,
                              isChanged: true
                            })
                          }}
                          step="0.01"
                          max={1000}
                          min={0}
                          defaultValue={getLabValue(item)}
                          disabled={modalState.mode !== "edit" && modalState.mode !== "new"}
                          >
                        </input>
                      </div>
                      <div className={cn('tableCell')}>
                        <input 
                          type='text'
                          maxLength={10}
                          className={cn('cellTextInput')}
                          onChange={(e) => {
                            setLabClassData(prevState => ({
                              ...prevState,
                              [item]:  e.target.value
                            }))
                            setModalState({
                              ...modalState,
                              isChanged: true
                            })
                          }}
                          defaultValue={getLabClass(item)}
                          disabled={modalState.mode !== "edit" && modalState.mode !== "new"}
                          >
                        </input>
                      </div>
                    </div>
                  )
                })}
              </div>

            </div>

            <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', justifyContent: 'center', gap: 10, marginTop: 15}}>
              
              {modalState.mode === "new"?
              <button style={{width: 160, height: 36, border: 'none', background: '#3A57E8', borderRadius: '4px', cursor: 'pointer', color: 'white', marginLeft: 20}}
                onClick={() => {
                  InsertLabData(0);
                }}>
                저장
              </button> : null}

              {modalState.mode === "edit"?
              <button style={{width: 160, height: 36, border: 'none', background: '#3A57E8', borderRadius: '4px', cursor: 'pointer', color: 'white', marginLeft: 20}}
                onClick={() => {
                  InsertLabData(modalState.metadata_id);
                }}>
                저장
              </button> : null}

              {modalState.mode === "detail" ?
              <button style={{width: 160, height: 36, border: 'none', background: '#3A57E8', borderRadius: '4px', cursor: 'pointer', color: 'white', marginLeft: 20}}
                onClick={() => {
                  setModalState({
                    ...modalState,
                    mode: 'edit'
                  })
                }}>
                수정
              </button> : null}

              {modalState.mode === "detail" || modalState.mode === "new" ?
              <button style={{width: 160, height: 36, border: 'none', background: '#54595E', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                onClick={() => {
                  setModalState({
                    type: "",
                    mode: ""
                  })
                }}>
                닫기
              </button> :  null}

              {modalState.mode === "edit" ?
              <button style={{width: 160, height: 36, border: 'none', background: '#54595E', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                onClick={() => {
                  if (modalState.isChanged === true) {
                    setDialogState({
                      isOpen: true,
                      title: "저장되지 않은 수정 사항이 있습니다. 페이지를 닫으시겠습니까?",
                      desc: "저장되지 않은 수정 사항은 페이지 종료 시 반영되지 않습니다.",
                      accept_label: "닫기",
                      deny_label: "취소",
                      accept_callback: () => {
                        setModalState({
                          ...modalState,
                          type: "",
                          mode: ""
                        })
                        ReloadDetailLabData();
                        setTimeout(() => {
                          setModalState({
                            ...modalState,
                            mode: "detail",
                            isChanged: false,
                          })
                          setDialogState({
                            isOpen: false
                          })

                        }, 100)
                        
                      }
                    })
                  }
                  else {
                    setModalState({
                      ...modalState,
                      mode: "detail"
                    })
                  } 
                }}>
                뒤로
              </button> :  null}
            </div>

          </div>
        </div>
        : null}

      {modalState.type === "lab_view" ?
        <div className={cn('modalBase')} style={{minHeight: 720}}>
          <div className={cn('modalPaper')} style={{height: 690, padding: 20}}>
            <div style={{}}>
              <img style={{height: 180, width: '100%', objectFit: 'cover', borderRadius: '7px 7px 16px 16px'}} src={`./img/modal_view_bg.svg`} alt="dashboard background"/>
              <div style={{position: 'relative', fontSize: '28px', fontWeight: '700', marginLeft: 30, top: -160, color: 'white'}}>
                {pageState.hospital}
              </div> 
            </div>

            <div style={{display: 'flex', width: 'calc(100% - 100px)', marginTop: -130, alignItems: 'center', alignSelf: 'center', height: 60, padding: '0 20px', background: 'white', borderRadius: '8px', gap: 10}}>
              <div style={{fontSize: '24px', fontWeight: '700'}}>{markName(participantInfo.participant_name)}</div>
              <div style={{fontSize: '16px', fontWeight: '400', color: '#232D42', marginTop: 5}}>| 연구 등록 번호 {participantInfo.participant_id}</div>
            </div>


            <div style={{position: 'relative', display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', gap: 10, top: -130, left: -15}}>
              <img style={{width: 24, height: 24, marginLeft: 'auto', alignSelf: 'start', cursor: 'pointer'}} src={`./img/btn_close.png`} alt="dashboard background"
                onClick={() => {
                  setModalState({
                    ...modalState,
                    type: "lab",
                    mode: "detail"
                  })
                }}/>
            </div>


            <div style={{width: '100%', height: 440, marginTop: 10, fontSize: '12px', textAlign: 'center', display: 'flex', overflowY: 'scroll', borderRadius: '4px', border: '1px solid #A2A2A2', alignSelf: 'center'}}>
              
              <div style={{width: '50%', borderTopLeftRadius: '4px'}}>
                <div className={cn('tableRow')} style={{background: 'rgba(0, 0, 0, 0.1)'}}>
                  <div className={cn('tableCell')} style={{fontWeight: 700, textAlign: 'center'}}>날짜</div>
                  <div className={cn('tableCell')} style={{fontWeight: 700, textAlign: 'center'}}>
                    <input 
                      type='text'
                      maxLength={10}
                      className={cn('cellTextInput')}
                      style={{textAlign: 'center'}}
                      defaultValue={labDate}
                      disabled={modalState.mode !== "edit" && modalState.mode !== "new"}
                      onKeyUp={(e) => {
                        const value = e.currentTarget.value;
                        const s = e.currentTarget.selectionStart;

                        if (s === null) return;
                        if (isNaN(Number(e.key))) return;

                        if (value.length === 5 && !value.endsWith('-')) {
                          console.log('here4')
                          e.currentTarget.value = `${value.slice(0, 4)}-${value.slice(-1)}`
                        }
                        else if (value.length === 8 && !value.endsWith('-')) {
                          console.log('here5')
                          e.currentTarget.value = `${value.slice(0, 4)}-${value.slice(5, 7)}-${value.slice(-1)}`
                        }
                        else if (value.length === 6 && !value.includes('-')) {
                          console.log('here6')
                          e.currentTarget.value = `${value.slice(0, 4)}-${value.slice(-2)}`
                        }
                        else if ((value.length === 9 || value.length === 10) && value.charAt(7) !== '-' && s !== 5) {
                          console.log('here7 position: '+s)
                          e.currentTarget.value = `${value.slice(0, 4)}-${value.slice(5, 7)}-${value.slice(7, 9)}`
                          e.currentTarget.selectionEnd = s+1;
                        }
                        
                      }}
                      onKeyDown={(e) => {
                        if (e.currentTarget.value.length === 10 && !isNaN(Number(e.key))) {
                          const s = e.currentTarget.selectionStart;
                          const value = e.currentTarget.value;
                          console.log(`selection: ${s} / key: ${e.key}`)

                          if (s === null) return;

                          if (s === 4 || s === 7) {
                            if (e.currentTarget.value !== `${value.slice(0, s)}-${e.key}${value.slice(s+2)}`) {
                              e.currentTarget.value = `${value.slice(0, s)}-${e.key}${value.slice(s+2)}`
                            }
                            else {
                              e.preventDefault();
                              e.currentTarget.value = `${value.slice(0, s)}-${e.key}${value.slice(s+2)}`
                              e.currentTarget.selectionStart = s+2;
                            }
                            e.currentTarget.selectionEnd = s+2;
                          }
                          else if (s !== 10) {
                            console.log('here2')
                            e.currentTarget.value = `${value.slice(0, s)}${e.key}${value.slice(s+1)}`
                            e.currentTarget.selectionEnd = s+1;
                          }
                        }
                      }}
                      onChange={(e) => {
                        setLabDate(e.target.value);
                      }}>
                        
                    </input>
                  </div>
                  <div className={cn('tableCell')} style={{fontWeight: 700, textAlign: 'center'}}></div>
                </div>
                <div className={cn('tableRow')} style={{background: 'rgba(0, 0, 0, 0.05)'}}>
                  <div className={cn('tableCell')} style={{fontWeight: 700, textAlign: 'center'}}>항목</div>
                  <div className={cn('tableCell')} style={{fontWeight: 700, textAlign: 'center'}}>수치</div>
                  <div className={cn('tableCell')} style={{fontWeight: 700, textAlign: 'center'}}>Class</div>
                </div>


                {Object.keys(LAB_ITEMS_COLUMN_1).sort().map((item, idx) => {
                  return (
                    <div className={cn('tableRow')}>
                      <div className={cn('tableCell')}>{LAB_ITEMS_COLUMN_1[item]}</div>
                      <div className={cn('tableCell')}>
                        <input 
                          type='number'
                          maxLength={5}
                          style={{ width: 100, height: 20, outline: 'none', fontSize: '12px', fontWeight: '600', lineHeight: '12px', letterSpacing:'-0.5px', boxSizing: 'border-box', border: 'none'}}
                          onChange={(e) => {
                            setLabNumData(prevState => ({
                              ...prevState,
                              [item]:  e.target.value
                            }))
                          }}
                          step="0.01"
                          max={100}
                          min={0}
                          defaultValue={getLabValue(item)}
                          disabled={modalState.mode !== "edit" && modalState.mode !== "new"}
                          >
                        </input>
                      </div>
                      <div className={cn('tableCell')}>
                        <input 
                          type='text'
                          maxLength={10}
                          className={cn('cellTextInput')}
                          onChange={(e) => {
                            setLabClassData(prevState => ({
                              ...prevState,
                              [item]:  e.target.value
                            }))
                          }}
                          defaultValue={getLabClass(item)}
                          disabled={modalState.mode !== "edit" && modalState.mode !== "new"}
                          >
                        </input>
                      </div>
                    </div>
                  )
                })}
              </div>

              <div style={{width: '50%', borderTopLeftRadius: '4px'}}>
                <div className={cn('tableRow')} style={{background: 'rgba(0, 0, 0, 0.1)'}}>
                  <div className={cn('tableCell')} style={{fontWeight: 700, textAlign: 'center'}}>검사 방법</div>
                  <div className={cn('tableCell')} style={{fontWeight: 700, textAlign: 'center'}}>
                    <select className={cn('cellSelect')} name="labType" id="labTypeSelect" value={labType} disabled={modalState.mode !== "edit" && modalState.mode !== "new"}
                      onChange={(e) => {
                        console.log("here change labtype : "+e.target.value)
                        setLabType(e.target.value)
                      }}>
                      <option value="" disabled selected>선택</option>
                      <option value="MAST">MAST</option>
                      <option value="IMMUNOCAP">IMMUNOCAP</option>
                    </select>
                  </div>
                  <div className={cn('tableCell')} style={{fontWeight: 700, textAlign: 'center'}}></div>
                </div>
                <div className={cn('tableRow')} style={{background: 'rgba(0, 0, 0, 0.05)'}}>
                  <div className={cn('tableCell')} style={{fontWeight: 700, textAlign: 'center'}}>항목</div>
                  <div className={cn('tableCell')} style={{fontWeight: 700, textAlign: 'center'}}>수치</div>
                  <div className={cn('tableCell')} style={{fontWeight: 700, textAlign: 'center'}}>Class</div>
                </div>


                {Object.keys(LAB_ITEMS_COLUMN_2).sort().map((item, idx) => {
                  return (
                    <div className={cn('tableRow')}>
                      <div className={cn('tableCell')}>{LAB_ITEMS_COLUMN_2[item]}</div>
                      <div className={cn('tableCell')}>
                        <input 
                          type='number'
                          maxLength={5}
                          style={{ width: 100, height: 20, outline: 'none', fontSize: '12px', fontWeight: '600', lineHeight: '12px', letterSpacing:'-0.5px', boxSizing: 'border-box', border: 'none'}}
                          onChange={(e) => {
                            setLabNumData(prevState => ({
                              ...prevState,
                              [item]:  e.target.value
                            }))
                          }}
                          step="0.01"
                          max="100"
                          min="0"
                          defaultValue={getLabValue(item)}
                          disabled={modalState.mode !== "edit" && modalState.mode !== "new"}
                          >
                        </input>
                      </div>
                      <div className={cn('tableCell')}>
                        <input 
                          type='text'
                          maxLength={10}
                          className={cn('cellTextInput')}
                          onChange={(e) => {
                            setLabClassData(prevState => ({
                              ...prevState,
                              [item]:  e.target.value
                            }))
                          }}
                          defaultValue={getLabClass(item)}
                          disabled={modalState.mode !== "edit" && modalState.mode !== "new"}
                          >
                        </input>
                      </div>
                    </div>
                  )
                })}
              </div>

            </div>

            <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', justifyContent: 'center', gap: 10, marginTop: 15}}>
              <button style={{width: 160, height: 36, border: 'none', background: '#3A57E8', borderRadius: '4px', cursor: 'pointer', color: 'white', marginLeft: 20}}
                onClick={() => setDialogState({
                  isOpen: true,
                  title: "Lab 결과를 게시하시겠습니까?",
                  desc: "환자가 메디팡팡 앱을 통해 Lab 결과를 조회할 수 있게 됩니다.\n환자의 Lab 결과 조회 화면을 확인하기 위해서는 게시 미리보기 버튼을 눌러주세요.",
                  accept_label: "게시",
                  deny_label: "취소",
                  accept_callback: () => {
                    changeLabPosted(modalState.metadata_id, true)
                  }
                })}>
                게시
              </button>
              <button style={{width: 160, height: 36, border: 'none', background: '#54595E', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                onClick={() => {
                  setModalState({
                    type: "",
                    mode: ""
                  })
                }}>
                닫기
              </button>
            </div>

          </div>
        </div>
        : null}

      {modalState.type === "scorad" ?
        <div className={cn('modalBase')} style={{minHeight: 720}}>
          <div className={cn('modalPaper')} style={{height: 420, padding: 20}}>
            <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', gap: 10}}>
              
              {modalState.mode === "detail" ?
              <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', gap: 10}}>
                <div>SCORAD</div>
              </div>: null}

              {modalState.mode === "new" || modalState.mode === "edit" ?
              <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', gap: 10}}>
                <div>SCORAD {modalState.mode === "new" ? "신규 등록" : "수정"} </div>
              </div>: null}
 
              <img style={{width: 24, height: 24, marginLeft: 'auto', alignSelf: 'start', cursor: 'pointer'}} src={`./img/btn_close.png`} alt="dashboard background"
                onClick={() => {
                  if (modalState.isChanged === true) {
                    setDialogState({
                      isOpen: true,
                      title: "저장되지 않은 수정 사항이 있습니다. 페이지를 닫으시겠습니까?",
                      desc: "저장되지 않은 수정 사항은 페이지 종료 시 반영되지 않습니다.",
                      accept_label: "닫기",
                      deny_label: "취소",
                      accept_callback: () => {
                        setModalState({
                          type: "",
                          mode: ""
                        })
                        setDialogState({
                          isOpen: false
                        })
                      }
                    })
                  }
                  else {
                    setModalState({
                      type: "",
                      mode: ""
                    })
                  }
                }}/>
            </div>

            <div style={{display: 'flex', height: 20, borderRadius: 8, background: 'white', marginTop: 10, padding: 20, alignItems: 'center'}}>
              <div style={{fontSize: '20px', fontWeight: 700}}>{markName(participantInfo.participant_name)}</div>
              <div style={{fontSize: '15px', fontWeight: 400, marginTop: 5, marginLeft: 10}}> | 연구 등록 번호 {participantInfo.participant_id}</div>
              
              {modalState.mode === "detail" || modalState.mode === "edit" ?
              <div style={{width: 130, textAlign: 'left', fontSize: '13px', fontWeight: 400, marginTop: 5, marginLeft: 'auto'}}>
                최초등록 {modalState.created_at}<br/>
                업데이트 {modalState.updated_at}<br/>
              </div> : null}
            </div>

            <div style={{display: 'flex', marginTop: 10, height: 24, alignItems: 'center', gap: 10, marginLeft: 10}}>
              <img style={{width: 24, height: 24, cursor: 'pointer'}} src={`./img/ic_description.png`} alt="description icon"/>
              <div style={{fontSize: '14px', fontWeight: 700}}>입력 시 참고하세요~</div>
            </div>

            <div style={{ marginTop: 5, marginLeft: 10, fontSize: '12px', fontWeight: 400, color: '#8A92A6', lineHeight: '15px'}}>
              {"- 연속 변수 직접 입력"}<br/>
              {"- Max: 103  /  Min: 0"}<br/>
              {"- 숫자만 입력 가능"}<br/>

            </div>

            <div style={{width: '100%', height: 240, marginTop: 10, fontSize: '12px', textAlign: 'center', borderRadius: '4px', border: '1px solid #A2A2A2', background: 'white'}}>
              
                <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 4fr', marginTop: 20, height: 30, fontWeight: 700, textAlign: 'center', marginRight: 30}}>
                  <div>날짜</div>
                  <div>
                    <input 
                      type='text'
                      className={cn('cellTextInput')}
                      style={{textAlign: 'center', height: 25, background: '#F3F3F3', borderRadius: 8, marginTop: -5}}
                      defaultValue={scoradData.entered_date}
                      disabled={modalState.mode !== "edit" && modalState.mode !== "new"}
                      maxLength={10}
                      onKeyUp={(e) => {
                        const value = e.currentTarget.value;
                        const s = e.currentTarget.selectionStart;

                        if (s === null) return;
                        if (isNaN(Number(e.key))) return;

                        if (value.length === 5 && !value.endsWith('-')) {
                          console.log('here4')
                          e.currentTarget.value = `${value.slice(0, 4)}-${value.slice(-1)}`
                        }
                        else if (value.length === 8 && !value.endsWith('-')) {
                          console.log('here5')
                          e.currentTarget.value = `${value.slice(0, 4)}-${value.slice(5, 7)}-${value.slice(-1)}`
                        }
                        else if (value.length === 6 && !value.includes('-')) {
                          console.log('here6')
                          e.currentTarget.value = `${value.slice(0, 4)}-${value.slice(-2)}`
                        }
                        else if ((value.length === 9 || value.length === 10) && value.charAt(7) !== '-' && s !== 5) {
                          console.log('here7 position: '+s)
                          e.currentTarget.value = `${value.slice(0, 4)}-${value.slice(5, 7)}-${value.slice(7, 9)}`
                          e.currentTarget.selectionEnd = s+1;
                        }
                        setScoradData({
                          ...scoradData,
                          entered_date: e.currentTarget.value
                        });

                      }}
                      onKeyDown={(e) => {
                        if (e.currentTarget.value.length === 10 && !isNaN(Number(e.key))) {
                          const s = e.currentTarget.selectionStart;
                          const value = e.currentTarget.value;
                          console.log(`selection: ${s} / key: ${e.key}`)

                          if (s === null) return;

                          if (s === 4 || s === 7) {
                            if (e.currentTarget.value !== `${value.slice(0, s)}-${e.key}${value.slice(s+2)}`) {
                              e.currentTarget.value = `${value.slice(0, s)}-${e.key}${value.slice(s+2)}`
                            }
                            else {
                              e.preventDefault();
                              e.currentTarget.value = `${value.slice(0, s)}-${e.key}${value.slice(s+2)}`
                              e.currentTarget.selectionStart = s+2;
                            }
                            e.currentTarget.selectionEnd = s+2;
                          }
                          else if (s !== 10) {
                            console.log('here2')
                            e.currentTarget.value = `${value.slice(0, s)}${e.key}${value.slice(s+1)}`
                            e.currentTarget.selectionEnd = s+1;
                          }
                        }
                      }}
                      onChange={(e) => {
                        setScoradData({
                          ...scoradData,
                          entered_date: e.target.value
                        });
                        setModalState({
                          ...modalState,
                          isChanged: true
                        })
                      }}>
                    </input>
                  </div>
                </div>

                <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr', marginTop: 20, height: 30, fontWeight: 700, textAlign: 'center', marginRight: 30}}>
                  <div>Extent</div>
                  <div>
                    <input 
                      type='number'
                      className={cn('cellTextInput')}
                      style={{textAlign: 'center', height: 25, background: '#F3F3F3', borderRadius: 8, marginTop: -5}}
                      defaultValue={scoradData.response_extent}
                      disabled={modalState.mode !== "edit" && modalState.mode !== "new"}
                      onChange={(e) => {
                        setScoradData({
                          ...scoradData,
                          response_extent: e.target.value
                        });
                        setModalState({
                          ...modalState,
                          isChanged: true
                        })
                      }}>
                    </input>
                  </div>

                  <div>Intensity</div>
                  <div>
                    <input 
                      type='number'
                      className={cn('cellTextInput')}
                      style={{textAlign: 'center', height: 25, background: '#F3F3F3', borderRadius: 8, marginTop: -5}}
                      defaultValue={scoradData.response_intensity}
                      disabled={modalState.mode !== "edit" && modalState.mode !== "new"}
                      onChange={(e) => {
                        setScoradData({
                          ...scoradData,
                          response_intensity: e.target.value
                        });
                        setModalState({
                          ...modalState,
                          isChanged: true
                        })
                      }}>
                    </input>
                  </div>

                  <div style={{marginTop: -5}}>Subjective symptoms</div>
                  <div>
                    <input 
                      type='number'
                      maxLength={10}
                      className={cn('cellTextInput')}
                      style={{textAlign: 'center', height: 25, background: '#F3F3F3', borderRadius: 8, marginTop: -5}}
                      defaultValue={scoradData.response_subj_symptoms}
                      disabled={modalState.mode !== "edit" && modalState.mode !== "new"}
                      onChange={(e) => {
                        setScoradData({
                          ...scoradData,
                          response_subj_symptoms: e.target.value
                        });
                        setModalState({
                          ...modalState,
                          isChanged: true
                        })
                      }}>
                    </input>
                  </div>
                </div>

                <div style={{display: 'grid', gridTemplateColumns: '4fr 1fr 1fr', marginTop: 25, height: 30, fontWeight: 700, textAlign: 'center', marginRight: 30}}>
                  <div/>
                  <div>Total score</div>
                  <div>
                    <input 
                      type='number'
                      maxLength={10}
                      className={cn('cellTextInput')}
                      style={{textAlign: 'center', height: 25, background: '#F3F3F3', borderRadius: 8, marginTop: -5}}
                      defaultValue={scoradData.total_score}
                      disabled={modalState.mode !== "edit" && modalState.mode !== "new"}
                      onChange={(e) => {
                        setScoradData({
                          ...scoradData,
                          total_score: e.target.value
                        });
                        setModalState({
                          ...modalState,
                          isChanged: true
                        })
                      }}>
                    </input>
                  </div>
                </div>

            
            </div>

            <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', justifyContent: 'center', gap: 10, marginTop: 15}}>
              
              {modalState.mode === "new"?
              <button style={{width: 160, height: 36, border: 'none', background: '#3A57E8', borderRadius: '4px', cursor: 'pointer', color: 'white', marginLeft: 20}}
                onClick={() => {
                  InsertScoradData(0);
                }}>
                저장
              </button> : null}

              {modalState.mode === "edit"?
              <button style={{width: 160, height: 36, border: 'none', background: '#3A57E8', borderRadius: '4px', cursor: 'pointer', color: 'white', marginLeft: 20}}
                onClick={() => {
                  InsertScoradData(modalState.metadata_id);
                }}>
                저장
              </button> : null}

              {modalState.mode === "detail" ?
              <button style={{width: 160, height: 36, border: 'none', background: '#3A57E8', borderRadius: '4px', cursor: 'pointer', color: 'white', marginLeft: 20}}
                onClick={() => {
                  setModalState({
                    ...modalState,
                    mode: 'edit'
                  })
                }}>
                수정
              </button> : null}

              {modalState.mode === "detail" || modalState.mode === "new" ?
              <button style={{width: 160, height: 36, border: 'none', background: '#54595E', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                onClick={() => {
                  setModalState({
                    type: "",
                    mode: ""
                  })
                }}>
                닫기
              </button> :  null}

              {modalState.mode === "edit"?
              <button style={{width: 160, height: 36, border: 'none', background: '#54595E', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                onClick={() => {
                if (modalState.isChanged === true) {
                  setDialogState({
                    isOpen: true,
                    title: "저장되지 않은 수정 사항이 있습니다. 페이지를 닫으시겠습니까?",
                    desc: "저장되지 않은 수정 사항은 페이지 종료 시 반영되지 않습니다.",
                    accept_label: "닫기",
                    deny_label: "취소",
                    accept_callback: () => {
                      setModalState({
                        ...modalState,
                        type: "",
                        mode: ""
                      })
                      ReloadDetailScoradData();
                      setTimeout(() => {
                        setModalState({
                          ...modalState,
                          mode: "detail",
                          isChanged: false,
                        })
                        setDialogState({
                          isOpen: false
                        })

                      }, 100)
                      
                    }
                  })
                }
                else {
                  setModalState({
                    ...modalState,
                    mode: "detail"
                  })
                } 
              }}>
                뒤로
              </button> :  null}
            </div>

          </div>
        </div>
        : null}

      {modalState.type === "easi" ?
        <div className={cn('modalBase')} style={{minHeight: 720}}>
          <div className={cn('modalPaper')} style={{height: 360, padding: 20}}>
            <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', gap: 10}}>
              
              {modalState.mode === "detail" ?
              <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', gap: 10}}>
                <div>EASI</div>
              </div>: null}

              {modalState.mode === "new" || modalState.mode === "edit" ?
              <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', gap: 10}}>
                <div>EASI {modalState.mode === "new" ? "신규 등록" : "수정"} </div>
              </div>: null}
 
              <img style={{width: 24, height: 24, marginLeft: 'auto', alignSelf: 'start', cursor: 'pointer'}} src={`./img/btn_close.png`} alt="dashboard background"
                onClick={() => {
                  if (modalState.isChanged === true) {
                    setDialogState({
                      isOpen: true,
                      title: "저장되지 않은 수정 사항이 있습니다. 페이지를 닫으시겠습니까?",
                      desc: "저장되지 않은 수정 사항은 페이지 종료 시 반영되지 않습니다.",
                      accept_label: "닫기",
                      deny_label: "취소",
                      accept_callback: () => {
                        setModalState({
                          type: "",
                          mode: ""
                        })
                        setDialogState({
                          isOpen: false
                        })
                      }
                    })
                  }
                  else {
                    setModalState({
                      type: "",
                      mode: ""
                    })
                  }
                }}/>
            </div>

            <div style={{display: 'flex', height: 20, borderRadius: 8, background: 'white', marginTop: 10, padding: 20, alignItems: 'center'}}>
              <div style={{fontSize: '20px', fontWeight: 700}}>{markName(participantInfo.participant_name)}</div>
              <div style={{fontSize: '15px', fontWeight: 400, marginTop: 5, marginLeft: 10}}> | 연구 등록 번호 {participantInfo.participant_id}</div>
              
              {modalState.mode === "detail" || modalState.mode === "edit" ?
              <div style={{width: 130, fontSize: '13px', fontWeight: 400, marginTop: 5, marginLeft: 'auto', textAlign: 'left'}}>
                최초등록 {modalState.created_at}<br/>
                업데이트 {modalState.updated_at}<br/>
              </div> : null}
            </div>

            <div style={{display: 'flex', marginTop: 10, height: 24, alignItems: 'center', gap: 10, marginLeft: 10}}>
              <img style={{width: 24, height: 24, cursor: 'pointer'}} src={`./img/ic_description.png`} alt="description icon"/>
              <div style={{fontSize: '14px', fontWeight: 700}}>입력 시 참고하세요~</div>
            </div>

            <div style={{ marginTop: 5, marginLeft: 10, fontSize: '12px', fontWeight: 400, color: '#8A92A6', lineHeight: '15px'}}>
              {"- 연속 변수 직접 입력"}<br/>
              {"- Max: 72  /  Min: 0"}<br/>
              {"- 숫자만 입력 가능"}<br/>

            </div>

            <div style={{width: '100%', height: 240, marginTop: 10, fontSize: '12px', textAlign: 'center', borderRadius: '4px', border: '1px solid #A2A2A2', background: 'white'}}>
              
                <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 4fr', marginTop: 20, height: 30, fontWeight: 700, textAlign: 'center', marginRight: 30}}>
                  <div>날짜</div>
                  <div>
                    <input 
                      type='text'
                      className={cn('cellTextInput')}
                      style={{textAlign: 'center', height: 25, background: '#F3F3F3', borderRadius: 8, marginTop: -5}}
                      defaultValue={easiData.entered_date}
                      disabled={modalState.mode !== "edit" && modalState.mode !== "new"}
                      maxLength={10}
                      onKeyUp={(e) => {
                        const value = e.currentTarget.value;
                        const s = e.currentTarget.selectionStart;

                        if (s === null) return;
                        if (isNaN(Number(e.key))) return;

                        if (value.length === 5 && !value.endsWith('-')) {
                          console.log('here4')
                          e.currentTarget.value = `${value.slice(0, 4)}-${value.slice(-1)}`
                        }
                        else if (value.length === 8 && !value.endsWith('-')) {
                          console.log('here5')
                          e.currentTarget.value = `${value.slice(0, 4)}-${value.slice(5, 7)}-${value.slice(-1)}`
                        }
                        else if (value.length === 6 && !value.includes('-')) {
                          console.log('here6')
                          e.currentTarget.value = `${value.slice(0, 4)}-${value.slice(-2)}`
                        }
                        else if ((value.length === 9 || value.length === 10) && value.charAt(7) !== '-' && s !== 5) {
                          console.log('here7 position: '+s)
                          e.currentTarget.value = `${value.slice(0, 4)}-${value.slice(5, 7)}-${value.slice(7, 9)}`
                          e.currentTarget.selectionEnd = s+1;
                        }
                        setEasiData({
                          ...easiData,
                          entered_date: e.currentTarget.value
                        });

                      }}
                      onKeyDown={(e) => {
                        if (e.currentTarget.value.length === 10 && !isNaN(Number(e.key))) {
                          const s = e.currentTarget.selectionStart;
                          const value = e.currentTarget.value;
                          console.log(`selection: ${s} / key: ${e.key}`)

                          if (s === null) return;

                          if (s === 4 || s === 7) {
                            if (e.currentTarget.value !== `${value.slice(0, s)}-${e.key}${value.slice(s+2)}`) {
                              e.currentTarget.value = `${value.slice(0, s)}-${e.key}${value.slice(s+2)}`
                            }
                            else {
                              e.preventDefault();
                              e.currentTarget.value = `${value.slice(0, s)}-${e.key}${value.slice(s+2)}`
                              e.currentTarget.selectionStart = s+2;
                            }
                            e.currentTarget.selectionEnd = s+2;
                          }
                          else if (s !== 10) {
                            console.log('here2')
                            e.currentTarget.value = `${value.slice(0, s)}${e.key}${value.slice(s+1)}`
                            e.currentTarget.selectionEnd = s+1;
                          }
                        }
                      }}
                      onChange={(e) => {
                        setEasiData({
                          ...easiData,
                          entered_date: e.target.value
                        });
                        setModalState({
                          ...modalState,
                          isChanged: true
                        })
                      }}>
                    </input>
                  </div>
                </div>

                <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 4fr', marginTop: 20, height: 30, fontWeight: 700, textAlign: 'center', marginRight: 30}}>
                  <div>EASI</div>
                  <div>
                    <input 
                      type='number'
                      className={cn('cellTextInput')}
                      style={{textAlign: 'center', height: 25, background: '#F3F3F3', borderRadius: 8, marginTop: -5}}
                      defaultValue={easiData.response_easi}
                      disabled={modalState.mode !== "edit" && modalState.mode !== "new"}
                      onChange={(e) => {
                        setEasiData({
                          ...easiData,
                          response_easi: e.target.value
                        });
                        setModalState({
                          ...modalState,
                          isChanged: true
                        })
                      }}>
                    </input>
                  </div>

                </div>
            
            </div>

            <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', justifyContent: 'center', gap: 10, marginTop: 15}}>
              
              {modalState.mode === "new"?
              <button style={{width: 160, height: 36, border: 'none', background: '#3A57E8', borderRadius: '4px', cursor: 'pointer', color: 'white', marginLeft: 20}}
                onClick={() => {
                  InsertEasiData(0)
                }}>
                저장
              </button> : null}

              {modalState.mode === "edit"?
              <button style={{width: 160, height: 36, border: 'none', background: '#3A57E8', borderRadius: '4px', cursor: 'pointer', color: 'white', marginLeft: 20}}
                onClick={() => {
                  InsertEasiData(modalState.metadata_id)
                }}>
                저장
              </button> : null}

              {modalState.mode === "detail" ?
              <button style={{width: 160, height: 36, border: 'none', background: '#3A57E8', borderRadius: '4px', cursor: 'pointer', color: 'white', marginLeft: 20}}
                onClick={() => {
                  setModalState({
                    ...modalState,
                    mode: 'edit'
                  })
                }}>
                수정
              </button> : null}


              {modalState.mode === "detail" || modalState.mode === "new" ?
              <button style={{width: 160, height: 36, border: 'none', background: '#54595E', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                onClick={() => {
                  setModalState({
                    type: "",
                    mode: ""
                  })
                }}>
                닫기
              </button> :  null}

              {modalState.mode === "edit" ?
              <button style={{width: 160, height: 36, border: 'none', background: '#54595E', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                onClick={() => {
                  if (modalState.isChanged === true) {
                    setDialogState({
                      isOpen: true,
                      title: "저장되지 않은 수정 사항이 있습니다. 페이지를 닫으시겠습니까?",
                      desc: "저장되지 않은 수정 사항은 페이지 종료 시 반영되지 않습니다.",
                      accept_label: "닫기",
                      deny_label: "취소",
                      accept_callback: () => {
                        setModalState({
                          ...modalState,
                          type: "",
                          mode: ""
                        })
                        ReloadDetailEasiData();
                        setTimeout(() => {
                          setModalState({
                            ...modalState,
                            mode: "detail",
                            isChanged: false,
                          })
                          setDialogState({
                            isOpen: false
                          })
  
                        }, 100)
                        
                      }
                    })
                  }
                  else {
                    setModalState({
                      ...modalState,
                      mode: "detail"
                    })
                  } 
                }}>
                뒤로
              </button> :  null}
            </div>

          </div>
        </div>
        : null}

      {modalState.type === "adr" ?
        <div className={cn('modalBase')} style={{minHeight: 720}}>
          <div className={cn('modalPaper')} style={{height: 420, padding: 20}}>
            <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', gap: 10}}>
              
              {modalState.mode === "detail" ?
              <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', gap: 10}}>
                <div>부작용</div>
              </div>: null}

              {modalState.mode === "new" || modalState.mode === "edit" ?
              <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', gap: 10}}>
                <div>부작용 {modalState.mode === "new" ? "신규 등록" : "수정"} </div>
              </div>: null}
 
              <img style={{width: 24, height: 24, marginLeft: 'auto', alignSelf: 'start', cursor: 'pointer'}} src={`./img/btn_close.png`} alt="dashboard background"
                onClick={() => {
                  if (modalState.isChanged === true) {
                    setDialogState({
                      isOpen: true,
                      title: "저장되지 않은 수정 사항이 있습니다. 페이지를 닫으시겠습니까?",
                      desc: "저장되지 않은 수정 사항은 페이지 종료 시 반영되지 않습니다.",
                      accept_label: "닫기",
                      deny_label: "취소",
                      accept_callback: () => {
                        setModalState({
                          type: "",
                          mode: ""
                        })
                        setDialogState({
                          isOpen: false
                        })
                      }
                    })
                  }
                  else {
                    setModalState({
                      type: "",
                      mode: ""
                    })
                  }
                }}/>
            </div>

            <div style={{display: 'flex', height: 20, borderRadius: 8, background: 'white', marginTop: 10, padding: 20, alignItems: 'center'}}>
              <div style={{fontSize: '20px', fontWeight: 700}}>{markName(participantInfo.participant_name)}</div>
              <div style={{fontSize: '15px', fontWeight: 400, marginTop: 5, marginLeft: 10}}> | 연구 등록 번호 {participantInfo.participant_id}</div>
              
              {modalState.mode === "detail" || modalState.mode === "edit" ?
              <div style={{width: 130, fontSize: '13px', fontWeight: 400, marginTop: 5, marginLeft: 'auto', textAlign: 'left'}}>
                최초등록 {modalState.created_at}<br/>
                업데이트 {modalState.updated_at}<br/>
              </div> : null}
            </div>

            <div style={{display: 'flex', marginTop: 10, height: 24, alignItems: 'center', gap: 10, marginLeft: 10}}>
              <img style={{width: 24, height: 24, cursor: 'pointer'}} src={`./img/ic_description.png`} alt="description icon"/>
              <div style={{fontSize: '14px', fontWeight: 700}}>입력 시 참고하세요~</div>
            </div>

            <div style={{ marginTop: 10, marginLeft: 10, fontSize: '12px', fontWeight: 400, color: '#8A92A6', lineHeight: '15px'}}>
              {"- 영어로 입력"}<br/>

            </div>

            <div style={{width: '100%', height: 240, marginTop: 10, fontSize: '12px', textAlign: 'center', borderRadius: '4px', border: '1px solid #A2A2A2', background: 'white'}}>
              
                <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 4fr', marginTop: 20, height: 30, fontWeight: 700, textAlign: 'center', marginRight: 30}}>
                  <div>날짜</div>
                  <div>
                    <input 
                      type='text'
                      className={cn('cellTextInput')}
                      style={{textAlign: 'center', height: 25, background: '#F3F3F3', borderRadius: 8, marginTop: -5}}
                      defaultValue={adrData.entered_date}
                      disabled={modalState.mode !== "edit" && modalState.mode !== "new"}
                      maxLength={10}
                      onKeyUp={(e) => {
                        const value = e.currentTarget.value;
                        const s = e.currentTarget.selectionStart;

                        if (s === null) return;
                        if (isNaN(Number(e.key))) return;

                        if (value.length === 5 && !value.endsWith('-')) {
                          console.log('here4')
                          e.currentTarget.value = `${value.slice(0, 4)}-${value.slice(-1)}`
                        }
                        else if (value.length === 8 && !value.endsWith('-')) {
                          console.log('here5')
                          e.currentTarget.value = `${value.slice(0, 4)}-${value.slice(5, 7)}-${value.slice(-1)}`
                        }
                        else if (value.length === 6 && !value.includes('-')) {
                          console.log('here6')
                          e.currentTarget.value = `${value.slice(0, 4)}-${value.slice(-2)}`
                        }
                        else if ((value.length === 9 || value.length === 10) && value.charAt(7) !== '-' && s !== 5) {
                          console.log('here7 position: '+s)
                          e.currentTarget.value = `${value.slice(0, 4)}-${value.slice(5, 7)}-${value.slice(7, 9)}`
                          e.currentTarget.selectionEnd = s+1;
                        }
                        setAdrData({
                          ...adrData,
                          entered_date: e.currentTarget.value
                        });

                      }}
                      onKeyDown={(e) => {
                        if (e.currentTarget.value.length === 10 && !isNaN(Number(e.key))) {
                          const s = e.currentTarget.selectionStart;
                          const value = e.currentTarget.value;
                          console.log(`selection: ${s} / key: ${e.key}`)

                          if (s === null) return;

                          if (s === 4 || s === 7) {
                            if (e.currentTarget.value !== `${value.slice(0, s)}-${e.key}${value.slice(s+2)}`) {
                              e.currentTarget.value = `${value.slice(0, s)}-${e.key}${value.slice(s+2)}`
                            }
                            else {
                              e.preventDefault();
                              e.currentTarget.value = `${value.slice(0, s)}-${e.key}${value.slice(s+2)}`
                              e.currentTarget.selectionStart = s+2;
                            }
                            e.currentTarget.selectionEnd = s+2;
                          }
                          else if (s !== 10) {
                            console.log('here2')
                            e.currentTarget.value = `${value.slice(0, s)}${e.key}${value.slice(s+1)}`
                            e.currentTarget.selectionEnd = s+1;
                          }
                        }
                      }}
                      onChange={(e) => {
                        setAdrData({
                          ...adrData,
                          entered_date: e.target.value
                        });
                        setModalState({
                          ...modalState,
                          isChanged: true
                        })
                      }}>
                    </input>
                  </div>
                </div>

                <div style={{display: 'grid', gridTemplateColumns: '1fr 5fr', marginTop: 20, height: 30, fontWeight: 700, textAlign: 'center', marginRight: 30}}>
                  <div>부작용</div>
                  <div style={{display: 'flex', marginLeft: 2}}>
                    <textarea 
                      className={cn('cellTextInput')}
                      style={{textAlign: 'left', width: 530, height: 50, background: '#F3F3F3', borderRadius: 8, marginTop: -5, padding: 10, resize: 'none', lineHeight: '16px'}}
                      defaultValue={adrData.response_adr}
                      disabled={modalState.mode !== "edit" && modalState.mode !== "new"}
                      onChange={(e) => {
                        setAdrData({
                          ...adrData,
                          response_adr: e.target.value
                        });
                        setModalState({
                          ...modalState,
                          isChanged: true
                        })
                      }}>
                    </textarea>
                  </div>
                </div>


            
            </div>

            <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', justifyContent: 'center', gap: 10, marginTop: 15}}>
              
              {modalState.mode === "new"?
              <button style={{width: 160, height: 36, border: 'none', background: '#3A57E8', borderRadius: '4px', cursor: 'pointer', color: 'white', marginLeft: 20}}
                onClick={() => {
                  InsertAdrData(0)
                }}>
                저장
              </button> : null}

              {modalState.mode === "edit"?
              <button style={{width: 160, height: 36, border: 'none', background: '#3A57E8', borderRadius: '4px', cursor: 'pointer', color: 'white', marginLeft: 20}}
                onClick={() => {
                  InsertAdrData(modalState.metadata_id)
                }}>
                저장
              </button> : null}

              {modalState.mode === "detail" ?
              <button style={{width: 160, height: 36, border: 'none', background: '#3A57E8', borderRadius: '4px', cursor: 'pointer', color: 'white', marginLeft: 20}}
                onClick={() => {
                  setModalState({
                    ...modalState,
                    mode: 'edit'
                  })
                }}>
                수정
              </button> : null}

              {modalState.mode === "detail" || modalState.mode === "new" ?
              <button style={{width: 160, height: 36, border: 'none', background: '#54595E', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                onClick={() => {
                  setModalState({
                    type: "",
                    mode: ""
                  })
                }}>
                닫기
              </button> :  null}

              {modalState.mode === "edit" ?
              <button style={{width: 160, height: 36, border: 'none', background: '#54595E', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
              onClick={() => {
                if (modalState.isChanged === true) {
                  setDialogState({
                    isOpen: true,
                    title: "저장되지 않은 수정 사항이 있습니다. 페이지를 닫으시겠습니까?",
                    desc: "저장되지 않은 수정 사항은 페이지 종료 시 반영되지 않습니다.",
                    accept_label: "닫기",
                    deny_label: "취소",
                    accept_callback: () => {
                      setModalState({
                        ...modalState,
                        type: "",
                        mode: ""
                      })
                      ReloadDetailAdrData();
                      setTimeout(() => {
                        setModalState({
                          ...modalState,
                          mode: "detail",
                          isChanged: false,
                        })
                        setDialogState({
                          isOpen: false
                        })

                      }, 100)
                      
                    }
                  })
                }
                else {
                  setModalState({
                    ...modalState,
                    mode: "detail"
                  })
                } 
              }}>
                뒤로
              </button> :  null}
            </div>

          </div>
        </div>
        : null}


      {modalState.type === "oas" ?
        <div className={cn('modalBase')} style={{minHeight: 720}}>
          <div className={cn('modalPaper')} style={{height: 460, padding: 20}}>
            <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', gap: 10}}>
              
              {modalState.mode === "detail" ?
              <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', gap: 10}}>
                <div>구강알레르기 증후군</div>
              </div>: null}

              {modalState.mode === "new" || modalState.mode === "edit" ?
              <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', gap: 10}}>
                <div>구강알레르기 증후군 {modalState.mode === "new" ? "신규 등록" : "수정"} </div>
              </div>: null}
 
              <img style={{width: 24, height: 24, marginLeft: 'auto', alignSelf: 'start', cursor: 'pointer'}} src={`./img/btn_close.png`} alt="dashboard background"
                onClick={() => {
                  if (modalState.isChanged === true) {
                    setDialogState({
                      isOpen: true,
                      title: "저장되지 않은 수정 사항이 있습니다. 페이지를 닫으시겠습니까?",
                      desc: "저장되지 않은 수정 사항은 페이지 종료 시 반영되지 않습니다.",
                      accept_label: "닫기",
                      deny_label: "취소",
                      accept_callback: () => {
                        setModalState({
                          type: "",
                          mode: ""
                        })
                        setDialogState({
                          isOpen: false
                        })
                      }
                    })
                  }
                  else {
                    setModalState({
                      type: "",
                      mode: ""
                    })
                  }
                }}/>
            </div>

            <div style={{display: 'flex', height: 20, borderRadius: 8, background: 'white', marginTop: 10, padding: 20, alignItems: 'center'}}>
              <div style={{fontSize: '20px', fontWeight: 700}}>{markName(participantInfo.participant_name)}</div>
              <div style={{fontSize: '15px', fontWeight: 400, marginTop: 5, marginLeft: 10}}> | 연구 등록 번호 {participantInfo.participant_id}</div>
              
              {modalState.mode === "detail" || modalState.mode === "edit" ?
              <div style={{width: 130, fontSize: '13px', fontWeight: 400, marginTop: 5, marginLeft: 'auto', textAlign: 'left'}}>
                최초등록 {modalState.created_at}<br/>
                업데이트 {modalState.updated_at}<br/>
              </div> : null}
            </div>

            <div style={{display: 'flex', marginTop: 10, height: 24, alignItems: 'center', gap: 10, marginLeft: 10}}>
              <img style={{width: 24, height: 24, cursor: 'pointer'}} src={`./img/ic_description.png`} alt="description icon"/>
              <div style={{fontSize: '14px', fontWeight: 700}}>입력 시 참고하세요~</div>
            </div>

            <div style={{ marginTop: 5, marginLeft: 10, fontSize: '12px', fontWeight: 400, color: '#8A92A6', lineHeight: '15px'}}>
              {"- 유발식품은 한글로 입력"}<br/>
            </div>

            <div style={{width: '100%', height: 230, marginTop: 10, fontSize: '12px', textAlign: 'center', borderRadius: '4px', border: '1px solid #A2A2A2', background: 'white'}}>
              
                <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 4fr', marginTop: 20, height: 30, fontWeight: 700, textAlign: 'center', marginRight: 30}}>
                  <div>날짜</div>
                  <div>
                    <input 
                      type='text'
                      className={cn('cellTextInput')}
                      style={{textAlign: 'center', height: 25, background: '#F3F3F3', borderRadius: 8, marginTop: -5}}
                      defaultValue={oasData.entered_date}
                      disabled={modalState.mode !== "edit" && modalState.mode !== "new"}
                      maxLength={10}
                      onKeyUp={(e) => {
                        const value = e.currentTarget.value;
                        const s = e.currentTarget.selectionStart;

                        if (s === null) return;
                        if (isNaN(Number(e.key))) return;

                        if (value.length === 5 && !value.endsWith('-')) {
                          console.log('here4')
                          e.currentTarget.value = `${value.slice(0, 4)}-${value.slice(-1)}`
                        }
                        else if (value.length === 8 && !value.endsWith('-')) {
                          console.log('here5')
                          e.currentTarget.value = `${value.slice(0, 4)}-${value.slice(5, 7)}-${value.slice(-1)}`
                        }
                        else if (value.length === 6 && !value.includes('-')) {
                          console.log('here6')
                          e.currentTarget.value = `${value.slice(0, 4)}-${value.slice(-2)}`
                        }
                        else if ((value.length === 9 || value.length === 10) && value.charAt(7) !== '-' && s !== 5) {
                          console.log('here7 position: '+s)
                          e.currentTarget.value = `${value.slice(0, 4)}-${value.slice(5, 7)}-${value.slice(7, 9)}`
                          e.currentTarget.selectionEnd = s+1;
                        }
                        setOasData({
                          ...oasData,
                          entered_date: e.currentTarget.value
                        });

                      }}
                      onKeyDown={(e) => {
                        if (e.currentTarget.value.length === 10 && !isNaN(Number(e.key))) {
                          const s = e.currentTarget.selectionStart;
                          const value = e.currentTarget.value;
                          console.log(`selection: ${s} / key: ${e.key}`)

                          if (s === null) return;

                          if (s === 4 || s === 7) {
                            if (e.currentTarget.value !== `${value.slice(0, s)}-${e.key}${value.slice(s+2)}`) {
                              e.currentTarget.value = `${value.slice(0, s)}-${e.key}${value.slice(s+2)}`
                            }
                            else {
                              e.preventDefault();
                              e.currentTarget.value = `${value.slice(0, s)}-${e.key}${value.slice(s+2)}`
                              e.currentTarget.selectionStart = s+2;
                            }
                            e.currentTarget.selectionEnd = s+2;
                          }
                          else if (s !== 10) {
                            console.log('here2')
                            e.currentTarget.value = `${value.slice(0, s)}${e.key}${value.slice(s+1)}`
                            e.currentTarget.selectionEnd = s+1;
                          }
                        }
                      }}
                      onChange={(e) => {
                        setOasData({
                          ...oasData,
                          entered_date: e.target.value
                        });
                        setModalState({
                          ...modalState,
                          isChanged: true
                        })
                      }}>
                    </input>
                  </div>
                </div>

                <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 4fr', marginTop: 10, height: 30, fontWeight: 700, textAlign: 'center', marginRight: 30}}>
                  <div>증후군 여부</div>
                  <div>
                    <form style={{border: 'none', display: 'flex', alignItems: 'center', gap: 3}}>
                      <input 
                        type='radio'
                        id="allergyTrue"
                        name="allergyRadio"
                        defaultChecked={oasData.existence_of_oas}
                        disabled={modalState.mode !== "edit" && modalState.mode !== "new"}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setOasData({
                              ...oasData,
                              existence_of_oas: true
                            });
                          }
                          setModalState({
                            ...modalState,
                            isChanged: true
                          });
                        }} />
                      <label style={{marginTop: 3}}>Yes</label>
                      <input 
                        type='radio'
                        id="allergyFalse"
                        name="allergyRadio"
                        defaultChecked={!oasData.existence_of_oas}
                        style={{marginLeft: 15}}
                        disabled={modalState.mode !== "edit" && modalState.mode !== "new"}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setOasData({
                              entered_date: customYMD(Date.now()),
                              existence_of_oas: false
                            });
                          }
                          setModalState({
                            ...modalState,
                            isChanged: true
                          });
                        }} />
                      <label style={{marginTop: 3}}>No</label>
                    </form>
                  </div>
                </div>

                <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr', marginTop: 10, height: 30, fontWeight: 700, textAlign: 'center', marginRight: 30}}>
                  <div>유발식품 1</div>
                  <div>
                    <input 
                      type='text'
                      className={cn('cellTextInput')}
                      style={{textAlign: 'center', height: 25, background: '#F3F3F3', borderRadius: 8, marginTop: -5}}
                      defaultValue={oasData.response_allergen_1}
                      disabled={(modalState.mode !== "edit" && modalState.mode !== "new") || !oasData.existence_of_oas}
                      onChange={(e) => {
                        setOasData({
                          ...oasData,
                          response_allergen_1: e.target.value
                        });
                        setModalState({
                          ...modalState,
                          isChanged: true
                        })
                      }}>
                    </input>
                  </div>

                  <div>유발식품 2</div>
                  <div>
                    <input 
                      type='text'
                      className={cn('cellTextInput')}
                      style={{textAlign: 'center', height: 25, background: '#F3F3F3', borderRadius: 8, marginTop: -5}}
                      defaultValue={oasData.response_allergen_2}
                      disabled={(modalState.mode !== "edit" && modalState.mode !== "new") || !oasData.existence_of_oas}
                      onChange={(e) => {
                        setOasData({
                          ...oasData,
                          response_allergen_2: e.target.value
                        });
                        setModalState({
                          ...modalState,
                          isChanged: true
                        })
                      }}>
                    </input>
                  </div>

                  <div>유발식품 3</div>
                  <div>
                    <input 
                      type='text'
                      maxLength={10}
                      className={cn('cellTextInput')}
                      style={{textAlign: 'center', height: 25, background: '#F3F3F3', borderRadius: 8, marginTop: -5}}
                      defaultValue={oasData.response_allergen_3}
                      disabled={(modalState.mode !== "edit" && modalState.mode !== "new") || !oasData.existence_of_oas}
                      onChange={(e) => {
                        setOasData({
                          ...oasData,
                          response_allergen_3: e.target.value
                        });
                        setModalState({
                          ...modalState,
                          isChanged: true
                        })
                      }}>
                    </input>
                  </div>
                </div>
                <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr', marginTop: 10, height: 30, fontWeight: 700, textAlign: 'center', marginRight: 30}}>
                  <div>유발식품 4</div>
                  <div>
                    <input 
                      type='text'
                      className={cn('cellTextInput')}
                      style={{textAlign: 'center', height: 25, background: '#F3F3F3', borderRadius: 8, marginTop: -5}}
                      defaultValue={oasData.response_allergen_4}
                      disabled={(modalState.mode !== "edit" && modalState.mode !== "new") || !oasData.existence_of_oas}
                      onChange={(e) => {
                        setOasData({
                          ...oasData,
                          response_allergen_4: e.target.value
                        });
                        setModalState({
                          ...modalState,
                          isChanged: true
                        })
                      }}>
                    </input>
                  </div>

                  <div>유발식품 5</div>
                  <div>
                    <input 
                      type='text'
                      className={cn('cellTextInput')}
                      style={{textAlign: 'center', height: 25, background: '#F3F3F3', borderRadius: 8, marginTop: -5}}
                      defaultValue={oasData.response_allergen_5}
                      disabled={(modalState.mode !== "edit" && modalState.mode !== "new") || !oasData.existence_of_oas}
                      onChange={(e) => {
                        setOasData({
                          ...oasData,
                          response_allergen_5: e.target.value
                        });
                        setModalState({
                          ...modalState,
                          isChanged: true
                        })
                      }}>
                    </input>
                  </div>

                  <div>유발식품 6</div>
                  <div>
                    <input 
                      type='text'
                      maxLength={10}
                      className={cn('cellTextInput')}
                      style={{textAlign: 'center', height: 25, background: '#F3F3F3', borderRadius: 8, marginTop: -5}}
                      defaultValue={oasData.response_allergen_6}
                      disabled={(modalState.mode !== "edit" && modalState.mode !== "new") || !oasData.existence_of_oas}
                      onChange={(e) => {
                        setOasData({
                          ...oasData,
                          response_allergen_6: e.target.value
                        });
                        setModalState({
                          ...modalState,
                          isChanged: true
                        })
                      }}>
                    </input>
                  </div>
                </div>
                <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr', marginTop: 10, height: 30, fontWeight: 700, textAlign: 'center', marginRight: 30}}>
                  <div>유발식품 7</div>
                  <div>
                    <input 
                      type='text'
                      className={cn('cellTextInput')}
                      style={{textAlign: 'center', height: 25, background: '#F3F3F3', borderRadius: 8, marginTop: -5}}
                      defaultValue={oasData.response_allergen_7}
                      disabled={(modalState.mode !== "edit" && modalState.mode !== "new") || !oasData.existence_of_oas}
                      onChange={(e) => {
                        setOasData({
                          ...oasData,
                          response_allergen_7: e.target.value
                        });
                        setModalState({
                          ...modalState,
                          isChanged: true
                        })
                      }}>
                    </input>
                  </div>

                  <div>유발식품 8</div>
                  <div>
                    <input 
                      type='text'
                      className={cn('cellTextInput')}
                      style={{textAlign: 'center', height: 25, background: '#F3F3F3', borderRadius: 8, marginTop: -5}}
                      defaultValue={oasData.response_allergen_8}
                      disabled={(modalState.mode !== "edit" && modalState.mode !== "new") || !oasData.existence_of_oas}
                      onChange={(e) => {
                        setOasData({
                          ...oasData,
                          response_allergen_8: e.target.value
                        });
                        setModalState({
                          ...modalState,
                          isChanged: true
                        })
                      }}>
                    </input>
                  </div>

                  <div>유발식품 9</div>
                  <div>
                    <input 
                      type='text'
                      maxLength={10}
                      className={cn('cellTextInput')}
                      style={{textAlign: 'center', height: 25, background: '#F3F3F3', borderRadius: 8, marginTop: -5}}
                      defaultValue={oasData.response_allergen_9}
                      disabled={(modalState.mode !== "edit" && modalState.mode !== "new") || !oasData.existence_of_oas}
                      onChange={(e) => {
                        setOasData({
                          ...oasData,
                          response_allergen_9: e.target.value
                        });
                        setModalState({
                          ...modalState,
                          isChanged: true
                        })
                      }}>
                    </input>
                  </div>
                </div>

            </div>

            <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', justifyContent: 'center', gap: 10, marginTop: 15}}>
              
              {modalState.mode === "new"?
              <button style={{width: 160, height: 36, border: 'none', background: '#3A57E8', borderRadius: '4px', cursor: 'pointer', color: 'white', marginLeft: 20}}
                onClick={() => {
                  InsertOasData(0)
                }}>
                저장
              </button> : null}

              {modalState.mode === "edit"?
              <button style={{width: 160, height: 36, border: 'none', background: '#3A57E8', borderRadius: '4px', cursor: 'pointer', color: 'white', marginLeft: 20}}
                onClick={() => {
                  InsertOasData(modalState.metadata_id)
                }}>
                저장
              </button> : null}

              {modalState.mode === "detail" ?
              <button style={{width: 160, height: 36, border: 'none', background: '#3A57E8', borderRadius: '4px', cursor: 'pointer', color: 'white', marginLeft: 20}}
                onClick={() => {
                  setModalState({
                    ...modalState,
                    mode: 'edit'
                  })
                }}>
                수정
              </button> : null}

              {modalState.mode === "detail" || modalState.mode === "new" ?
              <button style={{width: 160, height: 36, border: 'none', background: '#54595E', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                onClick={() => {
                  setModalState({
                    type: "",
                    mode: ""
                  })
                }}>
                닫기
              </button> :  null}

              {modalState.mode === "edit" ?
              <button style={{width: 160, height: 36, border: 'none', background: '#54595E', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
              onClick={() => {
                if (modalState.isChanged === true) {
                  setDialogState({
                    isOpen: true,
                    title: "저장되지 않은 수정 사항이 있습니다. 페이지를 닫으시겠습니까?",
                    desc: "저장되지 않은 수정 사항은 페이지 종료 시 반영되지 않습니다.",
                    accept_label: "닫기",
                    deny_label: "취소",
                    accept_callback: () => {
                      setModalState({
                        ...modalState,
                        type: "",
                        mode: ""
                      })
                      ReloadDetailOasData();
                      setTimeout(() => {
                        setModalState({
                          ...modalState,
                          mode: "detail",
                          isChanged: false,
                        })
                        setDialogState({
                          isOpen: false
                        })

                      }, 100)
                      
                    }
                  })
                }
                else {
                  setModalState({
                    ...modalState,
                    mode: "detail"
                  })
                } 
              }}>
              뒤로
              </button> :  null}
            </div>

          </div>
        </div>
        : null}

      {modalState.type === "survey" ?
        <div className={cn('modalBase')} style={{minHeight: 720}}>
          <div className={cn('modalPaper')} style={{height: 720, width: 1200, padding: 20}}>
            <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', gap: 10}}>
              
              {modalState.mode === "detail" ?
              <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', gap: 10}}>
                <div>설문결과</div>
              </div>: null}

              <img style={{width: 24, height: 24, marginLeft: 'auto', alignSelf: 'start', cursor: 'pointer'}} src={`./img/btn_close.png`} alt="dashboard background"
                onClick={() => {
                  setModalState({
                    type: "",
                    mode: ""
                  })
                }}/>
            </div>

            <div style={{height: 60, borderRadius: 8, background: 'white', marginTop: 10, padding: 20, alignItems: 'center'}}>
              <div style={{display: 'flex'}}>
                <div style={{fontSize: '20px', fontWeight: 700}}>{markName(participantInfo.participant_name)}</div>
                <div style={{fontSize: '15px', fontWeight: 400, marginTop: 5, marginLeft: 10}}> | 연구 등록 번호 {participantInfo.participant_id}</div>
                
              </div>
              <div style={{fontSize: '15px', fontWeight: 400, marginTop: 10}}>설문 응답일 {surveyData.surveyed_at.slice(0,10)}</div>

            </div>

            <div style={{padding: '15px 15px', height: 45, background: 'white', boxShadow: '0px 10px 13px rgba(17, 38, 146, 0.05)', borderRadius: '8px', marginTop: 10, alignItems: 'center', fontSize: '14px', color: '#54595E'}}>
              <div style={{display: 'grid', gridTemplateColumns: '0.7fr 0.7fr 0.7fr 0.7fr 5fr'}}>
                <div>ADCT : {surveyData.adct_score}</div>
                <div>{surveyData.response_2_age === 1 ? 'IDLQI' : surveyData.response_2_age === 2 ? 'CDLQI' : 'DLQI'} : {surveyData.qol_score}</div> 
                <div>DFI : {surveyData.dfi_score}</div>
                <div>POEM : {surveyData.poem_score}</div>
              </div>
              <div style={{marginTop: 10}}>
                치료부작용 : {getAdrString(surveyData) ?? "없음"}
              </div>
            </div>


            <div style={{padding: '15px 15px', height: 500, marginTop: 10, fontSize: '16px', borderRadius: '4px', background: 'white', boxShadow: '0px 10px 13px rgba(17, 38, 146, 0.05)', overflowY: 'scroll'}}>
                <>
                  <div style={{fontSize: 16, fontWeight: 600, color: '#54595E'}}>아토피피부염 관리도구 ADCT</div>
                  
                  <div className={cn('surveyRow1')} style={{marginTop: 10}}>
                    <div/><div>1. 지난 7일 동안, 아토피피부염과 관련한 증상이 어느 정도였나요?</div>
                    <div className={cn('answerRowGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_1a === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_1a === 1})}>{ANSWER_DICT.response_1a[1]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_1a === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_1a === 2})}>{ANSWER_DICT.response_1a[2]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_1a === 3 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_1a === 3})}>{ANSWER_DICT.response_1a[3]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_1a === 4 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_1a === 4})}>{ANSWER_DICT.response_1a[4]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_1a === 5 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_1a === 5})}>{ANSWER_DICT.response_1a[5]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow2')}>
                    <div/><div>2. 지난 7일 동안, 아토피피부염 때문에 심하게 가려웠던 날이 며칠이나 되나요?</div>
                    <div className={cn('answerRowGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_1b === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_1b === 1})}>{ANSWER_DICT.response_1b[1]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_1b === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_1b === 2})}>{ANSWER_DICT.response_1b[2]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_1b === 3 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_1b === 3})}>{ANSWER_DICT.response_1b[3]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_1b === 4 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_1b === 4})}>{ANSWER_DICT.response_1b[4]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_1b === 5 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_1b === 5})}>{ANSWER_DICT.response_1b[5]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow1')}>
                    <div/><div> 3. 지난 7일 동안, 아토피피부염 때문에 얼마나 불편했나요?</div>
                    <div className={cn('answerRowGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_1c === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_1c === 1})}>{ANSWER_DICT.response_1c[1]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_1c === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_1c === 2})}>{ANSWER_DICT.response_1c[2]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_1c === 3 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_1c === 3})}>{ANSWER_DICT.response_1c[3]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_1c === 4 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_1c === 4})}>{ANSWER_DICT.response_1c[4]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_1c === 5 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_1c === 5})}>{ANSWER_DICT.response_1c[5]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow2')}>
                    <div/><div> 4. 지난 7일 동안, 아토피피부염 때문에 수면에(잠이 들고 잠을 유지하는 데에) 어려움이 있었던 날이 며칠이나 되나요? </div>
                    <div className={cn('answerRowGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_1d === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_1d === 1})}>{ANSWER_DICT.response_1d[1]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_1d === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_1d === 2})}>{ANSWER_DICT.response_1d[2]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_1c === 3 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_1d === 3})}>{ANSWER_DICT.response_1d[3]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_1d === 4 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_1d === 4})}>{ANSWER_DICT.response_1d[4]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_1d === 5 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_1d === 5})}>{ANSWER_DICT.response_1d[5]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow1')}>
                    <div/><div> 5. 지난 7일 동안, 아토피 피부염이 일상 활동에 미친 영향이 얼마나 되나요?</div>
                    <div className={cn('answerRowGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_1e === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_1e === 1})}>{ANSWER_DICT.response_1e[1]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_1e === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_1e === 2})}>{ANSWER_DICT.response_1e[2]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_1e === 3 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_1e === 3})}>{ANSWER_DICT.response_1e[3]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_1e === 4 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_1e === 4})}>{ANSWER_DICT.response_1e[4]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_1e === 5 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_1e === 5})}>{ANSWER_DICT.response_1e[5]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow2')}>
                    <div/><div> 6. 지난 7일 동안, 아토피 피부염이 기분이나 감정에 미친 영향이 얼마나 되나요?</div>
                    <div className={cn('answerRowGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_1f === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_1f === 1})}>{ANSWER_DICT.response_1f[1]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_1f === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_1f === 2})}>{ANSWER_DICT.response_1f[2]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_1f === 3 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_1f === 3})}>{ANSWER_DICT.response_1f[3]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_1f === 4 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_1f === 4})}>{ANSWER_DICT.response_1f[4]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_1f === 5 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_1f === 5})}>{ANSWER_DICT.response_1f[5]}</div>
                      </div>
                    </div>
                  </div>
                </>

                {surveyData.response_2_age === 1 ?
                <>
                  <div style={{fontSize: 16, fontWeight: 600, color: '#54595E', marginTop: 30}}>아토피피부염 삶의 질 IDLQI</div>
                  
                  <div className={cn('surveyRow1')} style={{marginTop: 10}}>
                    <div/><div>나이를 선택하세요.</div>
                    <div className={cn('answerRowGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2_age === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2_age === 1})}>{ANSWER_DICT.response_2_age[1]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2_age === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2_age === 2})}>{ANSWER_DICT.response_2_age[2]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2_age === 3 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2_age === 3})}>{ANSWER_DICT.response_2_age[3]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow2')}>
                    <div/><div>[IDLQI] 1. 지난 1주간 아이가 얼마나 가려워하고 긁었나요?</div>
                    <div className={cn('answerRowGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2a === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2a === 1})}>{ANSWER_DICT.response_2a[1]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2a === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2a === 2})}>{ANSWER_DICT.response_2a[2]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2a === 3 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2a === 3})}>{ANSWER_DICT.response_2a[3]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2a === 4 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2a === 4})}>{ANSWER_DICT.response_2a[4]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow1')}>
                    <div/><div>[IDLQI] 2. 지난 1주간 아이의 기분은 어떘습니까?</div>
                    <div className={cn('answerRowGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2b === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2b === 1})}>{ANSWER_DICT.response_2b[1]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2b === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2b === 2})}>{ANSWER_DICT.response_2b[2]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2b === 3 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2b === 3})}>{ANSWER_DICT.response_2b[3]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2b === 4 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2b === 4})}>{ANSWER_DICT.response_2b[4]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow1')}>
                    <div/><div>[IDLQI] 3. 지난 1주간 매일 밤 잠드는데 드는 평균 시간은 얼마나 되었나요?</div>
                    <div className={cn('answerRowGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2c === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2c === 1})}>{ANSWER_DICT.response_2c[1]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2c === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2c === 2})}>{ANSWER_DICT.response_2c[2]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2c === 3 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2c === 3})}>{ANSWER_DICT.response_2c[3]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2c === 4 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2c === 4})}>{ANSWER_DICT.response_2c[4]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow2')}>
                    <div/><div>[IDLQI] 4. 지난 1주간 잠자는 도중 깨거나 힘들어한 시간이 하루 평균 몇 시간인가요?</div>
                    <div className={cn('answerRowGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2d === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2d === 1})}>{ANSWER_DICT.response_2d[1]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2d === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2d === 2})}>{ANSWER_DICT.response_2d[2]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2d === 3 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_1d === 3})}>{ANSWER_DICT.response_2d[3]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2d === 4 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2d === 4})}>{ANSWER_DICT.response_2d[4]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow1')}>
                    <div/><div>[IDLQI] 5. 지난 1주간 아이의 피부염이 아이가 노는데 얼마나 방해가 되었나요?</div>
                    <div className={cn('answerRowGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2e === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2e === 1})}>{ANSWER_DICT.response_2e[1]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2e === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2e === 2})}>{ANSWER_DICT.response_2e[2]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2e === 3 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2e === 3})}>{ANSWER_DICT.response_2e[3]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2e === 4 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2e === 4})}>{ANSWER_DICT.response_2e[4]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow2')}>
                    <div/><div>[IDLQI] 6. 지난 1주간 아이의 피부염 때문에 아이가 집안 행사에 참여하거나 다른 가족과 함께 즐기는데 방해가 된 적이 있었습니까?</div>
                    <div className={cn('answerRowGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2f === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2f === 1})}>{ANSWER_DICT.response_2f[1]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2f === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2f === 2})}>{ANSWER_DICT.response_2f[2]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2f === 3 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2f === 3})}>{ANSWER_DICT.response_2f[3]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2f === 4 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2f === 4})}>{ANSWER_DICT.response_2f[4]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow1')}>
                    <div/><div>[IDLQI] 7. 지난 1주간 아이의 피부염으로 아이가 식사하는데 어려움이 있었습니까?</div>
                    <div className={cn('answerRowGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2g === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2g === 1})}>{ANSWER_DICT.response_2g[1]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2g === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2g === 2})}>{ANSWER_DICT.response_2g[2]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2g === 3 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2g === 3})}>{ANSWER_DICT.response_2g[3]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2g === 4 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2g === 4})}>{ANSWER_DICT.response_2g[4]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow2')}>
                    <div/><div>[IDLQI] 8. 지난 1주간 아이의 피부염으로 아이를 치료하는데 어려움이 있었습니까?</div>
                    <div className={cn('answerRowGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2h === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2h === 1})}>{ANSWER_DICT.response_2h[1]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2h === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2h === 2})}>{ANSWER_DICT.response_2h[2]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2h === 3 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2h === 3})}>{ANSWER_DICT.response_2h[3]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2h === 4 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2h === 4})}>{ANSWER_DICT.response_2h[4]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow1')}>
                    <div/><div>[IDLQI] 9. 지난 1주간 아이의 피부염으로 인하여 옷을 입히고 벗기는데 불편한 점이 있었습니까?</div>
                    <div className={cn('answerRowGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2i === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2i === 1})}>{ANSWER_DICT.response_2i[1]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2i === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2i === 2})}>{ANSWER_DICT.response_2i[2]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2i === 3 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2i === 3})}>{ANSWER_DICT.response_2i[3]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2i === 4 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2i === 4})}>{ANSWER_DICT.response_2i[4]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow2')}>
                    <div/><div>[IDLQI] 10. 지난 1주간 아이의 피부염으로 인하여 목욕하는데 어떤 어려움이 있었습니까?</div>
                    <div className={cn('answerRowGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2j === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2j === 1})}>{ANSWER_DICT.response_2j[1]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2j === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2j === 2})}>{ANSWER_DICT.response_2j[2]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2j === 3 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2j === 3})}>{ANSWER_DICT.response_2j[3]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2j === 4 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2j === 4})}>{ANSWER_DICT.response_2j[4]}</div>
                      </div>
                    </div>
                  </div>


                </> : null}

                {surveyData.response_2_age === 2 ?
                <>
                  <div style={{fontSize: 16, fontWeight: 600, color: '#54595E', marginTop: 30}}>아토피피부염 삶의 질 CDLQI</div>
                  
                  <div className={cn('surveyRow1')} style={{marginTop: 10}}>
                    <div/><div>나이를 선택하세요.</div>
                    <div className={cn('answerRowGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2_age === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2_age === 1})}>{ANSWER_DICT.response_2_age[1]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2_age === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2_age === 2})}>{ANSWER_DICT.response_2_age[2]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2_age === 3 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2_age === 3})}>{ANSWER_DICT.response_2_age[3]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow2')}>
                    <div/><div>[CDLQI] 1. 지난 1주일 동안, 얼마나 피부가 가려웠나요? 피부를 긁어서 따갑거나 상처가 생겼나요?</div>
                    <div className={cn('answerRowGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2a === 6 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2a === 6})}>{ANSWER_DICT.response_2a[6]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2a === 7 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2a === 7})}>{ANSWER_DICT.response_2a[7]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2a === 8 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2a === 8})}>{ANSWER_DICT.response_2a[8]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2a === 9 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2a === 9})}>{ANSWER_DICT.response_2a[9]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow1')}>
                    <div/><div>[CDLQI] 2. 지난 1주일 동안, 피부 문제 때문에 화가 나거나, 슬프거나, 속상하거나 당황스러웠나요?</div>
                    <div className={cn('answerRowGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2b === 6 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2b === 6})}>{ANSWER_DICT.response_2b[6]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2b === 7 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2b === 7})}>{ANSWER_DICT.response_2b[7]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2b === 8 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2b === 8})}>{ANSWER_DICT.response_2b[8]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2b === 9 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2b === 9})}>{ANSWER_DICT.response_2b[9]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow1')}>
                    <div/><div>[CDLQI] 3. 지난 1주일 동안, 피부 문제로 인해서 친구들과 사이 좋게 지내는데 문제가 있었나요?</div>
                    <div className={cn('answerRowGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2c === 6 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2c === 6})}>{ANSWER_DICT.response_2c[6]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2c === 7 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2c === 7})}>{ANSWER_DICT.response_2c[7]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2c === 8 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2c === 8})}>{ANSWER_DICT.response_2c[8]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2c === 9 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2c === 9})}>{ANSWER_DICT.response_2c[9]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow2')}>
                    <div/><div>[CDLQI] 4. 지난 1주일 동안, 피부 문제 때문에 얼마나 자주 옷을 갈아입어야 했나요? 또는 입고 
                  싶은 옷이나 신발을 입거나 신지 못하였나요? 혹은 특별히 준비된 옷을 입어야 했나요?</div>
                    <div className={cn('answerRowGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2d === 6 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2d === 6})}>{ANSWER_DICT.response_2d[6]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2d === 7 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2d === 7})}>{ANSWER_DICT.response_2d[7]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2d === 8 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_1d === 8})}>{ANSWER_DICT.response_2d[8]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2d === 9 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2d === 9})}>{ANSWER_DICT.response_2d[9]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow1')}>
                    <div/><div>[CDLQI] 5. 지난 1주일 동안, 피부 문제 때문에 외출하거나 놀거나 취미 생활을 못하였나요?</div>
                    <div className={cn('answerRowGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2e === 6 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2e === 6})}>{ANSWER_DICT.response_2e[6]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2e === 7 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2e === 7})}>{ANSWER_DICT.response_2e[7]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2e === 8 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2e === 8})}>{ANSWER_DICT.response_2e[8]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2e === 9 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2e === 9})}>{ANSWER_DICT.response_2e[9]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow2')}>
                    <div/><div>[CDLQI] 6. 지난 1주일 동안, 피부 문제 때문에 수영이나 다른 스포츠 활동을 못하였나요?</div>
                    <div className={cn('answerRowGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2f === 6 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2f === 6})}>{ANSWER_DICT.response_2f[6]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2f === 7 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2f === 7})}>{ANSWER_DICT.response_2f[7]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2f === 8 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2f === 8})}>{ANSWER_DICT.response_2f[8]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2f === 9 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2f === 9})}>{ANSWER_DICT.response_2f[9]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow1')}>
                    <div/><div>[CDLQI] 7. 지난 1주일이 학기 중이었다면, 피부 문제가 학교 생활에 얼마나 많이 영향을 주었나요? 
                  지난 1주일이 방학이나 휴가 기간이었다면, 피부 문제로 방학(휴가)을 즐기는데 얼마나 
                  어려움이 있었나요?</div>
                    <div className={cn('answerRowGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2g === 6 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2g === 6})}>{ANSWER_DICT.response_2g[6]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2g === 7 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2g === 7})}>{ANSWER_DICT.response_2g[7]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2g === 8 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2g === 8})}>{ANSWER_DICT.response_2g[8]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2g === 9 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2g === 9})}>{ANSWER_DICT.response_2g[9]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow2')}>
                    <div/><div>[CDLQI] 8. 지난 1주일 동안, 피부 문제 때문에 친구들이 놀리거나, 피하거나, 따돌리거나, 괴롭히거
                   나 별명을 불렀나요?</div>
                    <div className={cn('answerRowGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2h === 6 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2h === 6})}>{ANSWER_DICT.response_2h[6]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2h === 7 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2h === 7})}>{ANSWER_DICT.response_2h[7]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2h === 8 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2h === 8})}>{ANSWER_DICT.response_2h[8]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2h === 9 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2h === 9})}>{ANSWER_DICT.response_2h[9]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow1')}>
                    <div/><div>[CDLQI] 9. 지난 1주일 동안, 피부 문제 때문에 잠들기 어렵거나 잠자다가 깬 적이 있는가요?</div>
                    <div className={cn('answerRowGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2i === 6 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2i === 6})}>{ANSWER_DICT.response_2i[6]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2i === 7 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2i === 7})}>{ANSWER_DICT.response_2i[7]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2i === 8 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2i === 8})}>{ANSWER_DICT.response_2i[8]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2i === 9 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2i === 9})}>{ANSWER_DICT.response_2i[9]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow2')}>
                    <div/><div>[CDLQI] 10. 지난 1주일 동안, 피부를 치료하는데 얼마나 어려움이 있었는가요?</div>
                    <div className={cn('answerRowGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2j === 6 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2j === 6})}>{ANSWER_DICT.response_2j[6]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2j === 7 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2j === 7})}>{ANSWER_DICT.response_2j[7]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2j === 8 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2j === 8})}>{ANSWER_DICT.response_2j[8]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2j === 9 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2j === 9})}>{ANSWER_DICT.response_2j[9]}</div>
                      </div>
                    </div>
                  </div>


                </> : null}

                {surveyData.response_2_age === 3 ?
                <>
                  <div style={{fontSize: 16, fontWeight: 600, color: '#54595E', marginTop: 30}}>아토피피부염 삶의 질 DLQI</div>
                  
                  <div className={cn('surveyRow1')} style={{marginTop: 10}}>
                    <div/><div>나이를 선택하세요.</div>
                    <div className={cn('answerRowGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2_age === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2_age === 1})}>{ANSWER_DICT.response_2_age[1]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2_age === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2_age === 2})}>{ANSWER_DICT.response_2_age[2]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2_age === 3 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2_age === 3})}>{ANSWER_DICT.response_2_age[3]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow2')}>
                    <div/><div>[DLQI] 1. 지난 한 주 동안, 본인 피부가 얼마나 가렵거나, 쓰라리거나, 아프거나 또는 화끈거렸습니까?</div>
                    <div className={cn('answerRowGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2a === 11 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2a === 11})}>{ANSWER_DICT.response_2a[11]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2a === 12 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2a === 12})}>{ANSWER_DICT.response_2a[12]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2a === 13 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2a === 13})}>{ANSWER_DICT.response_2a[13]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2a === 14 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2a === 14})}>{ANSWER_DICT.response_2a[14]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow1')}>
                    <div/><div>[DLQI] 2. 지난 한 주 동안, 본인 피부 문제 때문에 얼마나 당황하거나 혹은 신경이 쓰였습니까?</div>
                    <div className={cn('answerRowGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2b === 11 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2b === 11})}>{ANSWER_DICT.response_2b[11]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2b === 12 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2b === 12})}>{ANSWER_DICT.response_2b[12]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2b === 13 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2b === 13})}>{ANSWER_DICT.response_2b[13]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2b === 14 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2b === 14})}>{ANSWER_DICT.response_2b[14]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow1')}>
                    <div/><div>[DLQI] 3. 지난 한 주 동안, 본인 피부 문제로 쇼핑을 가거나 또는 집안일을 하거나 정원을 돌보는 데 
                얼마나 방해가 되었습니까?</div>
                    <div className={cn('answerRowGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2c === 11 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2c === 11})}>{ANSWER_DICT.response_2c[11]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2c === 12 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2c === 12})}>{ANSWER_DICT.response_2c[12]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2c === 13 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2c === 13})}>{ANSWER_DICT.response_2c[13]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2c === 14 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2c === 14})}>{ANSWER_DICT.response_2c[14]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2c === 15 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2c === 15})}>{ANSWER_DICT.response_2c[15]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow2')}>
                    <div/><div>[DLQI] 4. 지난 한 주 동안, 입을 옷을 고를 때 피부 문제가 얼마나 영향을 미쳤습니까?</div>
                    <div className={cn('answerRowGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2d === 11 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2d === 11})}>{ANSWER_DICT.response_2d[11]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2d === 12 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2d === 12})}>{ANSWER_DICT.response_2d[12]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2d === 13 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_1d === 13})}>{ANSWER_DICT.response_2d[13]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2d === 14 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2d === 14})}>{ANSWER_DICT.response_2d[14]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2d === 15 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2d === 15})}>{ANSWER_DICT.response_2d[15]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow1')}>
                    <div/><div>[DLQI] 5. 지난 한 주 동안, 피부 문제가 본인의 어떤 사회활동이나 여가활동에 얼마나 영향을 미쳤습
                니까?</div>
                    <div className={cn('answerRowGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2e === 11 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2e === 11})}>{ANSWER_DICT.response_2e[11]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2e === 12 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2e === 12})}>{ANSWER_DICT.response_2e[12]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2e === 13 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2e === 13})}>{ANSWER_DICT.response_2e[13]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2e === 14 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2e === 14})}>{ANSWER_DICT.response_2e[14]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2e === 15 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2e === 15})}>{ANSWER_DICT.response_2e[15]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow2')}>
                    <div/><div>[DLQI] 6. 지난 한 주 동안, 피부 문제 때문에 스포츠 활동을 하는데 얼마나 어려움이 있었습니까?</div>
                    <div className={cn('answerRowGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2f === 11 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2f === 11})}>{ANSWER_DICT.response_2f[11]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2f === 12 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2f === 12})}>{ANSWER_DICT.response_2f[12]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2f === 13 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2f === 13})}>{ANSWER_DICT.response_2f[13]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2f === 14 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2f === 14})}>{ANSWER_DICT.response_2f[14]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2f === 15 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2f === 15})}>{ANSWER_DICT.response_2f[15]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow1')}>
                    <div/><div>[DLQI] 7. 지난 한 주 동안, 피부 문제 때문에 일이나 또는 공부를 하지 못했습니까?</div>
                    <div className={cn('answerRowGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2g === 11 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2g === 11})}>{ANSWER_DICT.response_2g[11]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2g === 12 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2g === 12})}>{ANSWER_DICT.response_2g[12]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2g === 13 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2g === 13})}>{ANSWER_DICT.response_2g[13]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow2')}>
                    <div/><div>[DLQI] 만약 "아니오"라면, 지난 한 주 동안 본인의 피부 문제 때문에 일이나 공부하는 데 어려운 점은 
            얼마나 있었습니까?</div>
                    <div className={cn('answerRowGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2g_extra === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2g_extra === 1})}>{ANSWER_DICT.response_2g_extra[1]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2g_extra === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2g_extra === 2})}>{ANSWER_DICT.response_2g_extra[2]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2g_extra === 3 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2g_extra === 3})}>{ANSWER_DICT.response_2g_extra[3]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow1')}>
                    <div/><div>[DLQI] 8. 지난 한 주 동안, 본인의 피부 문제가 배우자나, 가까운 친구 혹은 친척들과의 관계에서 얼
                마나 곤란을 가져왔습니까?</div>
                    <div className={cn('answerRowGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2h === 11 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2h === 11})}>{ANSWER_DICT.response_2h[11]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2h === 12 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2h === 12})}>{ANSWER_DICT.response_2h[12]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2h === 13 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2h === 13})}>{ANSWER_DICT.response_2h[13]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2h === 14 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2h === 14})}>{ANSWER_DICT.response_2h[14]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2h === 15 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2h === 15})}>{ANSWER_DICT.response_2h[15]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow1')}>
                    <div/><div>[DLQI] 9. 지난 한 주 동안, 피부 문제가 어떤 성적인 어려움을 얼마나 일으켰습니까?</div>
                    <div className={cn('answerRowGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2i === 11 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2i === 11})}>{ANSWER_DICT.response_2i[11]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2i === 12 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2i === 12})}>{ANSWER_DICT.response_2i[12]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2i === 13 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2i === 13})}>{ANSWER_DICT.response_2i[13]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2i === 14 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2i === 14})}>{ANSWER_DICT.response_2i[14]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2i === 15 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2i === 15})}>{ANSWER_DICT.response_2i[15]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow2')}>
                    <div/><div>[DLQI] 10. 지난 한 주 동안, 피부 치료는 얼마나 문제를 일으켰습니까?</div>
                    <div className={cn('answerRowGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2j === 11 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2j === 11})}>{ANSWER_DICT.response_2j[11]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2j === 12 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2j === 12})}>{ANSWER_DICT.response_2j[12]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2j === 13 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2j === 13})}>{ANSWER_DICT.response_2j[13]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2j === 14 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2j === 14})}>{ANSWER_DICT.response_2j[14]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_2j === 15 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_2j === 15})}>{ANSWER_DICT.response_2j[15]}</div>
                      </div>
                    </div>
                  </div>


                </> : null}

                <>
                  <div style={{fontSize: 16, fontWeight: 600, color: '#54595E', marginTop: 30}}>아토피피부염 가족에 미치는 영향 DFI</div>
                  
                  <div className={cn('surveyRow1')} style={{marginTop: 10}}>
                    <div/><div>1. 자녀의 아토피피부염이 집안일 하는데 얼마나 영향을 주었습니까?</div>
                    <div className={cn('answerColumnGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_3a === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_3a === 1})}>{ANSWER_DICT.response_3a[1]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_3a === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_3a === 2})}>{ANSWER_DICT.response_3a[2]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_3a === 3 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_3a === 3})}>{ANSWER_DICT.response_3a[3]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_3a === 4 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_3a === 4})}>{ANSWER_DICT.response_3a[4]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow2')}>
                    <div/><div>2. 자녀의 아토피피부염이 가족의 식사를 준비하고 음식을 먹이는데 얼마나 많은 영향을 주었습니까?</div>
                    <div className={cn('answerColumnGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_3b === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_3a === 1})}>{ANSWER_DICT.response_3b[1]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_3b === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_3b === 2})}>{ANSWER_DICT.response_3b[2]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_3b === 3 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_3b === 3})}>{ANSWER_DICT.response_3b[3]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_3b === 4 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_3b === 4})}>{ANSWER_DICT.response_3b[4]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow1')}>
                    <div/><div>3. 자녀의 아토피피부염이 아이를 제외한 다른 가족의 수면에 얼마나 많은 영향을 주었습니까?</div>
                    <div className={cn('answerColumnGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_3c === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_3c === 1})}>{ANSWER_DICT.response_3c[1]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_3c === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_3c === 2})}>{ANSWER_DICT.response_3c[2]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_3c === 3 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_3c === 3})}>{ANSWER_DICT.response_3c[3]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_3c === 4 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_3c === 4})}>{ANSWER_DICT.response_3c[4]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow2')}>
                    <div/><div>4. 자녀의 아토피피부염이 가족의 휴일 나들이나 레저 활동에 얼마나 많은 영향을 주었습니까?</div>
                    <div className={cn('answerColumnGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_3d === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_1d === 1})}>{ANSWER_DICT.response_3d[1]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_3d === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_1d === 2})}>{ANSWER_DICT.response_3d[2]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_3d === 3 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_3d === 3})}>{ANSWER_DICT.response_3d[3]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_3d === 4 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_3d === 4})}>{ANSWER_DICT.response_3d[4]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow1')}>
                    <div/><div>5. 자녀의 아토피피부염이 당신의 지출에 얼마나 많은 영향을 주었습니까?</div>
                    <div className={cn('answerColumnGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_3e === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_3e === 1})}>{ANSWER_DICT.response_3e[1]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_3e === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_3e === 2})}>{ANSWER_DICT.response_3e[2]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_3e === 3 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_3e === 3})}>{ANSWER_DICT.response_3e[3]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_3e === 4 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_3e === 4})}>{ANSWER_DICT.response_3e[4]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow2')}>
                    <div/><div>6. 자녀의 아토피피부염이 당신을 지치고 긴장하거나 피로하게 하였습니까?</div>
                    <div className={cn('answerColumnGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_3f === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_3f === 1})}>{ANSWER_DICT.response_3f[1]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_3f === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_3f === 2})}>{ANSWER_DICT.response_3f[2]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_3f === 3 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_3f === 3})}>{ANSWER_DICT.response_3f[3]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_3f === 4 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_3f === 4})}>{ANSWER_DICT.response_3f[4]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow1')}>
                    <div/><div>7. 자녀의 아토피피부염이 당신에게 우울감, 좌절감, 죄책감, 분노 등 정신적 고통을 주었습니까?</div>
                    <div className={cn('answerColumnGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_3g === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_3g === 1})}>{ANSWER_DICT.response_3g[1]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_3g === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_3g === 2})}>{ANSWER_DICT.response_3g[2]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_3g === 3 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_3g === 3})}>{ANSWER_DICT.response_3g[3]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_3g === 4 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_3g === 4})}>{ANSWER_DICT.response_3g[4]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow2')}>
                    <div/><div>8. 자녀의 아토피피부염 치료를 위한 일이 당신의 생활에 얼마나 많은 영향을 주었습니까?</div>
                    <div className={cn('answerColumnGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_3h === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_3h === 1})}>{ANSWER_DICT.response_3h[1]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_3h === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_3h === 2})}>{ANSWER_DICT.response_3h[2]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_3h === 3 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_3h === 3})}>{ANSWER_DICT.response_3h[3]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_3h === 4 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_3h === 4})}>{ANSWER_DICT.response_3h[4]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow1')}>
                    <div/><div>9. 자녀의 아토피피부염이 당신과 배우자 사이 혹은 당신과 다른 자녀 사이에 얼마나 많은 영향을 주
    었습니까?</div>
                    <div className={cn('answerColumnGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_3i === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_3i === 1})}>{ANSWER_DICT.response_3i[1]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_3i === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_3i === 2})}>{ANSWER_DICT.response_3i[2]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_3i === 3 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_3i === 3})}>{ANSWER_DICT.response_3i[3]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_3i === 4 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_3i === 4})}>{ANSWER_DICT.response_3i[4]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow2')}>
                    <div/><div>10. 자녀의 아토피피부염이 쇼핑 시간에 얼마나 영향을 주었습니까?</div>
                    <div className={cn('answerColumnGroup')}>
                    <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_3j === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_3j === 1})}>{ANSWER_DICT.response_3j[1]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_3j === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_3j === 2})}>{ANSWER_DICT.response_3j[2]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_3j === 3 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_3j === 3})}>{ANSWER_DICT.response_3j[3]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_3j === 4 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_3j === 4})}>{ANSWER_DICT.response_3j[4]}</div>
                      </div>
                    </div>
                  </div>

                </>

                <>
                  <div style={{fontSize: 16, fontWeight: 600, color: '#54595E', marginTop: 30}}>환자 중심적 습진 진단 POEM</div>
                  
                  <div className={cn('surveyRow1')} style={{marginTop: 10}}>
                    <div/><div>1. 지난주, 며칠 동안 귀하/자녀는 습진으로 인한 피부 가려움을 느꼈습니까?</div>
                    <div className={cn('answerRowGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_4a === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_4a === 1})}>{ANSWER_DICT.response_4a[1]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_4a === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_4a === 2})}>{ANSWER_DICT.response_4a[2]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_4a === 3 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_4a === 3})}>{ANSWER_DICT.response_4a[3]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_4a === 4 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_4a === 4})}>{ANSWER_DICT.response_4a[4]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_4a === 5 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_4a === 5})}>{ANSWER_DICT.response_4a[5]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow2')}>
                    <div/><div>2. 지난주, 며칠 동안 귀하/자녀는 습진으로 인해 잠을 설쳤습니까?</div>
                    <div className={cn('answerRowGroup')}>
                    <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_4b === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_4b === 1})}>{ANSWER_DICT.response_4b[1]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_4b === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_4b === 2})}>{ANSWER_DICT.response_4b[2]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_4b === 3 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_4b === 3})}>{ANSWER_DICT.response_4b[3]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_4b === 4 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_4b === 4})}>{ANSWER_DICT.response_4b[4]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_4b === 5 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_4b === 5})}>{ANSWER_DICT.response_4b[5]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow1')}>
                    <div/><div>3. 지난주, 며칠 동안 귀하/자녀의 피부에 습진으로 인한 출혈이 있었습니까?</div>
                    <div className={cn('answerRowGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_4c === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_4c === 1})}>{ANSWER_DICT.response_4c[1]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_4c === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_4c === 2})}>{ANSWER_DICT.response_4c[2]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_4c === 3 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_4c === 3})}>{ANSWER_DICT.response_4c[3]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_4c === 4 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_4c === 4})}>{ANSWER_DICT.response_4c[4]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_4c === 5 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_4c === 5})}>{ANSWER_DICT.response_4c[5]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow2')}>
                    <div/><div>4. 지난주, 며칠 동안 귀하/자녀의 피부에 습진으로 인한 분비물이나 진물이 있었습니까?</div>
                    <div className={cn('answerRowGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_4d === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_4d === 1})}>{ANSWER_DICT.response_4d[1]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_4d === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_4d === 2})}>{ANSWER_DICT.response_4d[2]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_4d === 3 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_4d === 3})}>{ANSWER_DICT.response_4d[3]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_4d === 4 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_4d === 4})}>{ANSWER_DICT.response_4d[4]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_4d === 5 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_4d === 5})}>{ANSWER_DICT.response_4d[5]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow1')}>
                    <div/><div>5. 지난주, 며칠 동안 귀하/자녀의 피부에 습진으로 인한 갈라짐이 있었습니까?</div>
                    <div className={cn('answerRowGroup')}>
                    <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_4e === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_4e === 1})}>{ANSWER_DICT.response_4e[1]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_4e === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_4e === 2})}>{ANSWER_DICT.response_4e[2]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_4e === 3 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_4e === 3})}>{ANSWER_DICT.response_4e[3]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_4e === 4 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_4e === 4})}>{ANSWER_DICT.response_4e[4]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_4e === 5 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_4e === 5})}>{ANSWER_DICT.response_4e[5]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow2')}>
                    <div/><div>6. 지난주, 며칠 동안 귀하/자녀의 피부가 습진으로 인해 벗겨졌습니까?</div>
                    <div className={cn('answerRowGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_4f === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_4f === 1})}>{ANSWER_DICT.response_4f[1]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_4f === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_4f === 2})}>{ANSWER_DICT.response_4f[2]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_4f === 3 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_4f === 3})}>{ANSWER_DICT.response_4f[3]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_4f === 4 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_4f === 4})}>{ANSWER_DICT.response_4f[4]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_4f === 5 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_4f === 5})}>{ANSWER_DICT.response_4f[5]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow1')}>
                    <div/><div>7. 지난주, 며칠 동안 귀하/자녀의 피부가 습진으로 인해 건조해지거나 거칠어졌습니까?</div>
                    <div className={cn('answerRowGroup')}>
                    <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_4g === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_4g === 1})}>{ANSWER_DICT.response_4g[1]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_4g === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_4g === 2})}>{ANSWER_DICT.response_4g[2]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_4g === 3 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_4g === 3})}>{ANSWER_DICT.response_4g[3]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_4g === 4 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_4g === 4})}>{ANSWER_DICT.response_4g[4]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_4g === 5 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_4g === 5})}>{ANSWER_DICT.response_4g[5]}</div>
                      </div>
                    </div>
                  </div>

                </>

                <>
                  <div style={{fontSize: 16, fontWeight: 600, color: '#54595E', marginTop: 30}}>치료 부작용 체크리스트</div>
                  
                  <div className={cn('surveyRow1')} style={{marginTop: 10}}>
                    <div/><div>순환기/호흡기에 경험한 부작용이 있었나요?</div>
                    <div className={cn('answerRowGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_5a === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_5a === 1})}>{ANSWER_DICT.response_5a[1]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_5a === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_5a === 2})}>{ANSWER_DICT.response_5a[2]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow2')}>
                    <div/><div>순환기/호흡기에 어떤 부작용이 있었는지 모두 체크해주세요.</div>
                    <div className={cn('answerRowGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_5a === 1 && surveyData.response_5b.includes(1) ? `./img/ic_checkbox_checked.png` : `./img/ic_checkbox_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_5a === 1 && surveyData.response_5b.includes(1)})}>{ANSWER_DICT.response_5b[1]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_5a === 1 && surveyData.response_5b.includes(2) ? `./img/ic_checkbox_checked.png` : `./img/ic_checkbox_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_5a === 1 && surveyData.response_5b.includes(2)})}>{ANSWER_DICT.response_5b[2]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_5a === 1 && surveyData.response_5b.includes(3) ? `./img/ic_checkbox_checked.png` : `./img/ic_checkbox_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_5a === 1 && surveyData.response_5b.includes(3)})}>{ANSWER_DICT.response_5b[3]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_5a === 1 && surveyData.response_5b.includes(4) ? `./img/ic_checkbox_checked.png` : `./img/ic_checkbox_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_5a === 1 && surveyData.response_5b.includes(4)})}>{ANSWER_DICT.response_5b[4]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow1')}>
                    <div/><div>신경과 관련하여 경험한 부작용이 있었나요?</div>
                    <div className={cn('answerRowGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_5c === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_5c === 1})}>{ANSWER_DICT.response_5c[1]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_5c === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_5c === 2})}>{ANSWER_DICT.response_5c[2]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow2')}>
                    <div/><div>신경과 관련하여 어떤 부작용이 있었는지 모두 체크해주세요.</div>
                    <div className={cn('answerColumnGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_5c === 1 && surveyData.response_5d.includes(1) ? `./img/ic_checkbox_checked.png` : `./img/ic_checkbox_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_5c === 1 && surveyData.response_5d.includes(1)})}>{ANSWER_DICT.response_5d[1]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_5c === 1 && surveyData.response_5d.includes(2) ? `./img/ic_checkbox_checked.png` : `./img/ic_checkbox_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_5c === 1 && surveyData.response_5d.includes(2)})}>{ANSWER_DICT.response_5d[2]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_5c === 1 && surveyData.response_5d.includes(3) ? `./img/ic_checkbox_checked.png` : `./img/ic_checkbox_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_5c === 1 && surveyData.response_5d.includes(3)})}>{ANSWER_DICT.response_5d[3]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_5c === 1 && surveyData.response_5d.includes(4) ? `./img/ic_checkbox_checked.png` : `./img/ic_checkbox_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_5c === 1 && surveyData.response_5d.includes(4)})}>{ANSWER_DICT.response_5d[4]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_5c === 1 && surveyData.response_5d.includes(5) ? `./img/ic_checkbox_checked.png` : `./img/ic_checkbox_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_5c === 1 && surveyData.response_5d.includes(5)})}>{ANSWER_DICT.response_5d[5]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow1')}>
                    <div/><div>감염과 관련하여 경험한 부작용이 있었나요?</div>
                    <div className={cn('answerRowGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_5e === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_5e === 1})}>{ANSWER_DICT.response_5e[1]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_5e === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_5e === 2})}>{ANSWER_DICT.response_5e[2]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow2')}>
                    <div/><div>감염과 관련하여 어떤 부작용이 있었는지 모두 체크해주세요.</div>
                    <div className={cn('answerColumnGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_5e === 1 && surveyData.response_5f.includes(1) ? `./img/ic_checkbox_checked.png` : `./img/ic_checkbox_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_5e === 1 && surveyData.response_5f.includes(1)})}>{ANSWER_DICT.response_5f[1]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_5e === 1 && surveyData.response_5f.includes(2) ? `./img/ic_checkbox_checked.png` : `./img/ic_checkbox_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_5e === 1 && surveyData.response_5f.includes(2)})}>{ANSWER_DICT.response_5f[2]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_5e === 1 && surveyData.response_5f.includes(3) ? `./img/ic_checkbox_checked.png` : `./img/ic_checkbox_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_5e === 1 && surveyData.response_5f.includes(3)})}>{ANSWER_DICT.response_5f[3]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_5e === 1 && surveyData.response_5f.includes(4) ? `./img/ic_checkbox_checked.png` : `./img/ic_checkbox_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_5e === 1 && surveyData.response_5f.includes(4)})}>{ANSWER_DICT.response_5f[4]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_5e === 1 && surveyData.response_5f.includes(5) ? `./img/ic_checkbox_checked.png` : `./img/ic_checkbox_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_5e === 1 && surveyData.response_5f.includes(5)})}>{ANSWER_DICT.response_5f[5]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow1')}>
                    <div/><div>눈에 경험한 부작용이 있었나요?</div>
                    <div className={cn('answerRowGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_5g === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_5g === 1})}>{ANSWER_DICT.response_5g[1]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_5g === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_5g === 2})}>{ANSWER_DICT.response_5g[2]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow2')}>
                    <div/><div>눈과 관련하여 어떤 부작용이 있었는지 모두 체크해주세요.</div>
                    <div className={cn('answerColumnGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_5g === 1 && surveyData.response_5h.includes(1) ? `./img/ic_checkbox_checked.png` : `./img/ic_checkbox_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_5g === 1 && surveyData.response_5h.includes(1)})}>{ANSWER_DICT.response_5h[1]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_5g === 1 && surveyData.response_5h.includes(2) ? `./img/ic_checkbox_checked.png` : `./img/ic_checkbox_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_5g === 1 && surveyData.response_5h.includes(2)})}>{ANSWER_DICT.response_5h[2]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_5g === 1 && surveyData.response_5h.includes(3) ? `./img/ic_checkbox_checked.png` : `./img/ic_checkbox_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_5g === 1 && surveyData.response_5h.includes(3)})}>{ANSWER_DICT.response_5h[3]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_5g === 1 && surveyData.response_5h.includes(4) ? `./img/ic_checkbox_checked.png` : `./img/ic_checkbox_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_5g === 1 && surveyData.response_5h.includes(4)})}>{ANSWER_DICT.response_5h[4]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_5g === 1 && surveyData.response_5h.includes(5) ? `./img/ic_checkbox_checked.png` : `./img/ic_checkbox_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_5g === 1 && surveyData.response_5h.includes(5)})}>{ANSWER_DICT.response_5h[5]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow1')}>
                    <div/><div>피부에 경험한 부작용이 있었나요?</div>
                    <div className={cn('answerRowGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_5i === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_5i === 1})}>{ANSWER_DICT.response_5i[1]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_5i === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_5i === 2})}>{ANSWER_DICT.response_5i[2]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow2')}>
                    <div/><div>피부에 어떤 부작용이 있었는지 모두 체크해주세요.</div>
                    <div className={cn('answerRowGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_5i === 1 && surveyData.response_5j.includes(1) ? `./img/ic_checkbox_checked.png` : `./img/ic_checkbox_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_5i === 1 && surveyData.response_5j.includes(1)})}>{ANSWER_DICT.response_5j[1]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_5i === 1 && surveyData.response_5j.includes(2) ? `./img/ic_checkbox_checked.png` : `./img/ic_checkbox_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_5i === 1 && surveyData.response_5j.includes(2)})}>{ANSWER_DICT.response_5j[2]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_5i === 1 && surveyData.response_5j.includes(3) ? `./img/ic_checkbox_checked.png` : `./img/ic_checkbox_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_5i === 1 && surveyData.response_5j.includes(3)})}>{ANSWER_DICT.response_5j[3]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_5i === 1 && surveyData.response_5j.includes(4) ? `./img/ic_checkbox_checked.png` : `./img/ic_checkbox_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_5i === 1 && surveyData.response_5j.includes(4)})}>{ANSWER_DICT.response_5j[4]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow1')}>
                    <div/><div>전신에 경험한 부작용이 있었나요?</div>
                    <div className={cn('answerRowGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_5k === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_5k === 1})}>{ANSWER_DICT.response_5k[1]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_5k === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_5k === 2})}>{ANSWER_DICT.response_5k[2]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow2')}>
                    <div/><div>전신에 어떤 부작용이 있었는지 모두 체크해주세요.</div>
                    <div className={cn('answerColumnGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_5k === 1 && surveyData.response_5l.includes(1) ? `./img/ic_checkbox_checked.png` : `./img/ic_checkbox_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_5k === 1 && surveyData.response_5l.includes(1)})}>{ANSWER_DICT.response_5l[1]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_5k === 1 && surveyData.response_5l.includes(2) ? `./img/ic_checkbox_checked.png` : `./img/ic_checkbox_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_5k === 1 && surveyData.response_5l.includes(2)})}>{ANSWER_DICT.response_5l[2]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_5k === 1 && surveyData.response_5l.includes(3) ? `./img/ic_checkbox_checked.png` : `./img/ic_checkbox_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_5k === 1 && surveyData.response_5l.includes(3)})}>{ANSWER_DICT.response_5l[3]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_5k === 1 && surveyData.response_5l.includes(4) ? `./img/ic_checkbox_checked.png` : `./img/ic_checkbox_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_5k === 1 && surveyData.response_5l.includes(4)})}>{ANSWER_DICT.response_5l[4]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_5k === 1 && surveyData.response_5l.includes(5) ? `./img/ic_checkbox_checked.png` : `./img/ic_checkbox_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_5k === 1 && surveyData.response_5l.includes(5)})}>{ANSWER_DICT.response_5l[5]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow1')}>
                    <div/><div>듀피젠트 치료를 받으시는 경우, 경험한 부작용이 있었나요?</div>
                    <div className={cn('answerColumnGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_5m === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_5m === 1})}>{ANSWER_DICT.response_5m[1]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_5m === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_5m === 2})}>{ANSWER_DICT.response_5m[2]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_5m === 3 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_5m === 3})}>{ANSWER_DICT.response_5m[3]}</div>
                      </div>
                    </div>
                  </div>

                  <div className={cn('surveyRow2')}>
                    <div/><div>어떤 부작용이 있었는지 모두 체크해주세요.</div>
                    <div className={cn('answerColumnGroup')}>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_5m === 1 && surveyData.response_5n.includes(1) ? `./img/ic_checkbox_checked.png` : `./img/ic_checkbox_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_5m === 1 && surveyData.response_5n.includes(1)})}>{ANSWER_DICT.response_5n[1]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_5m === 1 && surveyData.response_5n.includes(2) ? `./img/ic_checkbox_checked.png` : `./img/ic_checkbox_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_5m === 1 && surveyData.response_5n.includes(2)})}>{ANSWER_DICT.response_5n[2]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_5m === 1 && surveyData.response_5n.includes(3) ? `./img/ic_checkbox_checked.png` : `./img/ic_checkbox_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_5m === 1 && surveyData.response_5n.includes(3)})}>{ANSWER_DICT.response_5n[3]}</div>
                      </div>
                      <div className={cn('answerItem')}>
                        <img className={cn('comboIcon')} src={surveyData.response_5m === 1 && surveyData.response_5n.includes(4) ? `./img/ic_checkbox_checked.png` : `./img/ic_checkbox_unchecked.png`} alt="user icon"/>
                        <div className={cn({'answerHighlightedText': surveyData.response_5m === 1 && surveyData.response_5n.includes(4)})}>{ANSWER_DICT.response_5n[4]}</div>
                      </div>
                    </div>
                  </div>

                </>

            </div>

            <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', justifyContent: 'center', gap: 10, marginTop: 15}}>


              {modalState.mode === "detail" ?
              <button style={{width: 160, height: 36, border: 'none', background: '#3A57E8', borderRadius: '4px', cursor: 'pointer', color: 'white', marginLeft: 20}}
                onClick={() => {
                  setModalState({
                    type: "",
                    mode: ""
                  })
                }}>
                닫기
              </button> :  null}

            </div>

          </div>
        </div>
        : null}

      {modalState.type === "chart" ?
      <div className={cn('modalBase')} style={{minHeight: 720}}>
        <div className={cn('modalPaper')} style={{width: 1200, height: 650, padding: 20}}>
          <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', gap: 10}}>
            
            <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', gap: 10}}>
              <div>개인별 차트 분석</div>
            </div>

            <img style={{width: 24, height: 24, marginLeft: 'auto', alignSelf: 'start', cursor: 'pointer'}} src={`./img/btn_close.png`} alt="dashboard background"
              onClick={() => {
                setModalState({
                  type: "",
                  mode: ""
                })
              }}/>
          </div>

          <div style={{display: 'flex', height: 20, borderRadius: 8, background: 'white', marginTop: 10, padding: 20, alignItems: 'center'}}>
            <div style={{fontSize: '20px', fontWeight: 700}}>{markName(participantInfo.participant_name)}</div>
            <div style={{fontSize: '15px', fontWeight: 400, marginTop: 5, marginLeft: 10}}> | 연구 등록 번호 {participantInfo.participant_id}</div>
            <div style={{display: 'grid', gap: 10, padding: '8px 12px', marginLeft: 'auto', gridTemplateColumns: '70px 60px 60px 60px 60px 60px ', alignItems: 'center', fontSize: '14px', 
                            background: '#ebebeb', borderRadius: '22px', boxShadow: 'inset 0 0 2px gray'}}>
                <div className={cn({'graphButton': true, 'graphButtonSelected': chartState.tab === 'scorad'})}
                  onClick={() => {
                    setChartState({tab: 'scorad'});
                    LoadScoradChartData(participantInfo.participant_id);
                  }}>SCROAD</div>
                <div className={cn({'graphButton': true, 'graphButtonSelected': chartState.tab === 'easi'})}
                  onClick={() => {
                    setChartState({tab: 'easi'});
                    LoadEasiChartData(participantInfo.participant_id);
                  }}>EASI</div>
                <div className={cn({'graphButton': true, 'graphButtonSelected': chartState.tab === 'adct'})}
                  onClick={() => {
                    setChartState({tab: 'adct'});
                    LoadSurveyChartData(participantInfo.participant_id, 'adct');
                  }}>ADCT</div>
                <div className={cn({'graphButton': true, 'graphButtonSelected': chartState.tab === 'dlqi'})}
                  onClick={() => {
                    setChartState({tab: 'dlqi'});
                    LoadSurveyChartData(participantInfo.participant_id, 'dlqi');
                  }}>DLQI</div>
                <div className={cn({'graphButton': true, 'graphButtonSelected': chartState.tab === 'dfi'})}
                  onClick={() => {
                    setChartState({tab: 'dfi'});
                    LoadSurveyChartData(participantInfo.participant_id, 'dfi');
                  }}>DFI</div>
                <div className={cn({'graphButton': true, 'graphButtonSelected': chartState.tab === 'poem'})}
                  onClick={() => {
                    setChartState({tab: 'poem'});
                    LoadSurveyChartData(participantInfo.participant_id, 'poem');
                  }}>POEM</div>
              
              </div>
          </div>


          <div style={{width: '100%', height: 500, marginTop: 10, fontSize: '12px', textAlign: 'center', borderRadius: '4px', border: '1px solid #A2A2A2', background: 'white'}}>
            
            <div style={{marginLeft: '32px'}}>
                  <Line 
                  datasetIdKey='id'
                  data={chartData}
                  width="1130" height="450" key="1"
                    options={{
                      plugins:{
                        legend: {
                          display: true
                        }
                      },
                      scales: {
                        x: {
                          display: true
                        },
                        y: {
                          display: true
                        }
                      },
                      responsive: false,
                      maintainAspectRatio: false
                    }}/>
                </div>

          </div>


        </div>
      </div>
      : null}
        

      {isLoading ?
        <div className={cn('modalBase')} style={{minHeight: 720, backdropFilter: 'blur(4px)'}}>
          <div className={cn('modalPaper')} style={{height: 100, width: 400, padding: 15, boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.12), 0px 20px 20px rgba(0, 0, 0, 0.08)', background: '#FFFFFF', borderRadius: '8px'}}>
            <div style={{display: 'flex', height: 60, fontSize: '22px', fontWeight: 500, alignItems: 'center', gap: 30, marginTop: 20}}>
              <div className={cn('flexCenter')} style={{width: 56, height: 56, marginLeft: 30}}>
                <CircularProgress color="neutral" />
              </div>
              <div>
                <div style={{fontWeight: 600, fontSize: 20}}>데이터를 불러오는 중입니다.</div>
                <div style={{fontWeight: 400, fontSize: 14, marginTop: 5, color: 'rgba(84, 89, 94, 0.6)'}}>잠시만 기다려주세요.</div>
              </div>
            </div>
          </div>
        </div>
        : null}

      {dialogState.isOpen ?
        <div className={cn('modalBase')} style={{minHeight: 720, backdropFilter: 'blur(4px)'}}>
          <div className={cn('modalPaper')} style={{height: 200, width: 600, padding: 25, boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.12), 0px 20px 20px rgba(0, 0, 0, 0.08)', background: '#FFFFFF', borderRadius: '8px'}}>
            <div style={{display: 'flex', gap: 10}}>
              <img style={{width: 24, height: 24, marginLeft: 'auto', alignSelf: 'start', cursor: 'pointer'}} src={`./img/btn_close.png`} alt="dashboard background"
                onClick={() => {
                  setDialogState({
                    isOpen: false
                  })
                }}/>
            </div>
            <div style={{fontWeight: 600, fontSize: 20, marginTop: -25, width: 500}}>{dialogState.title}</div>
            <div style={{fontWeight: 400, fontSize: 14, marginTop: 10, color: 'rgba(84, 89, 94, 0.6)', whiteSpace: 'pre-wrap', lineHeight: '22px'}}>{dialogState.desc}</div>
            <div style={{display: 'flex', gap: 10, fontSize: '14px', fontWeight: 500, marginTop: 'auto', marginLeft: 'auto'}}>
              <button style={{width: 180, height: 44, border: '1px solid #4F4F4F', background: 'none', borderRadius: '4px', cursor: 'pointer', color: '#4F4F4F'}}
                onClick={() => {
                  setDialogState({
                    isOpen: false
                  })
                }}>{dialogState.deny_label}</button>
              <button style={{width: 180, height: 44, border: 'none', background: '#4F4F4F', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                onClick={() => {
                  dialogState.accept_callback();
                }}>{dialogState.accept_label}</button>
            </div>
          </div>
        </div>
        : null}

      {popupState.type === "message" ?
        <div className={cn('modalBase')} style={{minHeight: 720, background: 'none'}} 
          onClick={() => {
            setPopupState({
              type: "",
              mode: ""
            })
          }}>
          <div className={cn('modalPaper')} style={{height: 100, width: 545, padding: 15, boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.12), 0px 20px 20px rgba(0, 0, 0, 0.08)', background: '#FFFFFF', borderRadius: '8px'}}>
            <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', gap: 10}}>
              <img style={{width: 24, height: 24, marginLeft: 'auto', alignSelf: 'start', cursor: 'pointer'}} src={`./img/btn_close.png`} alt="dashboard background"
                onClick={() => {
                  setPopupState({
                    type: "",
                    mode: ""
                  })
                }}/>
            </div>
            <div style={{display: 'flex', height: 60, fontSize: '22px', fontWeight: 500, alignItems: 'center', gap: 30, marginTop: -15}}>
            {popupState.mode === "success" ? <img style={{width: 56, height: 56, marginLeft: 30}} src={`./img/ic_info.png`} alt="warning icon"/> : null}
            {popupState.mode === "fail" ? <img style={{width: 56, height: 56, marginLeft: 30}} src={`./img/ic_warning.png`} alt="warning icon"/> : null}
              
              <div>
                <div style={{fontWeight: 600, fontSize: 20}}>{popupState.title}</div>
                <div style={{fontWeight: 400, fontSize: 14, marginTop: 5, color: 'rgba(84, 89, 94, 0.6)'}}>{popupState.desc}</div>
              </div>
            </div>


          </div>
        </div>
        : null}

    </div>
  );
}

